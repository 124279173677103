import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { PssName } from '@/modules/api/application/application-contracts';
import { ApiPssCapabilities, CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { customerSettingsService } from '@/modules/customer-settings/api/customer-settings.service';
import { logger } from '@/modules/monitoring';
import { MessageService } from '@/modules/shared';
import { UiAndApiPssCapabilities, getUiCapabilitiesByPss } from '@/modules/shared/configuration/pss-capabilities';
import { i18n } from '@/plugins/i18n';
import { AppSettingsModule } from '@/store/modules/app-settings.module';

const { t } = i18n.global;

export const useCustomerSettingsStore = defineStore('customerSettings', () => {
  const settings: Ref<CustomerSettingsModel<UiAndApiPssCapabilities> | undefined> = ref();
  const hasMultiFactorAuthentication: Ref<boolean> = ref(false);

  const pss: ComputedRef<PssName> = computed(() => AppSettingsModule.inventoryConfigurationProperties.pss);

  async function get(): Promise<void> {
    try {
      const customerSettings: CustomerSettingsModel<ApiPssCapabilities> = await customerSettingsService.get();
      const pssCapabilities: UiAndApiPssCapabilities = getUiCapabilitiesByPss(pss.value, customerSettings.pssCapabilities);

      settings.value = {
        ...customerSettings,
        pssCapabilities,
      };
    } catch (error) {
      logger.error(error as Error);
      MessageService.error(t('messages.customer_settings.settings'));
    }
  }

  async function getMultiFactorAuthentication(): Promise<void> {
    try {
      const response = await customerSettingsService.getMultiFactorAuthentication();
      hasMultiFactorAuthentication.value = response.hasMultiFactorAuthenticationEnabled;
    } catch (error) {
      logger.error(error as Error);
      MessageService.error(t('messages.customer_settings.multi_factor_authentication'));
    }
  }

  function $reset(): void {
    settings.value = undefined;
    hasMultiFactorAuthentication.value = false;
  }

  return {
    settings,
    hasMultiFactorAuthentication,
    get,
    getMultiFactorAuthentication,
    $reset,
  };
});
