import { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/modules/shared/types/routes';

export const EXTERNAL_ROUTES: RouteRecordRaw[] = [
  {
    path: '/external',
    redirect: `/external/${RouteName.login}`,
    component: () => import('../views/ExternalBase.vue'),
    children: [
      {
        path: RouteName.login,
        name: RouteName.login,
        component: () => import('../views/LoginPage.vue'),
      },
    ],
  },
];
