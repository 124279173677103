import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions-contracts';

const { t } = i18n.global;

export interface ApplyRecommendedOverbookingFactorPayload extends BaseFlightActionPayload {
  cabinCode: string;
  legNumber?: number | undefined;
}

export class ApplyRecommendedOverbookingFactorAction implements FlightAction<ApplyRecommendedOverbookingFactorPayload> {
  public actionType: FlightActionType;
  public label = t('apply_recommended_overbooking_factor');
  public value: any;
  public cabinCode: string;
  public legNumber: number | undefined;
  public fieldType: FilterFieldField = FilterFieldField.applyRecommendedOverbookingFactor;

  constructor(cabinCode?: string, legNumber?: number) {
    this.cabinCode = cabinCode ?? 'Y';
    this.legNumber = legNumber;
    this.setFlightActionType(legNumber);
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + this.fieldType,
      meta: {
        cabinCode: this.cabinCode,
        label: this.label,
        flightActionType: this.actionType,
        field: this.fieldType,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + this.fieldType,
        uniqueField: this.cabinCode,
        value: {
          legNumber: this.legNumber,
        },
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition<ApplyRecommendedOverbookingFactorPayload>): void {
    const payload = action.value;
    this.cabinCode = action.uniqueField as string;
    this.setFlightActionType(payload?.legNumber);
  }

  public getPayload(): ApplyRecommendedOverbookingFactorPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      legNumber: this.legNumber,
    };
  }

  public setPayload(payload: ApplyRecommendedOverbookingFactorPayload): void {
    this.cabinCode = payload.cabinCode;
    this.legNumber = payload.legNumber;
    this.setFlightActionType(payload.legNumber);
  }

  private setFlightActionType(legNumber: number | undefined): void {
    this.actionType = legNumber
      ? FlightActionType.applyMultiLeggedRecommendedOverbookingFactor
      : FlightActionType.applyRecommendedOverbookingFactor;
  }
}
