import { ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import {
  generateInventoryActiveOptimisationTacticsColumnsState,
  generateInventoryShadowOptimisationTacticsColumnsState,
  generateInventorySharedCabinClassSourceFlightColumnsState,
  generateInventorySharedFlightClassColumnsState,
  getInventoryActiveOptimisationTacticsColumns,
  getInventoryShadowOptimisationTacticsColumns,
  getInventorySharedCabinClassSourceFlightColumns,
  getInventorySharedFlightClassColumns,
} from '@/models/columns/collections/inventory/shared';
import * as CabinClassColumns from '@/models/columns/definitions';
import { ColumnGroupId, ColumnId } from '@/models/enums/grid';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const InventoryGridColumnDefinitions1y = (
  bookingsPickupDays: number[],
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
): ColGroupDef[] => {
  const inventoryFlightClassColumns = [
    ...getInventorySharedFlightClassColumns(bookingsPickupDays),
    CabinClassColumns.MinPriceColumn,
    CabinClassColumns.MaxPriceColumn,
    CabinClassColumns.NegotiatedAllottedSeatsColumn,
  ];

  if (customerSettings.hasNegoAllocationEnabled) {
    inventoryFlightClassColumns.push(CabinClassColumns.NegotiatedAllottedSeatsColumn);
  }

  const inventoryColumns = [
    {
      headerName: `${t('flight')} | ${t('class')}`,
      groupId: ColumnGroupId.InventoryFlightLineGroup,
      children: inventoryFlightClassColumns,
    },
    {
      headerName: `${t('flight')} | ${t('source_flight')}`,
      groupId: ColumnGroupId.ClassSourceFlightGroup,
      children: [...getInventorySharedCabinClassSourceFlightColumns()],
    },
    {
      headerName: `${t('active_optimisation_tactics')}`,
      groupId: ColumnGroupId.ActiveOptimisationTacticsGroup,
      children: [...getInventoryActiveOptimisationTacticsColumns()],
    },
  ];

  if (customerSettings.hasShadowTacticsEnabled) {
    inventoryColumns.push({
      headerName: t('shadow_tactics'),
      groupId: ColumnGroupId.ShadowOptimisationTacticsGroup,
      children: [...getInventoryShadowOptimisationTacticsColumns()],
    });
  }

  return inventoryColumns;
};

export const generateInventoryGridDefaultColumnState1y = (
  bookingsPickupDays: number[],
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
): ColumnState[] => {
  const columns = [
    ...generateInventorySharedFlightClassColumnsState(bookingsPickupDays),
    { colId: ColumnId.MinPrice, hide: false, sort: null },
    { colId: ColumnId.MaxPrice, hide: false, sort: null },
    { colId: ColumnId.NegotiatedAllottedSeats, hide: true, sort: null },
    ...generateInventorySharedCabinClassSourceFlightColumnsState(),
    ...generateInventoryActiveOptimisationTacticsColumnsState(),
  ];

  if (customerSettings.hasShadowTacticsEnabled) {
    columns.push(...generateInventoryShadowOptimisationTacticsColumnsState());
  }

  if (customerSettings.hasNegoAllocationEnabled) {
    columns.push({ colId: ColumnId.NegotiatedAllottedSeats, hide: true, sort: null });
  }
  return columns;
};
