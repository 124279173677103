import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { ICluster, ICreateCluster } from '@/modules/cluster-management/api/cluster-contracts';
import { clusterService } from '@/modules/cluster-management/api/cluster-service';
import { MessageService } from '@/modules/shared';

export const useClustersStore = defineStore('clusters', () => {
  const clusters: Ref<ICluster[]> = ref([]);
  const isLoading: Ref<boolean> = ref(false);

  async function get(): Promise<void> {
    try {
      isLoading.value = true;
      clusters.value = await clusterService.getAll();
    } catch {
      MessageService.failedRequest();
    } finally {
      isLoading.value = false;
    }
  }

  async function update(cluster: ICluster): Promise<void> {
    try {
      isLoading.value = true;
      const updatedCluster = await clusterService.update(cluster.id, cluster);

      const index = clusters.value.findIndex((cachedCluster) => cachedCluster.id === updatedCluster.id);
      if (index > -1) {
        clusters.value.splice(index, 1, updatedCluster);
      }
    } catch {
      MessageService.failedRequest();
    } finally {
      isLoading.value = false;
    }
  }

  async function create(cluster: ICreateCluster): Promise<void> {
    try {
      isLoading.value = true;
      const createdCluster = await clusterService.create(cluster);
      clusters.value.push(createdCluster);
    } catch {
      MessageService.failedRequest();
    } finally {
      isLoading.value = false;
    }
  }

  function $reset() {
    clusters.value = [];
    isLoading.value = false;
  }

  return {
    update,
    create,
    clusters,
    isLoading,
    get,
    $reset,
  };
});
