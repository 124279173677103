import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface SetPricingIncrementPayload extends BaseFlightActionPayload {
  cabinCode: string;
  value: number;
}

export class SetPricingIncrementAction implements FlightAction<SetPricingIncrementPayload> {
  public actionType: FlightActionType = FlightActionType.setPricingIncrement;
  public label = t('dynamic_filters.price_increment');
  public value: number;
  public cabinCode: string;
  public showBulkAvailabilityWarning: boolean;

  constructor(value: number, cabinCode?: string) {
    this.cabinCode = cabinCode;
    this.value = value;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.priceAggregation,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.setPricingIncrement,
        field: FilterFieldField.priceAggregation,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.priceAggregation,
        uniqueField: this.cabinCode,
        isValueValid: () => true,
        componentName: ComponentName.PricingAggregationSelect,
        value: this.value,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: SetPricingIncrementPayload): void {
    this.value = payload.value;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): SetPricingIncrementPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      value: this.value,
    };
  }
}
