import { UiPssCapabilities } from '../pss-capabilities';

export const PssCapabilities1s: UiPssCapabilities = {
  hasRevenue: true,
  hasFlightClosed: false,
  hasFlags: false,
  hasEffectiveDate: false,
  hasDiscontinueDate: false,
  hasWaitlist: false,
  hasGroupWaitlist: false,
  hasGroupPending: false,
  hasFareValue: true,
  hasMinThreshold: false,
  hasSegmentLimit: false,
  hasUnbalancedAdjustments: false,
  hasLeftover: true,
  hasDynamicPricing: false,
  hasContinuousRealtimeBookings: false,
};
