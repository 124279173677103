import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface UpdateAutopilotPayload extends BaseFlightActionPayload {
  cabinCode: string;
  value: boolean;
}

export class UpdateAutopilotAction implements FlightAction<UpdateAutopilotPayload> {
  public actionType: FlightActionType = FlightActionType.updateAutopilot;
  public label = t('dynamic_filters.toggle_autopilot');
  public cabinCode: string;
  public value: boolean;

  constructor(cabinCode?: string) {
    this.cabinCode = cabinCode;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition<boolean>> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.autopilot,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.updateAutopilot,
        field: FilterFieldField.autopilot,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.autopilot,
        uniqueField: this.cabinCode,
        componentName: ComponentName.AutopilotFilterField,
        value: this.value,
        isValueValid: (value: any) => isValueNotEmpty(value),
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/BooleanActionValueRenderer.vue'),
        ),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition<boolean>): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: UpdateAutopilotPayload): void {
    this.value = !!payload.value;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): UpdateAutopilotPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      value: !!this.value,
    };
  }
}
