import { ColDef, ColGroupDef, ColumnState } from 'ag-grid-enterprise';
import { differenceBy } from 'lodash-es';

import { ColumnId } from '@/models/enums/grid';

export class ColumnService {
  public static getColumnStateForColGroup(colGroup: ColGroupDef, hide = true): ColumnState[] {
    return colGroup.children.flatMap((colDef) => [
      {
        colId: (colDef as ColDef).colId,
        hide,
      },
    ]);
  }

  /**
   * Migrates the column state of the user, with the default.
   *
   * New Columns are injected before their next neighbor in the default state
   *
   * @param defaultColumnState The default column state
   * @param userColumnStates The user's column state
   */
  public static updateUserColumnStateWithNewDefaultState(defaultColumnState: ColumnState[], userColumnStates: ColumnState[]) {
    // Get the fields the current users' state is missing
    const missingFields = differenceBy(defaultColumnState, userColumnStates, 'colId');

    // For each missing field
    missingFields.forEach((missingField) => {
      // Find the position in the default state
      const missingFieldIndex = defaultColumnState.findIndex((el) => el.colId === missingField.colId);

      // Get the field next to it, in the default state
      const fieldAfter = defaultColumnState.slice(missingFieldIndex).find((field) => !missingFields.some((mf) => mf.colId === field.colId));
      if (fieldAfter && userColumnStates.some((el) => el.colId === fieldAfter.colId)) {
        // Find the position of that field in the current user's state
        const fieldAfterIndex = userColumnStates.findIndex((el) => el.colId === fieldAfter.colId);

        // Insert it at that position
        userColumnStates.splice(fieldAfterIndex, 0, missingField);
      }
    });

    return userColumnStates;
  }

  /**
   * Migrates the column state of the user, with the default.
   *
   * Columns are updated with the new colId
   *
   * @param defaultColumnState The default column state
   * @param userColumnStates The user's column state
   */
  public static updateAircraftColumnstoFlightState(defaultColumnState: ColumnState[], userColumnStates: ColumnState[]) {
    const mappedColId: Record<string, ColumnId> = {
      'aircraft-bookings': ColumnId.FlightBookings,
      'aircraft-equipment-type': ColumnId.FlightEquipmentType,
      'aircraft-equipment-version': ColumnId.FlightEquipmentVersion,
      'aircraft-fare-currency': ColumnId.FlightFareCurrency,
      'aircraft-average-fare': ColumnId.FlightAverageFare,
    };

    const missingFields = differenceBy(userColumnStates, defaultColumnState, 'colId').filter((a) =>
      // Only perform this action when the colId's are expected to be mapped.
      Object.values(mappedColId).some((value) => value.startsWith(a.colId)),
    );

    // For each missing field
    missingFields.forEach((missingField) => {
      // Find the position in the user state
      const missingFieldIndex = userColumnStates.findIndex((el) => el.colId === missingField.colId);
      // Update the colId
      missingField.colId = mappedColId[missingField.colId];

      // Insert it at that position
      userColumnStates.splice(missingFieldIndex, 0, missingField);
    });

    return userColumnStates;
  }
}
