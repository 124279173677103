import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { FlightViewLegCabinInfo } from '@/models/FlightModel';
import { FlightLegCabin } from '@/modules/api/flight/flight-contracts';
import { FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { store } from '@/store';
import { AppSettingsModule } from '@/store/modules/app-settings.module';

// State definition
export interface IOverbookingState {
  selectedLegCabin: FlightViewLegCabinInfo | null;
}

@Module({ dynamic: true, store, name: 'overbooking', namespaced: true })
class Overbooking extends VuexModule implements IOverbookingState {
  // Default state
  public selectedLegCabin: FlightViewLegCabinInfo | null = null;
  public cabins: FlightViewLegCabinInfo[] = [];
  public balancedAdjustment = 0;
  public unbalancedAdjustment = 0;
  public authorizedCapacity = 0;
  public defaultOverbookingFactor = 100;
  public overbookingAutopilot = false;
  public overbookingFactor: number = this.defaultOverbookingFactor;
  public overbookingRisk: number | undefined = undefined;
  public recommendedOverbookingFactor: number = this.defaultOverbookingFactor;

  public get cabinOrder(): undefined | number {
    if (!this.selectedLegCabin) {
      return;
    }
    return AppSettingsModule.inventoryConfigurationProperties.cabins.find((cabin) => cabin.code === this.selectedLegCabin.cabinCode)?.order;
  }

  get cabinAgreementSummary(): string {
    if (!this.selectedLegCabin || !this.selectedLegCabin.cabinAgreements) {
      return '-';
    }
    const blockedSum = this.selectedLegCabin.cabinAgreements.reduce((a, b) => a + (b.blocked || 0), 0);
    const bookedSum = this.selectedLegCabin.cabinAgreements.reduce((a, b) => a + (b.booked || 0), 0);
    return `${bookedSum} / ${blockedSum}`;
  }

  get leftoverCapacity() {
    return this.selectedLegCabin?.updatedLeftoverCapacity;
  }

  get overbookingRiskPercentage() {
    return this.overbookingRisk;
  }

  get totalAdjustments(): number {
    const balancedAdjustment = this.cabinOrder === 0 ? -1 * this.balancedAdjustment : this.balancedAdjustment;

    const cabinAgreements = this.selectedLegCabin?.cabinAgreements ? this.selectedLegCabin?.cabinAgreements : [];

    return this.unbalancedAdjustment + balancedAdjustment - cabinAgreements.reduce((a, b) => a + (b.blocked || 0), 0);
  }

  get totalCapacity(): number {
    return this.selectedLegCabin?.saleableCapacity + this.totalAdjustments;
  }

  get overbookedCapacity(): number {
    return Math.round(this.totalCapacity * (this.overbookingFactor / 100));
  }

  get recommendedOverbookedCapacity(): number {
    return Math.round(this.totalCapacity * (this.recommendedOverbookingFactor / 100));
  }

  public get absoluteOverbooking(): number {
    return Math.round(this.overbookedCapacity - this.totalCapacity);
  }

  get recommendedAbsoluteOverbooking(): number {
    return Math.round(this.recommendedOverbookedCapacity - this.totalCapacity);
  }

  @Action
  public async applyAdjustments() {
    this.setLegCabinBalancedAdjustments(this.cabinOrder);
    this.setSelectedLegCabinUnbalancedAdjustment();
  }

  @Action
  public applyOverbookingFactor() {
    this.setSelectedLegCabinOverbookingFactor(this.overbookingFactor);
  }

  @Action
  public applyRecommendedOverbookingFactor(): void {
    this.setSelectedLegCabinOverbookingFactor(this.recommendedOverbookingFactor);
  }

  @Action
  public async init(payload: { selectedLegCabin: FlightViewLegCabinInfo; cabins: FlightViewLegCabinInfo[] }): Promise<void> {
    const selectedLegCabin = payload.selectedLegCabin;
    const cabins = payload.cabins;
    this.setSelectedLegCabin(selectedLegCabin);
    if (cabins) {
      this.setCabins(cabins);
    }
    this.setLeftoverCapacity(selectedLegCabin.leftoverCapacity);
    this.setOverBookingFactor(selectedLegCabin.overbookingFactor);
    this.setUnbalancedAdjustment(selectedLegCabin.unbalancedAdjustment);

    if (this.cabins.length === 0) {
      this.setBalancedAdjustment(selectedLegCabin.balancedAdjustment);
      return;
    }

    const balancedAdjustment = this.cabinOrder === 0 ? cabins[0].balancedAdjustment : selectedLegCabin.balancedAdjustment;
    this.setBalancedAdjustment(balancedAdjustment);
  }

  @Action
  public async resolveLeftover(payload: { action: FlightActionType; updatedCabin: FlightLegCabin }) {
    switch (payload.action) {
      case FlightActionType.resolveByMatchingOvb:
        this.setOverBookingFactor(payload.updatedCabin.overbookingFactor);
        this.setLeftoverCapacity(payload.updatedCabin.leftoverCapacity);
        break;
      case FlightActionType.resolveByIgnoring:
        this.setAuthorizedCapacity(payload.updatedCabin.updatedAuthorizedCapacity);
        this.setLeftoverCapacity(0);
        break;
      default:
        break;
    }
  }

  // Mutations
  @Mutation
  public setSelectedLegCabin(payload: FlightViewLegCabinInfo) {
    this.selectedLegCabin = payload;
  }

  @Mutation
  public setCabins(payload: FlightViewLegCabinInfo[]) {
    this.cabins = payload;
  }

  @Mutation
  public setBalancedAdjustment(payload: number) {
    this.balancedAdjustment = payload === undefined ? 0 : payload;
  }

  @Mutation
  public setUnbalancedAdjustment(payload: number) {
    this.unbalancedAdjustment = payload === undefined ? 0 : payload;
  }

  @Mutation
  public setAuthorizedCapacity(payload: number) {
    this.authorizedCapacity = payload === undefined ? 0 : payload;
  }

  @Mutation
  public setLegCabinBalancedAdjustments(cabinOrder: number) {
    this.selectedLegCabin.balancedAdjustment = cabinOrder === 0 ? -1 * this.balancedAdjustment : this.balancedAdjustment;

    if (this.cabins.length === 0) {
      return;
    }

    // Assumes we only have one other cabin
    this.cabins[0].balancedAdjustment = cabinOrder === 0 ? this.balancedAdjustment : -1 * this.balancedAdjustment;
  }

  @Mutation
  public setSelectedLegCabinUnbalancedAdjustment() {
    this.selectedLegCabin.unbalancedAdjustment = this.unbalancedAdjustment;
  }

  @Mutation
  public setOverBookingFactor(payload: number): void {
    this.overbookingFactor = payload;
  }

  @Mutation
  public setRecommendedOverBookingFactor(payload: number): void {
    this.recommendedOverbookingFactor = payload;
  }

  @Mutation
  public setOverBookingRisk(payload: number): void {
    this.overbookingRisk = payload;
  }

  @Mutation
  public setOverbookingAutopilot(payload: boolean): void {
    this.overbookingAutopilot = payload;
  }

  @Mutation
  public setSelectedLegCabinOverbookingFactor(overbookingFactor: number): void {
    this.selectedLegCabin.overbookingFactor = overbookingFactor;
  }

  @Mutation
  public setLeftoverCapacity(payload: number): void {
    this.selectedLegCabin.updatedLeftoverCapacity = payload;
  }
}

export const OverbookingModule = getModule(Overbooking);
