import { orderBy, uniqBy } from 'lodash-es';

import { FilterFieldField } from '@/modules/api/shared-contracts';
import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { MarketInfoModule } from '@/store/modules/market-info.module';

export enum ControlFilterType {
  departure_date_range = 'departureDateRange',
  day_of_week = 'dayOfWeek',
  tags = 'tags',
  users = 'users',
  origin_destination_hub = 'originDestinationHub',
  flightNumber = 'flightNumber',
  aircraft_type = 'aircraftType',
  route_group = 'routeGroup',
  carrier_code = 'carrierCode',
  optimization_profile = 'optimizationProfile',
  optimization_tactic = 'optimizationTactic',
  cluster = 'cluster',
}

export type fieldType = FilterFieldField.origin | FilterFieldField.destination;

export function filteredAirports(fil: fieldType, selected?: string[]): RouteModel[] {
  const filteredField = fil === FilterFieldField.origin ? FilterFieldField.destination : FilterFieldField.origin;
  let airports: RouteModel[] = [];
  if (!selected || selected.length === 0) {
    airports = orderBy(uniqBy(MarketInfoModule.marketInfo, fil), fil);
  } else {
    selected.forEach((selection) => {
      const matches = MarketInfoModule.marketInfo.filter((airport: RouteModel) => airport[fil] === selection);
      airports.push(...matches);
    });
    airports = orderBy(uniqBy(airports, filteredField), filteredField);
  }
  return airports;
}
