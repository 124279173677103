import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export type RevertToLastCapturePayload = BaseFlightActionPayload;

export class RevertToLastCaptureAction implements FlightAction<RevertToLastCapturePayload> {
  public actionType: FlightActionType = FlightActionType.revert;
  public label = t('revert_to_nightly_capture');
  public value: any = null;

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: 'revertToCapture',
      meta: {
        label: this.label,
        flightActionType: FlightActionType.revert,
        field: FilterFieldField.revert,
        type: FilterFieldType.equal,
        valueKey: 'revertToCapture',
        warning: t('bulk_revert'),
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(): void {
    return;
  }

  public setPayload(): void {
    return;
  }

  public getPayload(): RevertToLastCapturePayload {
    return {
      actionType: this.actionType,
    };
  }
}
