// FOR ALL METHODS THAT ARE SHARED BY SELECT ELEMENTS

import { i18n } from '@/plugins/i18n';

import { OptionGroup, SingleSelectOption } from '../types/select.interface';

const { t } = i18n.global;

/**
 * Creates 2 already-filtered option groups to populate the select dropdown:
 *
 * First group is selected options
 *
 * Second group is not-selected options
 *
 * @param {Array} availableOptions - List of all available options
 * @param {Array} selectedValues - List of already selected values
 * @param {string} query - The query that user input for option filtering
 * @returns {Array} an array of 2 objects, 1 including selections, and the other including the not selected ones
 */
export function selectOptionGroups(availableOptions: SingleSelectOption[], selectedValues: any[], query: string): OptionGroup[] {
  let filteredSelectOptions = availableOptions;

  if (query !== '') {
    filteredSelectOptions = availableOptions.filter((option) => {
      // if it's a custom template with abbreviation (option.value) and full name (option.label) next to each oher,
      // combine those to text-search in
      // otherwise, use just the value
      const caseInsensitiveOption =
        option.value === option.label || !isNaN(option.value)
          ? option.label.toLowerCase()
          : [option.value, option.label].join(' ').toLowerCase();
      return caseInsensitiveOption.indexOf(query) !== -1;
    });
  }

  const remainingOptions: OptionGroup = {
    label: '',
    options:
      selectedValues && filteredSelectOptions
        ? filteredSelectOptions.filter((option) => !selectedValues.some((selectedValue) => selectedValue === option.value))
        : filteredSelectOptions,
  };

  if (selectedValues && selectedValues.length > 0) {
    const selectedOptions: OptionGroup = {
      label: t('general.selected'),
      options: availableOptions.filter((option) => selectedValues.some((selectedValue) => selectedValue === option.value)),
    };
    return remainingOptions.options.length > 0 ? [selectedOptions, remainingOptions] : [selectedOptions];
  } else {
    return [remainingOptions];
  }
}

export function clipText(text: string) {
  return `${text.substring(0, 3)}...`;
}
