export enum ComponentName {
  AddSeatsLowestAvailableFareFilterField = 'AddSeatsLowestAvailableFareFilterField',
  AdjustmentRangeFilterField = 'AdjustmentRangeFilterField',
  AircraftFilterField = 'AircraftFilterField',
  AutopilotFilterField = 'AutopilotFilterField',
  CarrierCodeFilterField = 'CarrierCodeFilterField',
  ClusterFilterField = 'ClusterFilterField',
  CustomAuthorizationUnitsManagementField = 'CustomAuthorizationUnitsManagementField',
  CustomDateFilterField = 'CustomDateFilterField',
  CustomInventoryManagementDatePickerField = 'CustomInventoryManagementDatePickerField',
  CustomInventoryManagementField = 'CustomInventoryManagementField',
  CustomInventoryManagementPinField = 'CustomInventoryManagementPinField',
  CustomNumberInputFilterField = 'CustomNumberInputFilterField',
  CustomNumberRangeFilterField = 'CustomNumberRangeFilterField',
  CustomSeatAvailabilityManagementField = 'CustomSeatAvailabilityManagementField',
  CustomSelectFilterField = 'CustomSelectFilterField',
  DatePickerFilterField = 'DatePickerFilterField',
  DateRangeFilterField = 'DateRangeFilterField',
  DayOfWeekFilterField = 'DayOfWeekFilterField',
  DestinationSelectFilterField = 'DestinationSelectFilterField',
  DistributionOptionFilterField = 'DistributionOptionFilterField',
  FlightNumberRangeFilterField = 'FlightNumberRangeFilterField',
  FlightPathFilterField = 'FlightPathFilterField',
  FlightPickerFilterField = 'FlightPickerFilterField',
  HubFilterField = 'HubFilterField',
  LinkedClassRuleSelectFilterField = 'LinkedClassRuleSelectFilterField',
  MatchCompetitorField = 'MatchCompetitorField',
  MoveClusterLevelField = 'MoveClusterLevelField',
  MoveLafField = 'MoveLafField',
  MoveLafUpField = 'MoveLafUpField',
  MoveOptimizationProfileField = 'MoveOptimizationProfileField',
  MoveRecommendedOverbookingRiskPercentageFilterField = 'MoveRecommendedOverbookingRiskPercentageFilterField',
  MoveTacticalAdjustmentField = 'MoveTacticalAdjustmentField',
  OptimisationProfileField = 'OptimisationProfileField',
  OptimizationProfileFilterField = 'OptimizationProfileFilterField',
  OptimizationTacticFilterField = 'OptimizationTacticFilterField',
  OriginSelectFilterField = 'OriginSelectFilterField',
  OverbookingAutopilotField = 'OverbookingAutopilotField',
  OverbookingFilterField = 'OverbookingFilterField',
  PricingAggregationSelect = 'PricingAggregationSelect',
  PricingTacticSelect = 'PricingTacticSelect',
  ResolveLeftoverField = 'ResolveLeftoverField',
  RivalRuleSelectFilterField = 'RivalRuleSelectFilterField',
  RouteGroupFilterField = 'RouteGroupFilterField',
  SetCabinPromotionActionEditor = 'SetCabinPromotionActionEditor',
  SetRecommendedOverbookingRiskPercentageFilterField = 'SetRecommendedOverbookingRiskPercentageFilterField',
  SwapOptimisationProfileField = 'SwapOptimisationProfileField',
  TacticAdjustmentSlider = 'TacticAdjustmentSlider',
  TagSelectFilterField = 'TagSelectFilterField',
  TagsFilterField = 'TagsFilterField',
  TagsRemoveField = 'TagsRemoveField',
  ToggleFilterFieldType = 'ToggleFilterFieldType',
  UpdateClusterLevelField = 'UpdateClusterLevelField',
  UserFilterField = 'UserFilterField',
}
