import { ElMessage } from 'element-plus';

import { ErrorCode, ErrorCodeMessages } from '@/modules/shared/types/error-codes';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export class MessageService {
  public static generalError() {
    ElMessage.error({
      message: t('general.error'),
      duration: 2000,
    });
  }

  public static searchCancelled() {
    ElMessage.info({
      message: t('search_cancelled'),
      duration: 2000,
    });
  }

  public static uniqueReportNameNeeded() {
    ElMessage.error({
      message: t('unique_report_name_needed'),
      showClose: true,
    });
  }

  public static successfulReportCopy(name: string) {
    ElMessage.success({
      message: t('successful_report_copy', {
        name,
      }),
      showClose: true,
    });
  }

  public static failedRequest() {
    ElMessage.error({
      message: t('failed_request_message'),
      showClose: true,
    });
  }

  public static realTimeRequestSuccess() {
    ElMessage.success({
      message: t('realtime_bookings_request_success'),
      showClose: true,
      duration: 10000,
    });
  }

  public static refineSearch() {
    ElMessage.info({
      message: t('messages.refine_search'),
      showClose: true,
      duration: 5000,
    });
  }

  public static invalidFilter() {
    ElMessage.error({
      message: t('messages.invalid_filter'),
      duration: 2000,
    });
  }

  public static noPlannedFlights() {
    ElMessage.info({
      message: t('messages.no_planned_flights'),
      showClose: true,
      duration: 5000,
    });
  }

  public static responseOverload(): void {
    ElMessage.error({
      message: t('messages.response_overload'),
      showClose: true,
      duration: 5000,
    });
  }

  public static savedSuccessfully(name: string) {
    ElMessage.success({
      message: t('messages.saved', [name]).toString(),
      duration: 5000,
      showClose: true,
    });
  }

  public static success(message = t('success_message')) {
    ElMessage.success({
      message: message,
      duration: 5000,
      showClose: true,
    });
  }

  public static error(message: string = ErrorCodeMessages.get(ErrorCode.DefaultError)) {
    ElMessage.error({
      message: message,
      duration: 5000,
      showClose: true,
    });
  }

  public static noPermission(redirected?: boolean): void {
    redirected ? MessageService.warning(t('route_access_not_allowed_redirected')) : MessageService.warning(t('route_access_not_allowed'));
  }

  public static warning(message: string) {
    ElMessage.warning({
      message: message,
      dangerouslyUseHTMLString: true,
      duration: 5000,
      showClose: true,
    });
  }

  public static info(message: string) {
    ElMessage.info({
      message: message,
      duration: 5000,
      showClose: true,
    });
  }

  public static reportTimedOut() {
    ElMessage.error({
      message: t('general.report_timed_out'),
      duration: 5000,
    });
  }

  public static noChangesToRevertDetected(): void {
    ElMessage.warning({
      message: t('no_changes_to_revert'),
      type: 'warning',
      showClose: true,
    });
  }

  public static noChangesDetected(): void {
    ElMessage.warning({
      message: t('no_changes_detected'),
      type: 'warning',
      showClose: true,
    });
  }
}
