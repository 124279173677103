import { FormatService } from '@/services/format.service';

export function formatNumber(amount: any, showPosNegSymbol: any = false, round: any = false): string {
  if (typeof amount !== 'number') {
    return '';
  }

  if (round) {
    amount = Math.round(amount);
  }

  let formattedNumber = FormatService.formatNumber(Math.abs(amount));

  if (showPosNegSymbol) {
    const prefix = amount > 0 ? '+' : amount < 0 ? '-' : '';
    formattedNumber = prefix + formattedNumber;
  }

  return formattedNumber;
}
