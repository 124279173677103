import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const SYSTEM_SETTINGS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/system',
    name: RouteName.system,
    meta: {
      authorities: [Authority.SystemSettingRead],
    },
    component: () => import('@/modules/system-settings/views/SystemSettings.vue'),
  },
];
