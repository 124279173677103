import { CellClassParams, ColDef, ValueGetterParams } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';

import { generateCabinPickupColumnId, generateFlightPickupColumnId } from '@/models/enums/grid';
import { BookingsPickupModel, FlightLineModel, PerformanceBandPickupModel } from '@/modules/api/flight/flight-contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';

import { NumberColumnFilterSettings } from './base';

const { t } = i18n.global;

export type PickupGenerateLevel = 'flight' | 'cabin' | 'class';

function translateBookingsPickupHeader(pickupDay: number): string {
  return pickupDay === 0
    ? t('shared.real_time_bookings_pickup').toString()
    : t('bookings_pickup_days_short', { days: pickupDay }).toString();
}

function translateBookingsPickupHeaderTooltip(pickupDay: number): string {
  return pickupDay === 0 ? t('shared.real_time_bookings_pickup').toString() : t('bookings_pickup_days', { days: pickupDay }).toString();
}

export const generateBookingsPickupCols = (
  generateLevel: PickupGenerateLevel,
  pickupDays: number[],
  cabinCode?: string,
  sortable = true,
): ColDef<FlightLineModel>[] => {
  if (isEmpty(pickupDays)) {
    return [];
  }

  return pickupDays.map((pickupDay: number) => {
    const pickupColumn: ColDef = {
      ...NumberColumnFilterSettings,
      headerName: translateBookingsPickupHeader(pickupDay),
      cellRenderer: 'GridBookingsPickupRenderer',
      minWidth: 35,
      width: 35,
      sortable,
      hide: false,
      colId:
        generateLevel === 'cabin' && cabinCode
          ? generateCabinPickupColumnId(cabinCode, 'bookings', pickupDay)
          : generateFlightPickupColumnId('bookings', pickupDay),
      cellClass: ({ data }: CellClassParams) => `data-test-cabin-booking-pickups-${pickupDay}-${data.ondId} marginless-cell`,
      headerTooltip: translateBookingsPickupHeaderTooltip(pickupDay),
      comparator: StringOrNumberComparator,
      valueGetter: (params: ValueGetterParams) => {
        let bookingsPickupSource: BookingsPickupModel;
        switch (generateLevel) {
          case 'flight':
          case 'class':
            bookingsPickupSource = params.data?.bookingsPickUps?.find((pickup: BookingsPickupModel) => pickup.dayOffset === pickupDay);
            break;
          case 'cabin':
            bookingsPickupSource = FlightService.getMatchedCabin(params.data, cabinCode)?.bookingsPickUps?.find(
              (pickup: BookingsPickupModel) => pickup.dayOffset === pickupDay,
            );
            break;
        }
        return bookingsPickupSource ? (bookingsPickupSource.bookings || 0) - (bookingsPickupSource.cancellations || 0) : null;
      },
      cellRendererParams: {
        cabinCode,
        pickupDay,
      },
    };
    return pickupColumn;
  });
};

export function translatePerformanceBandPickupHeader(pickupDay: number): string {
  return pickupDay === 0
    ? t('shared.real_time_performance_band_pickup').toString()
    : t('performance_band_pickup_days_short', { days: pickupDay }).toString();
}

export function translatePerformanceBandPickupHeaderTooltip(pickupDay: number): string {
  return pickupDay === 0
    ? t('shared.real_time_performance_band_pickup').toString()
    : t('performance_band_pickup_days', { days: pickupDay }).toString();
}

export const generatePerformanceBandPickupCols = (
  generateLevel: PickupGenerateLevel,
  pickupDays: number[],
  cabinCode?: string,
): ColDef[] => {
  if (isEmpty(pickupDays)) {
    return [];
  }

  return pickupDays.map((pickupDay: number) => {
    const pickupColumn: ColDef = {
      ...NumberColumnFilterSettings,
      headerName: translatePerformanceBandPickupHeader(pickupDay),
      cellRenderer: 'GridDifferenceRenderer',
      minWidth: 35,
      width: 35,
      sortable: true,
      hide: false,
      colId:
        generateLevel === 'cabin' && cabinCode
          ? generateCabinPickupColumnId(cabinCode, 'performance-band', pickupDay)
          : generateFlightPickupColumnId('performance-band', pickupDay),
      cellClass: ({ data }: CellClassParams) =>
        `ag-right-aligned-cell data-test-cabin-performance-band-pick-up-day-${pickupDay}-${data.ondId} marginless-cell`,
      headerTooltip: translatePerformanceBandPickupHeaderTooltip(pickupDay),
      comparator: StringOrNumberComparator,
      valueGetter: (params: ValueGetterParams) => {
        const performanceBandPickupSource = FlightService.getMatchedCabin(params.data, cabinCode)?.performanceBandPickUps?.find(
          (pickup: PerformanceBandPickupModel) => pickup.dayOffset === pickupDay,
        );
        return performanceBandPickupSource?.performanceBandDifference;
      },
      cellRendererParams: {
        cabinCode,
        pickupDay,
      },
    };
    return pickupColumn;
  });
};
