<template>
  <div class="sticky-header">
    <div class="sticky-header__top">
      <div class="sticky-header__title">
        <slot name="title"></slot>
      </div>

      <div class="sticky-header__menu">
        <slot name="menu"></slot>
      </div>
    </div>

    <div ref="contentRef">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const contentRef = ref<Element>();

const emit = defineEmits<{ (e: 'contentMounted', v: Element): void }>();

onMounted(() => {
  if (contentRef.value) emit('contentMounted', contentRef.value);
});
</script>

<style lang="scss" scoped>
.sticky-header {
  display: flex;
  flex-direction: column;
  top: 0;
  background: white;

  &__top {
    padding-top: 17px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    h1 {
      margin-bottom: 0;
      line-height: 1em;
    }
  }

  &__menu,
  &__title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__menu {
    justify-content: flex-end;
  }
}
</style>
