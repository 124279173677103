import { cloneDeep } from 'lodash-es';

import { CabinStructure, ClassStructure } from '@/modules/api/application/application-contracts';
import { AppSettingsModule } from '@/store/modules/app-settings.module';

export class InventoryStructureService {
  public static getNonDiscreteControlClasses(cabinCode: string): ClassStructure[] {
    const cabin = AppSettingsModule.inventoryConfigurationProperties.cabins.find((cab) => cab.code === cabinCode);

    if (!cabin) {
      return [];
    }

    return cabin.classes.filter((cls) => !cls.discrete && !cls.excludeInLAF);
  }

  public static sortCabinsWithEconomyFirst(cabins: CabinStructure[]): CabinStructure[] {
    return cloneDeep(cabins).sort((a) => (AppSettingsModule.economyCabin.code === a.code ? -1 : 1));
  }
}
