import { ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import {
  generateInventoryTacticsActiveOptimisationTacticsColumnsState,
  generateInventoryTacticsShadowOptimisationTacticsColumnsState,
  generateInventoryTacticsSharedFlightCabinColumnsState,
  getInventoryTacticsActiveOptimisationTacticsColumns,
  getInventoryTacticsShadowOptimisationTacticsColumns,
  getInventoryTacticsSharedFlightCabinColumns,
} from '@/models/columns/collections/inventory-tactics/shared';
import { ColumnGroupId } from '@/models/enums/grid';
import { PssName } from '@/modules/api/application/application-contracts';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export const InventoryTacticColumns1n = (
  pss: PssName,
  performanceBandPickupDays: number[],
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
): ColGroupDef[] => {
  const inventoryTacticsColumns = [
    {
      headerName: `${t('flight')} | ${t('cabin')}`,
      groupId: ColumnGroupId.CabinGroup,
      children: getInventoryTacticsSharedFlightCabinColumns(pss, performanceBandPickupDays),
    },
    {
      headerName: `${t('active_optimisation_tactics')}`,
      groupId: ColumnGroupId.ActiveOptimisationTacticsGroup,
      children: [...getInventoryTacticsActiveOptimisationTacticsColumns()],
    },
  ];

  if (customerSettings.hasShadowTacticsEnabled) {
    inventoryTacticsColumns.push({
      headerName: t('shadow_tactics'),
      groupId: ColumnGroupId.ShadowOptimisationTacticsGroup,
      children: [...getInventoryTacticsShadowOptimisationTacticsColumns()],
    });
  }

  return inventoryTacticsColumns;
};

export const generateDefaultColumnState1n = (
  performanceBandPickupDays: number[],
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
): ColumnState[] => {
  const columns = [
    ...generateInventoryTacticsSharedFlightCabinColumnsState(performanceBandPickupDays),
    ...generateInventoryTacticsActiveOptimisationTacticsColumnsState(),
  ];

  if (customerSettings.hasShadowTacticsEnabled) {
    columns.push(...generateInventoryTacticsShadowOptimisationTacticsColumnsState());
  }
  return columns;
};
