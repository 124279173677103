import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../api/flight-actions-contracts';

const { t } = i18n.global;

export type RemoveSourceFlightPayload = BaseFlightActionPayload;

export class RemoveSourceFlightAction implements FlightAction<RemoveSourceFlightPayload> {
  public actionType: FlightActionType = FlightActionType.removeSourceFlight;
  public label = t('remove_source_flight');
  public value: number;

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: 'removeSourceFlight',
      meta: {
        label: this.label,
        flightActionType: FlightActionType.removeSourceFlight,
        field: FilterFieldField.removeSourceFlight,
        type: FilterFieldType.equal,
        valueKey: 'removeSourceFlight',
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setPayload(payload: RemoveSourceFlightPayload): void {
    // we don't need to set a value for this action
    this.value = undefined;
  }

  public getPayload(): RemoveSourceFlightPayload {
    const flightAction: RemoveSourceFlightPayload = {
      actionType: this.actionType,
    };

    return flightAction;
  }
}
