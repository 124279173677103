import { defineStore } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { CreateRouteGroupPayload, RouteGroup } from '@/modules/route-management/api/route-groups.contracts';
import { routeGroupsService } from '@/modules/route-management/api/route-groups.service';

export const useRouteGroupsStore = defineStore('routeGroups', () => {
  const _routeGroups: Ref<RouteGroup[]> = ref([]);
  const routeGroups: ComputedRef<RouteGroup[]> = computed(() => _routeGroups.value.sort((a, b) => a.name.localeCompare(b.name)));

  async function get(): Promise<void> {
    _routeGroups.value = await routeGroupsService.getAll();
  }

  async function create(routeGroup: CreateRouteGroupPayload): Promise<RouteGroup> {
    const newGroup = await routeGroupsService.post(routeGroup);
    _routeGroups.value = [...routeGroups.value, newGroup];
    return newGroup;
  }

  function $reset(): void {
    _routeGroups.value = [];
  }

  return {
    routeGroups,
    get,
    create,
    $reset,
  };
});
