import firebase from 'firebase/app';
import 'firebase/auth';
import { Plugin } from 'vue';

import { Environment } from '@/environment';

export const firebaseConfig = {
  apiKey: 'AIzaSyAs_JG2ctj0y99eehBqj1-zowLy7fNbDIY',
  authDomain: 'kambrinc.firebaseapp.com',
};

export const Firebase: Plugin = {
  install() {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().tenantId = Environment.googleTenantId;
  },
};
