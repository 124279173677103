export const en = {
  system_settings: {
    titles: {
      system_settings: 'System Settings',
    },
    setting: {
      pss_provider: {
        title: 'PSS Provider',
        description: 'The PSS Eddy receives data from.',
      },
      current_version: {
        title: 'Current Version',
        description: 'The current version of Eddy.',
      },
      continuous_pricing_multiplier: {
        title: 'Pricing Multiplier',
        description: 'Pricing Aggregation Multiplier for Continuous Pricing.',
      },
      restart_system: {
        title: 'System Restart',
        description: 'Restarts the PSS data import service.',
      },
      realtime_schedule: {
        title: 'Realtime Update Schedule',
        description: 'The Schedule of the realtime updates.',
      },
    },
    status: {
      loading_schedule: 'Loading Schedule...',
      error_loading_schedule: 'Error loading Schedule.',
      no_schedule_defined: 'No Schedule defined.',
      system_restart_success: 'System restart has been successfully initiated. Please wait for 5 minutes and login again.',
    },
    labels: {
      ndo: 'NDO',
    },
    actions: {
      restart: 'Restart',
    },
  },
};
