import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { useInsightsStore } from '@/modules/insights/store/insights.store';
import { RouteName } from '@/modules/shared';

function hasInsightsEnabled(): boolean {
  const customerSettingsStore = useCustomerSettingsStore();
  return customerSettingsStore.settings!.hasInsightsEnabled;
}

export async function redirectWhenOnlyOneInsight(
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  try {
    await useInsightsStore().getEnabledInsights();
    const insights = useInsightsStore().insights;

    if (insights.length === 1) {
      next({ name: RouteName.insightsDetails, params: { insightName: insights[0].name } });
    } else {
      next();
    }
  } catch (error) {
    next();
  }
}

export const INSIGHTS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'insights',
    name: RouteName.insights,
    beforeEnter: [redirectWhenOnlyOneInsight],
    component: async () => import('../views/InsightsOverview.vue'),
    meta: {
      authorities: [Authority.InsightsView],
      isAllowed: hasInsightsEnabled,
    },
  },

  {
    path: 'insights/:insightName',
    name: RouteName.insightsDetails,
    component: () => import('../views/InsightsDetailsView.vue'),
    meta: {
      authorities: [Authority.InsightsView],
      isAllowed: hasInsightsEnabled,
    },
  },
];
