import {
  aircraftTypeFilter,
  carrierCodeFilter,
  clusterFilter,
  flightPathFilter,
  generateTagFilter,
  hubFilter,
  optimizationProfileFilter,
  optimizationTacticFilter,
  routeGroupFilter,
  userFilter,
} from '@/models/filters/definitions/control';
import {
  dayOfWeekFilter,
  departureDateRangeFilter,
  destinationFilter,
  flightNumberFilter,
  originFilter,
} from '@/models/filters/definitions/shared';
import { RouteFilterType } from '@/modules/api/shared-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { ITag } from '@/modules/tags';

export function generateControlFilters(tags: ITag[], filterType?: RouteFilterType): FilterFieldDefinition[] {
  const filters: FilterFieldDefinition[] = [
    dayOfWeekFilter,
    departureDateRangeFilter,
    generateTagFilter(tags),
    userFilter,
    flightNumberFilter,
    aircraftTypeFilter,
    routeGroupFilter,
    carrierCodeFilter,
    optimizationProfileFilter,
    optimizationTacticFilter,
    clusterFilter,
  ];
  switch (filterType) {
    case RouteFilterType.origin_destination:
      filters.push(originFilter, destinationFilter);
      break;
    case RouteFilterType.hub:
      filters.push(hubFilter, flightPathFilter);
      break;
    default:
      // TODO: (KB) when would you want to have all the filters available with a combination of both market types?
      filters.push(originFilter, destinationFilter, hubFilter, flightPathFilter);
      break;
  }
  return filters;
}
