import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';
import { App, Plugin } from 'vue';

export const AgGrid: Plugin = {
  install(app: App) {
    LicenseManager.setLicenseKey(
      'CompanyName=Kambr,LicensedApplication=Eddy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-028964,ExpiryDate=2_June_2023_[v2]_MTY4NTY2MDQwMDAwMA==8cf8500cee2b8737a1568b937ed99f6e',
    );

    app.component('AgGridVue', AgGridVue);
  },
};
