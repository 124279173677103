import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface SetPricingAdjustmentPayload extends BaseFlightActionPayload {
  cabinCode: string;
  value: number;
}

export class SetPricingAdjustmentAction implements FlightAction<SetPricingAdjustmentPayload> {
  public actionType: FlightActionType = FlightActionType.setPricingAdjustment;
  public label = t('dynamic_filters.tactical_adjustment');
  public value;
  public cabinCode: string;
  public showBulkAvailabilityWarning: boolean;

  constructor(cabinCode?: string, value?: number) {
    this.cabinCode = cabinCode;
    this.value = value ?? 0;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.tacticalAdjustment,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.setPricingAdjustment,
        field: FilterFieldField.tacticalAdjustment,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.tacticalAdjustment,
        uniqueField: this.cabinCode,
        isValueValid: () => true,
        componentName: ComponentName.TacticAdjustmentSlider,
        value: this.value,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: SetPricingAdjustmentPayload): void {
    this.value = payload.value;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): SetPricingAdjustmentPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      value: this.value,
    };
  }
}
