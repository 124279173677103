export const en = {
  grid: {
    headings: {
      column_view: 'Column View',
      column_sizing: 'Column Sizing',
      column_filters: 'Column Filters',
    },
    columns: {
      comparison_lower: '{unitA} is {value} lower than {unitB}',
      comparison_equal: '{unitA} is equal to {unitB}',
      comparison_higher: '{unitA} is {value} higher than {unitB}',
    },
    actions: {
      save_as_default: {
        action: 'Save as Default',
        description:
          'Save as Default: Save the current grid settings as the user default settings. Use the Reset to Default option to restore the user default grid settings.',
      },
      reset_to_default: {
        action: 'Reset to Default',
        description: 'Reset to Default: Restore the user default grid settings.',
      },
      factory_reset: {
        action: 'Factory Reset',
        description:
          'Factory Reset: Restore the application default grid settings. To set this state as the user default grid settings, use the Save as Default option after the Factory Reset.',
      },
      autosize_all: {
        action: 'Autosize All',
        description: 'Autosize All: Adjust the column width to fit the contents of the cell.',
      },
      size_to_fit: {
        action: 'Size to Fit',
        description: 'Size to Fit: Fill the screen with the columns that are selected to be displayed.',
      },
      clear_all_x_filters: 'Clear All {n} Filters',
    },
    confirmations: {
      save_as_default: {
        title: 'Save as Default',
        message:
          'Save the current grid settings as the user default settings. The existing default grid settings will be overwritten. Are you sure you want to continue?',
      },
      reset_to_default: {
        title: 'Reset to Default',
        message: 'Replace the current grid settings with the user default grid settings. Are you sure you want to continue?',
      },
      factory_reset: {
        title: 'Factory Reset',
        message: 'Replace the current grid settings with the application default grid settings. Are you sure you want to continue?',
      },
    },
    errors: {
      failed_to_save_default_view: 'Failed to save as default grid view',
    },
  },
};
