export const en = {
  control: {
    titles: {
      review_queue: 'Review Queue',
    },
    cross_filters: {
      performance_band_pickup: `{n} day - Performance Band Pickup`,
      op_level: 'OP Level',
    },
    cabin_x: `Cabin {cabin}`,
    zoom_to_selection: 'Zoom to selection',
    reset_selection: 'Reset selection',
    configuration: {
      option: {
        flight: 'Flight',
        cabin: 'Cabin {cabin}',
      },
    },
    columns: {
      expected_no_shows: 'Expected No Shows',
      expected_no_shows_short: 'Expected NS',
      recommended_overbooking_factor_short: 'rOVB Factor',
      recommended_overbooking_factor: 'Recommended Overbooking Factor',
      overbooking_risk_short: 'OVB Risk',
      overbooking_risk: 'Overbooking Risk',
      overbooking_autopilot_short: 'OVB Autopilot',
      overbooking_autopilot: 'Overbooking Autopilot',
      equipment_version_short: 'EqpV',
      equipment_version: 'Equipment Version',
      equipment_type_short: 'EqpT',
      equipment_type: 'Equipment Type',
      sales_configuration_short: 'Config',
      sales_configuration: 'Sales Configuration',
    },
    bulk_actions: {
      apply_recommended_overbooking: 'Apply rOVB',
      move_recommended_overbooking_risk_percentage_up: 'Increase rOVB Risk %',
      move_recommended_overbooking_risk_percentage_down: 'Decrease rOVB Risk %',
      set_overbooking_autopilot: 'Set OVB Autopilot',
    },
  },
};
