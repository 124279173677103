import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import { ICustomerDefinedDataState } from '@/modules/customer-defined-data/store/customer-defined-data.module.model';
import { IContinuousPricingState } from '@/modules/route-management/store/modules/continuous-pricing.module';
import { IAuthState } from '@/store/modules/auth.module';
import { IControlState } from '@/store/modules/control.module';
import { IFlightState } from '@/store/modules/flight.module';
import { IMarketInfoState } from '@/store/modules/market-info.module';
import { IRouteManagementState } from '@/store/modules/route-management.module';
import { IUserConfigState } from '@/store/modules/user-config.module';

// Root state, add your module type here to get type checking and autocomplete
// when using a module in a component / view.
export interface IRootState {
  auth: IAuthState;
  continuousPricing: IContinuousPricingState;
  control: IControlState;
  flight: IFlightState;
  marketInfo: IMarketInfoState;
  routeManagement: IRouteManagementState;
  userConfig: IUserConfigState;
  customerDefinedData: ICustomerDefinedDataState;
}

// Throw a raw error instead of a vuex module decorator wrapped error
// https://github.com/championswimmer/vuex-module-decorators/issues/224
config.rawError = true;

// Declare empty store first, dynamically register all modules later.
export const store = createStore<IRootState>({
  strict: false,
});
