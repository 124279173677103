import { isArray } from 'lodash-es';

import { FilterField, FilterFieldField, FilterFieldType, FilterGroup, FilterGroupType } from '@/modules/api/shared-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { getFlightNumberRangeQueryString } from '@/utils';

import { ConversionService } from './conversion.service';

export class FilterService {
  public static transformRequest(request: FilterFieldDefinition[]): {
    type: FilterGroupType;
    fields: Array<FilterField>;
  } {
    return {
      type: FilterGroupType.and,
      fields: request
        .filter((item: FilterFieldDefinition): FilterField => {
          if ((isArray(item.value) && item.value.length === 0) || item.value === null || item.value === '') {
            return;
          }
          return item;
        })
        .map((item: FilterFieldDefinition) =>
          item.generateCustomFilter
            ? item.generateCustomFilter(item)
            : {
                field: item.field,
                type: item.type,
                value: item.value,
              },
        )
        .filter((elm) => elm !== null) as FilterField[],
    };
  }

  public static transformFlightNumbersToFilterFields(flightNumberRangesAsString: string) {
    try {
      const flightNumberRanges: number[][] = ConversionService.getNumberRangesFromString(flightNumberRangesAsString);

      // If there is only one range or number
      if (flightNumberRanges.length === 1) {
        return {
          field: FilterFieldField.flightNumber,
          type: flightNumberRanges[0].length === 1 ? FilterFieldType.equal : FilterFieldType.between,
          value:
            flightNumberRanges[0].length === 1 ? flightNumberRanges[0][0].toString() : [flightNumberRanges[0][0], flightNumberRanges[0][1]],
        };
      } else {
        // If there are multiple ranges and/or numbers
        return {
          type: FilterGroupType.or,
          fields: flightNumberRanges.map((range) => {
            if (range.length === 1) {
              return {
                field: FilterFieldField.flightNumber,
                type: FilterFieldType.equal,
                value: range[0].toString(),
              };
            } else {
              return {
                field: FilterFieldField.flightNumber,
                type: FilterFieldType.between,
                value: [range[0], range[1]],
              };
            }
          }),
        };
      }
    } catch (e) {
      return null;
    }
  }

  public static transformFilterFieldToFlightNumberRange(filter: FilterField | FilterGroup) {
    const filterField = filter as unknown as FilterField;

    if (!filterField.value || (filterField.value as any).length === 0) {
      // TODO: Improve by splitting filters and groups
      const filterGroup = filter as unknown as FilterGroup;

      return filterGroup.fields &&
        filterGroup.fields.some((field) => (field as unknown as FilterField).field === FilterFieldField.flightNumber)
        ? getFlightNumberRangeQueryString(filterGroup.fields)
        : undefined;
    }

    return filterField.type === FilterFieldType.between ? (filterField.value as number[]).join('-') : (filterField.value as any);
  }
}
