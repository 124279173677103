import { GridState } from '@/store/modules/user-config.module';

export function generateBookingDetailsColumnDefaultState(): GridState {
  return {
    columnsState: [
      {
        colId: 'ag-Grid-AutoColumn-bookingDate',
        width: 250,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'ag-Grid-AutoColumn-bookingCode',
        width: 250,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'ag-Grid-AutoColumn-bookingAgent',
        width: 250,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'ag-Grid-AutoColumn-classCode',
        width: 250,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'ag-Grid-AutoColumn-fareBasisCode',
        width: 250,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'bookingDate',
        width: 117,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: true,
        rowGroupIndex: 0,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'boardingSequence',
        width: 113,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'bookingCode',
        width: 116,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: true,
        rowGroupIndex: 1,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'ticketCode',
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'fareBasisCode',
        width: 110,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: true,
        rowGroupIndex: 4,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'classCode',
        width: 95,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: true,
        rowGroupIndex: 3,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'bookingStatus',
        width: 102,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'currency',
        width: 122,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrency',
        width: 89,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'passengerType',
        width: 123,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'count',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'netFare',
        width: 151,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyNetFare',
        width: 156,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'tax1',
        width: 124,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyTax1',
        width: 129,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'tax2',
        width: 127,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyTax2',
        width: 132,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'tax3',
        width: 127,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyTax3',
        width: 132,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'tax4',
        width: 128,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyTax4',
        width: 133,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'tax5',
        width: 127,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyTax5',
        width: 132,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'tax6',
        width: 127,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyTax6',
        width: 133,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'totalCost',
        width: 187,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'baseCurrencyTotalCost',
        width: 192,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'bookingAgent',
        width: 147,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: true,
        rowGroupIndex: 2,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'sellingAgent',
        width: 112,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'bookingTime',
        width: 115,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'neutralUnitOfConstruct',
        width: 90,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'promotionFlag',
        width: 105,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'discount',
        width: 80,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'dynamicPriceAdjustment',
        width: 151,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'priceAdjustmentApplied',
        width: 120,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'seatAssignment',
        width: 89,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'liftStatus',
        width: 82,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'cabinCode',
        width: 99,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'nestCode',
        width: 91,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'typeCode',
        width: 86,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'flightSequence',
        width: 128,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'journeyKey',
        width: 216,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'daysBookingInAdvance',
        width: 114,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'hourOfBooking',
        width: 115,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
    ],
    isPivotMode: false,
  };
}
