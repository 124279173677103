export const en = {
  data_import: {
    data_import: 'External File Importer',
    select_files: 'Select File(s)',
    validate: 'Validate',
    import: 'Import',
    import_apply: 'Import and Apply',
    overwrite: 'Overwrite',
    validating: 'Validating',
    importing: 'Importing',
    validation_successful: 'Validation Successful',
    please_dont_close_this_window: 'Please do not close this window',
    import_completed: 'Import completed',
    select_more_files: 'Select more files',
    drop_files_here: 'Drop your .csv file(s) here or click here to upload.',
    csv_files_max_10mb: 'The file size must be less than 10 MB.',
    validation_failed: 'Validation failed',
    parsing_failed: 'Parsing failed',
    columns_couldnt_get_parsed: "Some columns in the file couldn't get parsed",
    parsing_failed_message:
      "Some fields in the document couldn't be parsed, please check if the following columns in the file are present and properly named",
    more_info: 'More Info',
    download_template: 'Download Template',
    start_import: 'Start Import',
    tag_name: 'Tag Name',
    linked_class_rule_name: 'Linked Class Rule Name',
    rival_rule_name: 'Rival Rule Name',
    reason: 'Reason',
    line_number: 'Line Number',
    specific_error: 'Specific Error',
    line_content: 'Line Content',
    tag_assignments: 'Tag Assignments',
    tag_assignments_description: 'Assign new and existing tags to flights.',
    no_import: 'No Import',
    flight_not_found: 'Flight not found',
    tag_not_found: 'Tag not found',
    profile_cabin_not_found: 'Profile Cabin not found',
    flight_cabin_not_found: 'Flight Cabin not found',
    profile_not_found: 'Profile not found',
    profile_level_not_found: 'Profile Level not found',
    cluster_not_found: 'Cluster not found',
    cluster_cabin_not_found: 'Cluster Cabin not found',
    cluster_not_assigned_to_cabin: 'Cluster not assigned to Cabin',
    optimization_profile_level_assignments: 'Optimization Profile Level Assignments',
    optimization_profile_level_assignments_description: 'Assign existing optimization profile levels to flights.',
    source_flight_assignments: 'Source Flight Assignments',
    source_flight_assignments_description: 'Assign source flights to flights.',
    source_flight_not_found: 'Source flight not found',
    file_size_exceeded: 'Maximum file size ({maximum}MB) exceeded, please upload a smaller file.',
    currency_not_found: 'Currency not found',
    duplicate_records: 'Records are duplicate',

    linked_class_rule_assignments: 'Linked Class Rule Assignments',
    linked_class_rule_assignments_description: 'Assign existing linked class rules to flights.',
    linked_class_rule_not_found: 'Linked Class Rule not found',

    rival_rule_assignments: 'Rival Rule Assignments',
    rival_rule_assignments_description: 'Assign existing rival rules to flights.',
    duplicate_assignment_found: 'Duplicate assignment to flight',

    clean_slate_description:
      'When Overwrite is enabled, importing fares will override all existing fare values set previously. This is equivalent to starting over and importing a new set of fare values.',

    published_fares: {
      fares_discarded_from_file: 'Fares discard from file',
      fares_to_delete: 'Fares to delete',
      fares_to_import: 'Fares to import',
      rank: 'Rank',
      fares_to_be_imported:
        'The fares in the file are valid, but there were some overlapping records that will be discarded. The discarded fares are listed in the grid below.',
      fare_overlapping_rule: `We strongly recommend that you please review the overlapping fare records and delete the rows from your CSV that would not be applicable. And then re-import the file.
        However, you can choose to have Eddy automatically discard the overlapping fares based on the following logic:`,
      fare_overlapping_rule_1: 'The fare record (row) with the shortest departure date range takes precedence.',
      fare_overlapping_rule_2:
        'If the duration of the departure date ranges is the same in the overlapping records, then the fare record (row) with the shortest sales date range is chosen.',
      fare_overlapping_rule_3: 'If the entries for all fields are the same, the first fare record (row) listed will be selected.',
      fare_overlapping_last_warning:
        'If you are comfortable with the above overlapping fare records NOT being imported to Eddy, please click “Import” to complete the Published Fares import to Eddy.',
    },
  },
};
