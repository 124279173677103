export const Constants = {
  DEFAULT_FONT_SIZE: 13,
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_TIME_FORMAT: 'HH:mm:ss',
  storage: {
    ID_TOKEN_NAME: 'id_token',
    USER_CONFIG: 'user_config',
    SYSTEM_CONFIG: 'system_config',
  },
};
