<template>
  <div class="k-draggable-item" :class="{ disabled }" data-test="draggable-item">
    <div class="handle" :class="{ dimmed: disabled }">
      <font-awesome-icon :icon="['far', 'grip-lines']" fixed-width />
    </div>

    <div class="item">
      <slot></slot>
    </div>

    <div v-if="removable" class="remove">
      <slot name="remove">
        <el-button type="danger" plain class="data-test-delete-button" @click="emit('remove')">
          <font-awesome-icon :icon="['fal', 'times']" />
        </el-button>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{ disabled?: boolean; removable?: boolean }>();
const emit = defineEmits<{ (e: 'remove'): void }>();
</script>

<style lang="scss" scoped>
.k-draggable-item {
  align-items: center;
  border-radius: 4px;
  cursor: move;
  display: flex;
  gap: 4px;
  padding: 4px;
  transition: background-color 0.3s ease-in-out;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: $grey-6;

    .handle:not(.dimmed),
    .remove {
      opacity: 1;
      transition-delay: 0.1s;
    }

    .remove {
      pointer-events: auto;
    }
  }

  .item {
    flex-grow: 1;
  }

  .handle,
  .remove {
    flex: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0s;
  }

  .remove {
    display: flex;
    pointer-events: none;
  }
}
</style>
