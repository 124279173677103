import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export enum PromotionType {
  FixedPrices = 'FIXED_PRICES',
}

export interface PromotionTypeUI {
  label: string;
  value: PromotionType;
}

export const promotionUITypes: PromotionTypeUI[] = [
  {
    label: t('fixed_prices'),
    value: PromotionType.FixedPrices,
  },
];

export interface PromotionPricingPoint {
  sequence: number;
  price: number;
}

export interface PromotionStateUI {
  label: string;
  color: string;
  value: PromotionState;
}

export enum PromotionState {
  active = 'ACTIVE',
  planned = 'PLANNED',
  ended = 'ENDED',
}

export interface PromotionDaysEnabled {
  day1: boolean;
  day2: boolean;
  day3: boolean;
  day4: boolean;
  day5: boolean;
  day6: boolean;
  day7: boolean;
}

export interface PromotionDepartureDateRange {
  start: string;
  end: string;
}

export interface PromotionDepartureDateRangeUI {
  start: Date;
  end: Date;
  isValid: boolean;
  isSaved: boolean;
}

export interface PromotionModel {
  id?: number;
  name: string;
  description?: string;
  type?: PromotionType;
  state?: PromotionState;
  classCode: string;
  cabinCode: string;
  createdDateTime?: string;
  createdBy?: UserModel;
  startSaleDate: string;
  endSaleDate: string;
  departureDateRanges: PromotionDepartureDateRange[];
  pricePoints: PromotionPricingPoint[];
  daysEnabled: PromotionDaysEnabled;
}

export interface PromotionUIModel {
  id?: number;
  name: string;
  description?: string;
  type?: PromotionTypeUI;
  state: PromotionStateUI;
  classCode: string;
  cabinCode: string;
  createdDateTime?: string;
  createdBy?: UserModel;
  startSaleDate: Date;
  endSaleDate: Date;
  departureDateRanges: PromotionDepartureDateRangeUI[];
  pricePoints: PromotionPricingPoint[];
  daysEnabled: PromotionDaysEnabled;
}

export interface PromotionAssignmentModel {
  id: number;
  cabinCode: string;
  classCode: string;
  name: string;
  sequence: number;
  price: number;
  state: PromotionState;
}

export interface IPromotionService {
  getAll(): Promise<PromotionUIModel[]>;
  getAll(searchParams: { endSaleDate: string }): Promise<PromotionUIModel[]>;
  getByFlightId(flightId: number): Promise<PromotionUIModel[]>;
  getById(promotionId: number): Promise<PromotionUIModel>;
  create(promotion: PromotionUIModel): Promise<PromotionUIModel>;
  update(id: number, promotion: PromotionUIModel): Promise<PromotionUIModel>;
  delete(id: number): Promise<any>;
  copy(selectedPromotionId: number, name: string): Promise<PromotionUIModel[]>;
  isValid(promotion: PromotionUIModel): boolean;
}
