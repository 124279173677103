import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { NumberColumnFilterSettings } from '@/models/columns/definitions/base';
import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { FlightViewFlightLineRow, FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { formatNumber } from '@/modules/shared';
import { i18n } from '@/plugins/i18n';
import { FormatService } from '@/services/format.service';

const { t } = i18n.global;

//
//
// Flight Columns
//
//

export const ancillaryRevenueColumn: ColDef<FlightLineModel> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.ancillary_revenue.header_name'),
  headerTooltip: t('revenues.columns.ancillary_revenue.header_tooltip'),
  field: 'totalRevenue.ancillary.amount',
  sortable: true,
  valueFormatter: (params) => formatNumber(params.value),
  requiredPermission(params) {
    return !!params.customerSettings.includeAncillaryInTotalRevenue;
  },
};

export function ancillaryRevenueFlightColumn(): ColDef<FlightLineModel> {
  return {
    ...ancillaryRevenueColumn,
    colId: ColumnId.FlightAncillaryRevenue,
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) => data?.totalRevenue?.ancillary?.amount,
  };
}

export function ancillaryRevenueCabinColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...ancillaryRevenueColumn,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinAncillaryRevenue),
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) =>
      data?.cabins?.find((cabin) => cabin.code === cabinCode)?.totalRevenue?.ancillary?.amount,
  };
}

export const totalRevenueColumn: ColDef<FlightLineModel> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.total_revenue.header_name'),
  field: 'totalRevenue.amount',
  sortable: true,
  valueFormatter: (params) => formatNumber(params.value),
};

export function totalRevenueFlightColumn(): ColDef<FlightLineModel> {
  return {
    ...totalRevenueColumn,
    colId: ColumnId.FlightTotalFareRevenue,
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) => data?.totalRevenue?.amount,
  };
}

export function totalRevenueCabinColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...totalRevenueColumn,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinTotalFareRevenue),
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) =>
      data?.cabins?.find((cabin) => cabin.code === cabinCode)?.totalRevenue?.amount,
  };
}

export const averageAncillaryRevenuePerPaxColumn: ColDef<FlightLineModel> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.average_ancillary_revenue_per_pax.header_name'),
  headerTooltip: t('revenues.columns.average_ancillary_revenue_per_pax.header_tooltip'),
  field: 'totalRevenue.ancillary.averagePerPassenger',
  sortable: true,
  valueFormatter: (params: ValueFormatterParams<FlightLineModel>) => formatNumber(params.value),
  requiredPermission(params) {
    return !!params.customerSettings.includeAncillaryInTotalRevenue;
  },
};

export function averageAncillaryRevenuePerPaxFlightColumn(): ColDef<FlightLineModel> {
  return {
    ...averageAncillaryRevenuePerPaxColumn,
    colId: ColumnId.FlightAverageAncillaryRevenuePerPax,
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) => data?.totalRevenue?.ancillary?.averagePerPassenger,
  };
}

export function averageAncillaryRevenuePerPaxCabinColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...averageAncillaryRevenuePerPaxColumn,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinAverageAncillaryRevenuePerPax),
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) =>
      data?.cabins?.find((cabin) => cabin.code === cabinCode)?.totalRevenue?.ancillary?.averagePerPassenger,
  };
}

export const surchargeRevenueColumn: ColDef<FlightLineModel> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.surcharge_revenue.header_name'),
  headerTooltip: t('revenues.columns.surcharge_revenue.header_tooltip'),
  field: 'totalRevenue.surcharge.amount',
  sortable: true,
  valueFormatter: (params) => formatNumber(params.value),
  requiredPermission(params) {
    return !!params.customerSettings.includeSurchargeInTotalRevenue;
  },
};

export function surchargeRevenueFlightColumn(): ColDef<FlightLineModel> {
  return {
    ...surchargeRevenueColumn,
    colId: ColumnId.FlightSurchargeRevenue,
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) => data?.totalRevenue?.surcharge?.amount,
  };
}

export function surchargeRevenueCabinColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...surchargeRevenueColumn,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinSurchargeRevenue),
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) =>
      data?.cabins?.find((cabin) => cabin.code === cabinCode)?.totalRevenue?.surcharge?.amount,
  };
}

export const taxRevenueColumn: ColDef<FlightLineModel> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.tax_revenue.header_name'),
  headerTooltip: t('revenues.columns.tax_revenue.header_tooltip'),
  field: 'totalRevenue.tax.amount',
  sortable: true,
  valueFormatter: (params) => formatNumber(params.value),
  requiredPermission(params) {
    return !!params.customerSettings.includeTaxInTotalRevenue;
  },
};

export function taxRevenueFlightColumn(): ColDef<FlightLineModel> {
  return {
    ...taxRevenueColumn,
    colId: ColumnId.FlightTaxRevenue,
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) => data?.totalRevenue?.tax?.amount,
  };
}

export function taxRevenueCabinColumn(cabinCode: CabinCode): ColDef<FlightLineModel> {
  return {
    ...taxRevenueColumn,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinTaxRevenue),
    valueGetter: ({ data }: ValueGetterParams<FlightLineModel>) =>
      data?.cabins?.find((cabin) => cabin.code === cabinCode)?.totalRevenue?.tax?.amount,
  };
}

//
//
// Inventory Columns - Class level
//
//

export const totalRevenueClassColumn: ColDef<FlightViewFlightLineRow> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.total_revenue.header_name'),
  headerTooltip: t('revenues.columns.total_revenue.header_tooltip'),
  field: 'totalRevenue.amount',
  valueFormatter: (params) => FormatService.amountWithCurrency(params.value, params.data?.fareCurrency),
};

export const ancillaryRevenueClassColumn: ColDef<FlightViewFlightLineRow> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.ancillary_revenue.header_name'),
  headerTooltip: t('revenues.columns.ancillary_revenue.header_tooltip'),
  field: 'totalRevenue.ancillary.amount',
  valueFormatter: (params) => FormatService.amountWithCurrency(params.value, params.data?.fareCurrency),
  requiredPermission(params) {
    return !!params.customerSettings.includeAncillaryInTotalRevenue;
  },
  cellRenderer: 'GridAncillaryRevenueRenderer',
};

/**
 * This one is the same as {@link averageAncillaryRevenuePerPaxColumn} but with currency symbol.
 */
export const averageAncillaryRevenuePerPaxClassColumn: ColDef<FlightViewFlightLineRow> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.average_ancillary_revenue_per_pax.header_name'),
  headerTooltip: t('revenues.columns.average_ancillary_revenue_per_pax.header_tooltip'),
  field: 'totalRevenue.ancillary.averagePerPassenger',
  valueFormatter: (params) => FormatService.amountWithCurrency(params.value, params.data?.fareCurrency),
  requiredPermission(params) {
    return !!params.customerSettings.includeAncillaryInTotalRevenue;
  },
};

export const surchargeRevenueClassColumn: ColDef<FlightViewFlightLineRow> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.surcharge_revenue.header_name'),
  headerTooltip: t('revenues.columns.surcharge_revenue.header_tooltip'),
  field: 'totalRevenue.surcharge.amount',
  valueFormatter: (params) => FormatService.amountWithCurrency(params.value, params.data?.fareCurrency),
  requiredPermission(params) {
    return !!params.customerSettings.includeSurchargeInTotalRevenue;
  },
};

export const taxRevenueClassColumn: ColDef<FlightViewFlightLineRow> = {
  ...NumberColumnFilterSettings,
  headerName: t('revenues.columns.tax_revenue.header_name'),
  headerTooltip: t('revenues.columns.tax_revenue.header_tooltip'),
  field: 'totalRevenue.tax.amount',
  valueFormatter: (params) => FormatService.amountWithCurrency(params.value, params.data?.fareCurrency),
  requiredPermission(params) {
    return !!params.customerSettings.includeTaxInTotalRevenue;
  },
};

//
//
// Inventory Tactics Columns
//
//

export const ancillaryTotalRevenueRatioLegCabinColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  headerName: t('revenues.columns.total_ancillaries_ratio.header_name'),
  headerTooltip: t('revenues.columns.total_ancillaries_ratio.header_tooltip'),
  cellRenderer: 'GridRevenueRatioRenderer',
  field: 'totalRevenue.ancillary.ratio',
  valueFormatter: (params) => FormatService.amountWithCurrency(params.value, params.data?.fareCurrency),
  requiredPermission(params) {
    return !!params.customerSettings.includeAncillaryInTotalRevenue;
  },
};
