import { createRouter, createWebHistory } from 'vue-router';

import { featureGuard } from '@/modules/features';
import { globalGuard } from '@/modules/router/guards/global.guard';
import { ROUTES } from '@/modules/router/routes';

export const router = createRouter({
  history: createWebHistory(),
  routes: ROUTES,
});

router.beforeEach(globalGuard);
router.beforeEach(featureGuard);
