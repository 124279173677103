import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { ColumnId } from '@/models/enums/grid';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FormatService } from '@/services/format.service';

import { NumberColumnFilterSettings, TextColumnFilterSettings } from './base';

const { t } = i18n.global;

export const SourceFlightBookingsColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightBookings,
  headerName: t('source_bk_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_bk'),
  valueGetter: (params: ValueGetterParams) =>
    params.data.sourceFlight?.aircraftNdoBookings ? params.data.sourceFlight.aircraftNdoBookings : null,
};

export const SourceFlightGroupBookingsColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightGroupBookings,
  headerName: t('source_grp_bk_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_grp_bk'),
  valueGetter: (params: ValueGetterParams) =>
    params.data.sourceFlight?.aircraftNdoGroupBookings ? params.data.sourceFlight.aircraftNdoGroupBookings : null,
};

export const SourceFlightBoardingsColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightBoardings,
  headerName: t('source_brds_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_brds'),
  valueGetter: (params: ValueGetterParams) =>
    params.data.sourceFlight?.finalAircraftBoardings ? params.data.sourceFlight.finalAircraftBoardings : null,
};

export const SourceFlightGroupBoardingsColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightGroupBoardings,
  headerName: t('source_grp_boa_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_grp_boa'),
  valueGetter: (params: ValueGetterParams) =>
    params.data.sourceFlight?.finalAircraftGroupBoardings ? params.data.sourceFlight.finalAircraftGroupBoardings : null,
};

export const SourceFlightSeatAvailabilityColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightSeatAvailability,
  headerName: t('source_seat_avail_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_seat_avail'),
  valueGetter: (params: ValueGetterParams) =>
    params.data.sourceFlight?.seatAvailability ? params.data.sourceFlight.seatAvailability : null,
};

export const SourceFlightFinalBookingsColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightFinalBookings,
  headerName: t('source_fn_bk_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_fn_bk'),
  valueGetter: (params: ValueGetterParams) =>
    params.data.sourceFlight?.finalAircraftBookings ? params.data.sourceFlight.finalAircraftBookings : null,
};

export const SourceFlightFinalGroupBookingsColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightFinalGroupBookings,
  headerName: t('source_fn_grp_bk_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_fn_grp_bk'),
  valueGetter: (params: ValueGetterParams) =>
    params.data.sourceFlight?.finalAircraftGroupBookings ? params.data.sourceFlight.finalAircraftGroupBookings : null,
};

export const SourceFlightRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightRevenue,
  headerName: t('source_rev_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_rev'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const revenue = params.value;
    const data = params.data as FlightLineModel;

    if (revenue || revenue === 0) {
      return FormatService.amountWithoutCurrency(revenue, data.sourceFlight?.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.sourceFlight?.aircraftNdoRevenue ? Math.round(data.sourceFlight.aircraftNdoRevenue) : null;
  },
};

export const SourceFlightBoardedRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightBoardedRevenue,
  headerName: t('source_brd_rev_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_brd_rev'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const revenue = params.value;
    const data = params.data as FlightLineModel;

    if (revenue || revenue === 0) {
      return FormatService.amountWithoutCurrency(revenue, data.sourceFlight?.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.sourceFlight?.finalAircraftBoardedRevenue ? Math.round(data.sourceFlight.finalAircraftBoardedRevenue) : null;
  },
};

export const SourceFlightFinalRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightFinalRevenue,
  headerName: t('source_fn_rev_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_fn_rev'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const revenue = params.value;
    const data = params.data as FlightLineModel;

    if (revenue || revenue === 0) {
      return FormatService.amountWithoutCurrency(revenue, data.sourceFlight?.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.sourceFlight?.finalAircraftRevenue ? Math.round(data.sourceFlight.finalAircraftRevenue) : null;
  },
};

export const SourceFlightAverageFareColumn: ColDef = {
  ...NumberColumnFilterSettings,
  filterParams: {
    ...NumberColumnFilterSettings?.filterParams,
    filterOptions: ['greaterThan', 'inRange', 'lessThan'],
    defaultOption: 'greaterThan',
  },
  colId: ColumnId.SourceFlightAverageFare,
  headerName: t('source_avg_fare_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_avg_fare'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;
    const data = params.data as FlightLineModel;

    if (averageFare || averageFare === 0) {
      return FormatService.amountWithoutCurrency(averageFare, data.sourceFlight?.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.sourceFlight?.aircraftNdoAverageFare ? Math.round(data.sourceFlight.aircraftNdoAverageFare) : null;
  },
};

export const SourceFlightBoardedAverageFareColumn: ColDef = {
  ...NumberColumnFilterSettings,
  filterParams: {
    ...NumberColumnFilterSettings?.filterParams,
    filterOptions: ['greaterThan', 'inRange', 'lessThan'],
    defaultOption: 'greaterThan',
  },
  colId: ColumnId.SourceFlightBoardedAverageFare,
  headerName: t('source_brd_avg_fare_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_brd_avg_fare'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;
    const data = params.data as FlightLineModel;

    if (averageFare || averageFare === 0) {
      return FormatService.amountWithoutCurrency(averageFare, data.sourceFlight?.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.sourceFlight?.finalAircraftBoardedAverageFare ? Math.round(data.sourceFlight.finalAircraftBoardedAverageFare) : null;
  },
};

export const SourceFlightFinalAverageFareColumn: ColDef = {
  ...NumberColumnFilterSettings,
  filterParams: {
    ...NumberColumnFilterSettings?.filterParams,
    filterOptions: ['greaterThan', 'inRange', 'lessThan'],
    defaultOption: 'greaterThan',
  },
  colId: ColumnId.SourceFlightFinalAverageFare,
  headerName: t('source_fn_avg_fare_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('source_fn_avg_fare'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;
    const data = params.data as FlightLineModel;

    if (averageFare || averageFare === 0) {
      return FormatService.amountWithoutCurrency(averageFare, data.sourceFlight?.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.sourceFlight?.finalAircraftAverageFare ? Math.round(data.sourceFlight.finalAircraftAverageFare) : null;
  },
};

export const SourceFlightFareCurrencyColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.SourceFlightFareCurrency,
  headerName: t('general.source_currency_short'),
  type: 'leftAligned',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('general.source_currency'),
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.sourceFlight?.fareCurrency ? data.sourceFlight.fareCurrency : null;
  },
};
