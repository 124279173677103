import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import LinkedClassRulesDetails from '@/modules/rules/linked-class-rules/views/LinkedClassRulesDetails.vue';
import { RouteName } from '@/modules/shared/types/routes';

const hasLinkedClassRulesEnabled = (): boolean => {
  const customerSettingsStore = useCustomerSettingsStore();
  return !!customerSettingsStore.settings?.hasLinkedClassRulesEnabled;
};

export const LINKED_CLASSS_RULES_ROUTES: RouteRecordRaw[] = [
  {
    path: 'linked-class-rules/create',
    name: RouteName.linkedClassRulesCreate,
    meta: {
      authorities: [Authority.LinkedClassRulesUpdate],
      isAllowed: hasLinkedClassRulesEnabled,
    },
    component: LinkedClassRulesDetails,
  },
  {
    path: 'linked-class-rules/:id',
    name: RouteName.linkedClassRulesDetails,
    meta: {
      authorities: [Authority.LinkedClassRulesRead],
      isAllowed: hasLinkedClassRulesEnabled,
    },
    component: LinkedClassRulesDetails,
  },
];
