<template>
  <el-dropdown
    split-button
    type="success"
    :disabled="loading || disabled"
    data-test="dropdown-save-and-close-button"
    @click="onSaveAndClose"
    @command="onCommand"
  >
    {{ t('actions.save_and_close') }}

    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="save">
          {{ t('actions.save') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

withDefaults(defineProps<{ loading?: boolean; disabled?: boolean }>(), { loading: false, disabled: false });
const emit = defineEmits<{ (e: 'save'): void; (e: 'saveAndClose'): void }>();

const { t } = useI18n();

function onSaveAndClose() {
  emit('saveAndClose');
}

function onCommand() {
  emit('save');
}
</script>
