import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface ChangeUnbalancedAdjustmentPayload extends BaseFlightActionPayload {
  cabinCode: string;
  value: number;
}

/**
 * ChangeUnbalancedAdjustmentAction _is_ used in bulk, unlike the multi-leg variant which is only used in details.
 */
export class ChangeUnbalancedAdjustmentAction implements FlightAction<ChangeUnbalancedAdjustmentPayload> {
  public actionType: FlightActionType = FlightActionType.changeUnbalancedAdjustment;
  public label = t('dynamic_filters.change_unbalanced_adjustment');

  public cabinCode: string;
  public value = 0;

  constructor(cabinCode?: string, unbalancedAdjustment?: number) {
    this.cabinCode = cabinCode;
    if (unbalancedAdjustment) {
      this.value = unbalancedAdjustment;
    }
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.changeUnbalancedAdjustment,
      meta: {
        cabinCode: this.cabinCode,
        label: this.label,
        flightActionType: FlightActionType.changeUnbalancedAdjustment,
        field: FilterFieldField.changeUnbalancedAdjustment,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.changeUnbalancedAdjustment,
        uniqueField: this.cabinCode,
        componentName: ComponentName.AdjustmentRangeFilterField,
        warning: t('bulk_adjustment_change'),
        value: this.value.toString(),
        isValueValid: (value: any) => isValueNotEmpty(value),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: ChangeUnbalancedAdjustmentPayload): void {
    this.value = payload.value;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): ChangeUnbalancedAdjustmentPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      value: this.value,
    };
  }
}
