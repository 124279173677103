import moment, { Duration, Moment, unitOfTime } from 'moment';

import { Constants } from '@/constants';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export class DateTimeService {
  public static calculateDateDifference(
    later: string | Date | Moment,
    earlier: string | Date | Moment,
    timeUnit: unitOfTime.Diff = 'days',
  ): number | undefined {
    const laterMoment = moment(later);
    const earlierMoment = moment(earlier);

    return laterMoment.isValid() && earlierMoment.isValid() ? laterMoment.diff(earlierMoment, timeUnit) : undefined;
  }

  public static sanitizeDateTimeStamp(dateTimeString: string, timezone = 'Z'): string {
    return `${dateTimeString.split('.')[0]}${timezone}`;
  }

  public static getDurationInHoursAndMinutes(duration: Duration): string {
    let prefix = '';
    prefix = duration.asMinutes() >= 0 ? '+ ' : '- ';

    let hours = '';
    hours = `${Math.floor(Math.abs(duration.asHours()))}${t('hour_short')}`;

    let minutes = '';
    if (Math.round(duration.minutes()) !== 0) {
      minutes = ` ${Math.abs(Math.round(duration.minutes()))}${t('minute_short')}`;
    }

    // Return difference with prefix
    return `${prefix}${hours}${minutes}`;
  }

  /**
   *   Gets minutes and will return in a HH:mm format
   */
  public static getMinutesInHoursAndMinutes(totalInMinutes: number, showPlusByDefault = false): string {
    const prefix = totalInMinutes > 0 && showPlusByDefault ? '+' : totalInMinutes < 0 ? '-' : '';
    const hours = ('0' + Math.floor(Math.abs(totalInMinutes) / 60)).slice(-2);
    const minutes = ('0' + (Math.abs(totalInMinutes) % 60)).slice(-2);
    return prefix + hours + ':' + minutes;
  }

  public static calculateNdo(date1: Moment, date2: Moment): number {
    const duration = moment.duration(date2.diff(date1));
    // To make sure this works when time switches between regular time and daylight savings time: floor it when positive, ceil it when negative.
    return duration.asDays() >= 0 ? Math.floor(duration.asDays()) : Math.ceil(duration.asDays());
  }

  // Diff in milliseconds
  public static calculateTimeDifference(date1: Moment, date2: Moment) {
    return Math.abs(date1.diff(date2));
  }

  public static calculateLastBookingCaptureDateDifference(captureDate: string, lastBookingDate: string) {
    // Last booking date is the last date we saw a booking from the import / capture
    // So actual last booking was the day before this, so to make for this difference we need to add a day to calculated difference
    const daysAgo =
      DateTimeService.calculateDateDifference(
        DateTimeService.getUTCDate({
          date: captureDate,
          format: Constants.DEFAULT_DATE_FORMAT,
        }),
        DateTimeService.getUTCDate({
          date: lastBookingDate,
          format: Constants.DEFAULT_DATE_FORMAT,
        }),
        'days',
      ) + 1;

    if (daysAgo > 0) {
      return daysAgo;
    } else {
      return null;
    }
  }

  /**
   * Will return a moment object from the given date string; or the current date if no date is provided.
   * @returns the current date in UTC format with the time set to 00:00:00 if no time is provided in the date string.
   * @example
   * const date = DateTimeService.getUTCDate({ date: '2020-01-01T00:00:00.000Z' });
   * // date = 2020-01-01T00:00:00.000Z
   * @example
   * const date = DateTimeService.getUTCDate({ date: '2020-01-01' });
   * // date = 2020-01-01T00:00:00.000Z
   * @example
   * const date = DateTimeService.getUTCDate({ date: '2020-01-01T15:45:10.000Z', format: 'YYYY-MM-DD' });
   * // date = 2020-01-01T00:00:00.000Z
   * @example
   * const date = DateTimeService.getUTCDate();
   * // date = 2020-01-01T15:39:23.000Z
   */
  public static getUTCDate({
    date,
    format = moment.defaultFormatUtc,
  }: {
    date?: string | number | Date;
    format?: string;
  } = {}): Moment {
    if (!date) {
      return moment.utc();
    }
    return moment.utc(date, format);
  }

  public static isValidDate({
    date,
    format = Constants.DEFAULT_DATE_FORMAT,
  }: {
    date?: string | number | Date;
    format?: string;
  } = {}): boolean {
    return moment(date, format).isValid();
  }

  public static getTime({ date, format = Constants.DEFAULT_DATE_FORMAT }: { date?: string | number | Date; format?: string } = {}): Moment {
    if (!date) {
      return moment();
    }
    return moment(date, format);
  }

  public static getDate({ date, format = Constants.DEFAULT_DATE_FORMAT }: { date?: string | number | Date; format?: string } = {}): Moment {
    if (!date) {
      return moment();
    }

    return moment(date, format);
  }

  public static formatUTCDate({
    date,
    format = Constants.DEFAULT_DATE_FORMAT,
  }: {
    date?: string | number | Date;
    format?: string;
  } = {}): string {
    return moment.utc(date).format(format);
  }

  /**
   * formatDate formats the provided date into a formatted string
   * @param options an object containing the date and the format
   * @returns a formatted date according to the provided pattern
   */
  public static formatDate({
    date,
    format = Constants.DEFAULT_DATE_FORMAT,
  }: {
    date?: string | number | Date;
    format?: string;
  } = {}): string {
    return moment(date).format(format);
  }

  public static momentToUTCDateString({ date, format = Constants.DEFAULT_DATE_FORMAT }: { date: Moment; format?: string }) {
    const offsetInMinutes = moment().utcOffset();
    return date.clone().utc().add(offsetInMinutes, 'm').format(format);
  }

  public static formatUtcDateTimeToLocalString({ date, format }: { date: string; format: string }) {
    return moment(moment.utc(date).toDate()).format(format);
  }

  public static momentToDateString({ date, format = Constants.DEFAULT_DATE_FORMAT }: { date: Moment; format?: string }) {
    return date.clone().format(format);
  }

  public static offsetDates(dates: Date[] | Moment[]): Date[] {
    return dates.map((date: Date | Moment) => this.StripUTCOffset({ date }).toDate());
  }

  static isBeforeDate(referenceDate: string, dateToCheck: string): any {
    return moment(dateToCheck).isBefore(moment(referenceDate));
  }

  public static defaultWeekdays() {
    return [0, 1, 2, 3, 4, 5, 6].map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('ddd');
    });
  }

  /**
   * A method to strip the timezone UTC offset, which is mainly used to deal with the date pickers that select a date based on the local date instead of UTC.
   * When that day is selected it will be (depending on the offset) a different day in UTC, which is not something we want.
   * That's where this function helps, by removing the offset while retaining the same date.
   * The offset is calculated based on the provided input, which is important to take daylight savings time into account.
   * @param date is the date you would like to have the timezone stripped from, like 29-01-21 00:00 (GMT+2)
   *
   * @returns Date converted to one without an offset. For the example it would be 29-01-21 00:00 (with no timezone offset)
   */
  public static StripUTCOffset({
    date,
    format = Constants.DEFAULT_DATE_FORMAT,
  }: {
    date: Date | Moment | string;
    format?: string;
  }): Moment {
    const offsetInMinutes = moment(date).utcOffset();
    return offsetInMinutes > 0
      ? moment(date, format).clone().utc().add(offsetInMinutes, 'm')
      : moment(date, format).clone().utc().subtract(offsetInMinutes, 'm');
  }

  public static get UTCOffsetInMinutes(): number {
    return moment().utcOffset();
  }

  /**
   * Calculates the duration difference between two dates
   * @param value is the early date
   * @param target is the later, target date
   * @returns a string indicating the difference in hours and minutes
   */
  public static dateDifferenceInHours(value: Moment, target: Moment): string {
    // Get the difference duration
    const diff = moment.duration(
      DateTimeService.calculateDateDifference(
        DateTimeService.formatDate({
          date: value.toDate(),
          format: Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT,
        }),
        DateTimeService.formatDate({
          date: target.toDate(),
          format: Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT,
        }),
        'minutes',
      ),
      'minutes',
    );

    if (Math.floor(diff.asMinutes()) !== 0) {
      return DateTimeService.getDurationInHoursAndMinutes(diff);
    } else {
      return t('same_time');
    }
  }
}
