import axios from 'axios';
import type { AxiosProgressEvent, AxiosRequestConfig, CancelToken } from 'axios';

import { Environment } from '@/environment';
import { encodeQueryString } from '@/modules/api/api-utils';
import { EddyError, ErrorResponse } from '@/modules/shared/types/error-response';
import { AuthModule } from '@/store/modules/auth.module';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function mergeHeaders(original: Headers, extended: Headers) {
  const result = new Headers();

  for (const [originalKey, originalValue] of original.entries()) {
    result.append(originalKey, originalValue);

    for (const [key, value] of extended.entries()) {
      // Use set because the extended values should overwrite the original values
      result.set(key, value);
    }
  }

  return result;
}

export interface ApiError {
  response: {
    data?: {
      errors?: ErrorResponse[];
    };
    status: number;
  };
}

export interface EddyBaseError {
  response: {
    data: {
      errors: EddyError[];
    };
    status: number;
  };
}

class BaseClient {
  public baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    axios.interceptors.request.use(
      (request) => request,
      (error) => error,
    );
  }

  public getHeaders(multipart = false): Headers {
    let defaultHeaders: any = DEFAULT_HEADERS;

    if (multipart) {
      defaultHeaders = {};
    }

    if (AuthModule.token) {
      defaultHeaders = {
        Authorization: `Bearer ${AuthModule.token}`,
        ...defaultHeaders,
      };
    }

    return new Headers(defaultHeaders);
  }

  public async post<T>(uri: string, data: any, parameters: any = {}, cancelToken: CancelToken = null): Promise<T> {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }

    const config: AxiosRequestConfig = {
      headers: Object.fromEntries(this.getHeaders()),
      withCredentials: true,
      cancelToken,
      baseURL: this.baseUrl,
    };

    const res = await axios.post<T>(uri, data, config);
    return res.data;
  }

  public async patch<T>(uri: string, data: any): Promise<T> {
    const res = await axios.patch<T>(uri, data, {
      headers: Object.fromEntries(this.getHeaders()),
      withCredentials: true,
    });
    return res.data;
  }

  public async put<T>(uri: string, data: any, headers: Headers = new Headers()): Promise<T> {
    return axios
      .put<T>(uri, data, {
        headers: Object.fromEntries(mergeHeaders(this.getHeaders(), headers)),
        withCredentials: true,
      })
      .then((res) => res.data);
  }

  public delete<T>(uri: string, data?: any) {
    return axios.delete<T>(uri, {
      data,
      headers: Object.fromEntries(this.getHeaders()),
      withCredentials: true,
    });
  }

  public async get<T>(uri: string, data = {}): Promise<T> {
    if (Object.keys(data).length > 0) {
      uri = `${uri}?${encodeQueryString(data)}`;
    }

    const res = await axios.get<T>(uri, {
      headers: Object.fromEntries(this.getHeaders()),
      withCredentials: true,
    });
    return res.data;
  }

  public async postWithoutCredentials<T>(uri: string, data: any, parameters: any = {}): Promise<T> {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }

    const res = await axios.post<T>(uri, data, {
      withCredentials: false,
    });
    return res.data;
  }

  public async getToken<T>(uri: string, data: any, parameters: any = {}) {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }

    let params = '';

    for (const [index, key] of Object.keys(data).entries()) {
      params = index > 0 ? (params += '&') : '';
      params += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }

    const res = await axios.post<T>(uri, params, {
      baseURL: Environment.oauthUrl,
      headers: {
        Authorization: `Basic ${btoa(Environment.clientId + ':')}`,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return res.data;
  }

  public async getRaw<T>(uri: string, data = {}) {
    if (Object.keys(data).length > 0) {
      uri = `${uri}?${encodeQueryString(data)}`;
    }

    const res = await axios.get<T>(uri, {
      headers: Object.fromEntries(this.getHeaders()),
      withCredentials: true,
    });
    return res;
  }

  public upload(uri: string, data: any) {
    return fetch(uri, {
      headers: Object.fromEntries(this.getHeaders(true)),
      method: 'POST',
      body: data,
    });
  }

  public async uploadFiles<T>(uri: string, formData: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {
    const res = await axios.post<T>(uri, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${AuthModule.token}`,
      },
      onUploadProgress,
    });
    return res.data;
  }

  public async downloadFile<T>(
    uri: string,
    data: any,
    parameters: any = {},
    cancelToken: CancelToken = null,
  ): Promise<{
    filename: string;
    data: any;
  }> {
    if (Object.keys(parameters).length > 0) {
      uri = `${uri}?${encodeQueryString(parameters)}`;
    }

    const headers = this.getHeaders();
    headers.append('Access-Control-Expose-Headers', 'Content-Disposition');

    const response = await axios.post<T>(uri, data, {
      headers: Object.fromEntries(headers),
      withCredentials: true,
      cancelToken,
      responseType: 'blob',
    });

    // Extract filename from header
    const filename = response.headers['content-disposition']
      .split(';')
      .find((n: string) => n.includes('filename='))
      .replace('filename=', '')
      .replace(/"/g, '')
      .trim();

    return {
      filename,
      data: response.data,
    };
  }
}

const ApiClient = new BaseClient(Environment.apiUrl);
const IntegrationClient = new BaseClient(Environment.integrationUrl);

export { ApiClient, IntegrationClient };
