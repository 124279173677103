import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export type RemoveRivalRulePayload = BaseFlightActionPayload & { value: any };

export class RemoveRivalRuleAction implements FlightAction<RemoveRivalRulePayload> {
  public actionType: FlightActionType = FlightActionType.removeRivalRule;
  public label = t('rival_rules.remove_rival_rules');
  public value: any = undefined;

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: FlightActionType.removeRivalRule,
      meta: {
        label: this.label,
        flightActionType: FlightActionType.removeRivalRule,
        field: FilterFieldField.removeRivalRule,
        type: FilterFieldType.equal,
        valueKey: FilterFieldField.removeRivalRule,
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setPayload(payload: RemoveRivalRulePayload): void {
    // we don't need to set a value for this action
    this.value = undefined;
  }

  public getPayload(): RemoveRivalRulePayload {
    const flightAction: RemoveRivalRulePayload = {
      actionType: this.actionType,
      value: this.value,
    };

    return flightAction;
  }
}
