import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export type RemoveLinkedClassRulePayload = BaseFlightActionPayload & { value: any };

export class RemoveLinkedClassRuleAction implements FlightAction<RemoveLinkedClassRulePayload> {
  public actionType: FlightActionType = FlightActionType.removeLinkedClassRule;
  public label = t('linked_class_rules.remove_linked_class_rules');
  public value: any = undefined;

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: FlightActionType.removeLinkedClassRule,
      meta: {
        label: this.label,
        flightActionType: FlightActionType.removeLinkedClassRule,
        field: FilterFieldField.removeLinkedClassRule,
        type: FilterFieldType.equal,
        valueKey: FilterFieldField.removeLinkedClassRule,
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setPayload(payload: RemoveLinkedClassRulePayload): void {
    // we don't need to set a value for this action
    this.value = undefined;
  }

  public getPayload(): RemoveLinkedClassRulePayload {
    const flightAction: RemoveLinkedClassRulePayload = {
      actionType: this.actionType,
      value: this.value,
    };

    return flightAction;
  }
}
