import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { ColumnId } from '@/models/enums/grid';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
import { generateLfColorScheme } from '@/modules/user-settings/utils/colors.utils';
import { i18n } from '@/plugins/i18n';
import { FormatService } from '@/services/format.service';

import { LoadFactorType, NumberColumnFilterSettings } from './base';

const { t } = i18n.global;

export const LegSaleableCapacityColumn = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftSaleableCapacity,
  headerName: t('leg_saleable_capacity_short'),
  type: 'numericColumn',
  field: 'aircraftSaleableCapacity',
  minWidth: 35,
  width: 35,
  sortable: true,
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-sCap-key-cell-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => (params.data.legs?.length === 1 ? params.data.aircraftSaleableCapacity : null),
  headerTooltip: t('leg_saleable_capacity'),
  // allow gui to set aggregations for this column
  enableValue: true,
  // restrict aggregations to sum, min and max
  //   allowedAggFuncs: ['sum', 'min', 'max'],
};

export const LegAuthorizedCapacityColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftAuthorizedCapacity,
  headerName: t('authorized_capacity_short'),
  type: 'numericColumn',
  field: 'aircraftLid',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: t('authorized_capacity'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-aircraft-a-cap-${data.ondId}`,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => (params.data.legs?.length === 1 ? params.data.aircraftLid : null),
};

export const LegAuthorizedCapacityLoadFactorColumn = (lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftLidLoadFactor,
  headerName: t('authorized_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  field: 'aircraftLidLoadFactor',
  cellRenderer: 'GridLoadFactorRenderer',
  comparator: StringOrNumberComparator,
  minWidth: 40,
  width: 40,
  sortable: true,
  valueFormatter: (params: ValueFormatterParams) => FormatService.roundNumber(params.value, 1),
  valueGetter: (params: ValueGetterParams) =>
    params.data.legs?.length === 1 ? Number(FormatService.roundNumber(params.data.aircraftLidLoadFactor, 1)) : null,
  cellRendererParams: (params: ICellRendererParams) => {
    const rendererParams: { lf: string; lfColor?: string; type: LoadFactorType } = {
      lf: params.valueFormatted,
      type: 'leg',
    };

    if (lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const colorScheme = generateLfColorScheme(lafLoadFactorColoring).reverse();
      // for load factors above 100%,
      // match them to the highest color
      const matchingLf = colorScheme[Math.max(Math.min(100, Math.round(params.value)) - 1, 0)];

      if (matchingLf) {
        rendererParams.lfColor = matchingLf;
      }
    }

    return rendererParams;
  },
  headerTooltip: t('authorized_capacity_load_factor'),
});

export const LegSaleableCapacityLoadFactorColumn = (lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: ColumnId.LegAircraftCapacityLoadfactor,
  headerName: t('saleable_capacity_load_factor_short'),
  field: 'aircraftCapacityLoadFactor',
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  comparator: StringOrNumberComparator,
  width: 40,
  minWidth: 40,
  sortable: true,
  valueFormatter: (params: ValueFormatterParams) => FormatService.roundNumber(params.value, 1),
  headerTooltip: t('saleable_capacity_load_factor'),
  cellRenderer: 'GridLoadFactorRenderer',
  valueGetter: (params: ValueGetterParams) =>
    params.data.legs?.length === 1 ? Number(FormatService.roundNumber(params.data.aircraftCapacityLoadFactor, 1)) : null,
  cellRendererParams: (params: ICellRendererParams) => {
    const rendererParams: { lf: string; lfColor?: string; type: LoadFactorType } = {
      lf: params.valueFormatted as string,
      type: 'leg',
    };

    if (lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const colorScheme = generateLfColorScheme(lafLoadFactorColoring).reverse();
      // for load factors above 100%,
      // match them to the highest color
      const matchingLf = colorScheme[Math.max(Math.min(100, Math.round(params.value)) - 1, 0)];

      if (matchingLf) {
        rendererParams.lfColor = matchingLf;
      }
    }

    return rendererParams;
  },
});
