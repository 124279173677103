import { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/modules/shared/types/routes';

export const USER_SETTINGS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/user',
    name: RouteName.user,
    component: () => import('@/modules/user-settings/views/UserSettingsView.vue'),
  },
  {
    path: `settings/${RouteName.userChangePassword}`,
    name: RouteName.userChangePassword,
    component: () => import('@/modules/user-settings/views/UserChangePasswordView.vue'),
  },
];
