export const en = {
  flight_actions_module: {
    changes: 'no changes | one change | {count} changes',
    labels: {
      offset: 'Offset:',
      seats_available: 'Seats available:',
      distribution: 'Distribution:',
      apply_raus: 'Apply rAUs',
    },
    values: {
      maintainPr: 'Maintain protections',
      noMaintainPr: "Don't maintain protections",
      evenly: 'Evenly',
    },
    remove_all_tags: 'Remove All Tags',
  },
};
