import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions-contracts';

const { t } = i18n.global;

export interface SwapOptimizationTacticPayload extends BaseFlightActionPayload {
  cabinCode: string;
  applyRecommendedAU?: boolean;
}

export interface SwapOptimizationTacticFieldPayload {
  applyRecommendedAUs: boolean;
}

export class SwapOptimizationTacticAction implements FlightAction<SwapOptimizationTacticPayload> {
  public actionType: FlightActionType = FlightActionType.swapOptimisationTactic;
  public label = t('dynamic_filters.swap_optimisation_tactics');
  public cabinCode?: string;
  public applyRecommendedAUs: boolean;

  constructor(cabinCode?: string, applyRecommendedAUs = true) {
    this.cabinCode = cabinCode;
    this.applyRecommendedAUs = applyRecommendedAUs;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.swapOptimisationTactic,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.swapOptimisationTactic,
        field: FilterFieldField.swapOptimisationTactic,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.swapOptimisationTactic,
        uniqueField: this.cabinCode,
        componentName: ComponentName.SwapOptimisationProfileField,
        // hasNoEditor: true,

        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/SwapOptimizationTacticActionValueRenderer.vue'),
        ),
        value: { applyRecommendedAUs: this.applyRecommendedAUs } as SwapOptimizationTacticFieldPayload,
        isValueValid: () => true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    const payload = action.value as SwapOptimizationTacticFieldPayload;
    this.actionType = FlightActionType.swapOptimisationTactic;

    this.applyRecommendedAUs = payload.applyRecommendedAUs;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: SwapOptimizationTacticPayload): void {
    this.actionType = FlightActionType.swapOptimisationTactic;
    this.applyRecommendedAUs = payload.applyRecommendedAU;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): SwapOptimizationTacticPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      applyRecommendedAU: this.applyRecommendedAUs,
    };
  }
}
