export const en = {
  cluster_management: {
    titles: {
      cluster_management: 'Cluster Management',
    },
    headings: {
      create_cluster: 'Create Cluster',
      edit_cluster: 'Edit Cluster',
      no_clusters: `You haven't created any clusters yet`,
    },
    states: {
      no_clusters: 'Create clusters to standardize the name convention of clustering in the system.',
      loading_clusters: 'Loading Clusters',
    },
    actions: {
      add_cluster: 'Add Cluster',
      create_cluster: 'Create Cluster',
    },

    clusters_empty_state_title: "You haven't made any clusters yet",
    clusters_empty_state_description: 'Create clusters to standardize the name convention of clustering in the system.',
  },
};
