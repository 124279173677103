import { AppSettingsModule } from '@/store/modules/app-settings.module';

export class ConversionService {
  // Converts ranges (like flight number ranges: '100, 200-250'), to numeric arrays.
  public static getNumberRangesFromString(numberRangeString: string): number[][] {
    if (!numberRangeString) {
      return [];
    }
    let trimmedNumberRangeString = numberRangeString.toString().trim();
    if (trimmedNumberRangeString.endsWith(',')) {
      trimmedNumberRangeString = trimmedNumberRangeString.substring(0, trimmedNumberRangeString.length - 1);
    }

    if (trimmedNumberRangeString.length === 0) {
      return [];
    }

    const numberRangeStrings = trimmedNumberRangeString.split(',').map((toTrim) => toTrim.trim());

    const numberRanges: any[] = [];

    // Split by string, loop through the numbers or ranges
    for (const numberRange of numberRangeStrings) {
      // If it's a range
      if (numberRange.includes('-') && !numberRange.endsWith('-')) {
        const splitRange = numberRange.split('-');
        if (splitRange.length === 2 && Number(splitRange[0]) < Number(splitRange[1])) {
          numberRanges.push([Number(splitRange[0]), Number(splitRange[1])]);
        } else {
          throw new Error('InvalidNumberRangeException');
        }
      } else {
        // If it's a single number
        if (Number(numberRange)) {
          numberRanges.push([Number(numberRange)]);
        } else {
          throw new Error('InvalidNumberRangeException');
        }
      }
    }

    return numberRanges;
  }

  public static removeNonNumericCharsFromInput(input: any) {
    let charArray = input.toString().trim().split('');

    charArray = charArray.filter((char: any) => !isNaN(char));

    if (charArray.length > 1 && charArray[0] === '0') {
      charArray.shift();
    }

    if (charArray.length > 0) {
      return parseInt(charArray.join(''), 10);
    }

    // if user types and then deletes everything
    if (charArray.length === 0) {
      return null;
    }
  }

  public static cabinCodeToCabinName(cabinCode: string) {
    const cabinConfig = AppSettingsModule.inventoryConfigurationProperties.cabins.find((cabin) => cabin.code === cabinCode);
    if (cabinConfig) {
      return cabinConfig.name;
    }
  }
}
