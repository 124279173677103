import { ColDef, ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import { filterColumnsOnPermission } from '@/models/columns/utils/col-def-with-permission';
import { CabinStructure, PssName } from '@/modules/api/application/application-contracts';
import { useCustomerDefinedDataStore } from '@/modules/customer-defined-data/store/customer-defined-data.store';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { FeaturesModel } from '@/modules/features';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
// eslint-disable-next-line import/order -- changing the order breaks import of NumberColumnFilterSettings in generateAncillaryFlightColumns 🤷🏻‍♂️
import { generateCustomerDefinedDataColumnGroup } from '@/models/columns/definitions/customer-defined-data';

import { generateDefaultColumnState1a, generateFlightLineColumns1a } from './1a';
import { generateDefaultColumnState1d, generateFlightLineColumns1d } from './1d';
import { generateDefaultColumnState1n, generateFlightLineColumns1n } from './1n';
import { generateDefaultColumnState1s, generateFlightLineColumns1s } from './1s';
import { generateDefaultColumnState1v, generateFlightLineColumns1v } from './1v';
import { generateDefaultColumnState1x, generateFlightLineColumns1x } from './1x';
import { generateDefaultColumnState1y, generateFlightLineColumns1y } from './1y';

export interface FlightLineColumnsGeneratorValuesInterface {
  cabins: CabinStructure[];
  bookingsPickupDays: number[];
  performanceBandPickupDays: number[];
  competitorCarrierCodes: string[];
  lafLoadFactorColoring: LafLoadFactorColoring;
  features: FeaturesModel;
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>;
}

export const generateFlightLineColumns = (
  pss: PssName,
  generatorValues: FlightLineColumnsGeneratorValuesInterface,
): Array<ColDef | ColGroupDef> => {
  const customerDefinedDataStore = useCustomerDefinedDataStore();

  const allFlightLineColumns = (): Array<ColDef | ColGroupDef> => {
    switch (pss) {
      case PssName.Radixx:
        return generateFlightLineColumns1d(generatorValues);
      case PssName.Sabre:
        return generateFlightLineColumns1s(generatorValues);
      case PssName.Amadeus:
        /**
         * Amadeus doesn't use baseCurrency
         */
        return generateFlightLineColumns1a(generatorValues);
      case PssName.Navitaire:
        return generateFlightLineColumns1n(generatorValues);
      case PssName.Genius_TurSys:
        return generateFlightLineColumns1x(generatorValues);
      case PssName.Pribas:
        return generateFlightLineColumns1y(generatorValues);
      case PssName.Videcom:
        return generateFlightLineColumns1v(generatorValues);
      default:
        return [];
    }
  };
  // Extend with Customer Defined Data dynamic columns. The schema needs to be loaded first.
  const customerDefinedDataColumns = generateCustomerDefinedDataColumnGroup(
    customerDefinedDataStore.schema,
    generatorValues.cabins?.map(({ code }) => code) ?? [],
  );
  const columns = [...allFlightLineColumns(), ...customerDefinedDataColumns];
  return filterColumnsOnPermission(columns, generatorValues.customerSettings, generatorValues.features);
};

export const generateFlightLineColumnDefaultState = (
  pss: PssName,
  generatorValues: FlightLineColumnsGeneratorValuesInterface,
): ColumnState[] => {
  switch (pss) {
    case PssName.Radixx:
      return generateDefaultColumnState1d(generatorValues);
    case PssName.Sabre:
      return generateDefaultColumnState1s(generatorValues);
    case PssName.Amadeus:
      /**
       * Amadeus doesn't use baseCurrency
      // */
      return generateDefaultColumnState1a(generatorValues);
    case PssName.Navitaire:
      return generateDefaultColumnState1n(generatorValues);
    case PssName.Genius_TurSys:
      return generateDefaultColumnState1x(generatorValues);
    case PssName.Pribas:
      return generateDefaultColumnState1y(generatorValues);
    case PssName.Videcom:
      return generateDefaultColumnState1v(generatorValues);
    default:
      return [];
  }
};
