import { ScheduleMode } from '@/modules/shared/shared-contracts';

export interface ApiPssCapabilities {
  hasNegotiatedAllocation: boolean;
}

export enum FareSource {
  CompetitiveFares = 'COMPETITIVE_FARES',
  PublishedFares = 'PUBLISHED_FARES',
}

export enum RealtimeBookingSource {
  NONE = 'NONE',
  STREAMED = 'STREAMED',
  SCHEDULED = 'SCHEDULED',
}

export interface MultiFactorAuthentication {
  hasMultiFactorAuthenticationEnabled: boolean;
}

export interface CustomerSettingsModel<T> {
  airlineHemisphere: string;
  competitiveFareMaxDataAgeDays: number;
  dataScienceCaptureDate: string;
  hasAutoCorrectEnabled: boolean;
  hasBringYourOwnRecommendationsEnabled: boolean;
  hasConstructBringYourOwnRecommendationsOpLevelsEnabled: boolean;
  numberOfBringYourOwnRecommendationsOpLevels: number;
  hasShadowTacticsEnabled: boolean;
  hasContinuousPricingEnabled: boolean;
  hasDistanceInMiles: boolean;
  hasFareConversionEnabled: boolean;
  hasAutomationsEnabled: boolean;
  hasLinkedClassRulesEnabled: boolean;
  hasRivalRulesEnabled: boolean;
  hasAirAsiaDataScienceIntegrationEnabled: boolean;
  hasMultiFactorAuthenticationEnabled: boolean;
  hasOverbookingEnabled: boolean;
  hasPromotionsEnabled: boolean;
  hasRealtimeTriggerEnabled?: boolean;
  hasCompetitiveFaresInboundLogicEnabled?: boolean;
  /**
   * @deprecated use realtimeBookingSource instead.
   */
  hasRealTimeOnDemandEnabled: boolean;
  /**
   * Enables the ability to view customer defined data.
   */
  hasCustomerDefinedDataEnabled: boolean;
  hasScheduledTriggerEnabled?: boolean;
  haveRoutineBasedAutomationsEnabled?: boolean;
  maxPrice: number;
  ownFareSource: FareSource;
  /**
   * This property is only available for certain PSS's.
   */
  priceAdjustmentRange?: number[];
  /**
   * This property is only available for certain PSS's.
   */
  priceIncrementRange?: number[];
  pricingMultiplier: number;
  pssCapabilities: T;
  scheduleMode: ScheduleMode | '';
  lastRealtimeUpdate: string;
  cfCaptureDate: string;
  captureDate: string;
  realtimeBookingSource: RealtimeBookingSource;
  realtimeBookingsNumberOfFailedFiles: number;
  opServiceLastRunTimestamp: string;
  hasOverbookingRecommendationsEnabled: boolean;
  defaultOverbookingRiskPercentage: number;
  includeAncillaryInTotalRevenue?: boolean;
  includeSurchargeInTotalRevenue?: boolean;
  includeTaxInTotalRevenue?: boolean;
  hasForecastingEnabled: boolean;
  hasForecastingAndDynamicProgramEnabled: boolean;
  numberOfForecastingAndDynamicProgramLevels: number;
  hasInsightsEnabled: boolean;
  configuredInsights: string[];
  maxRoutineBasedAutomations: number;
  hasNegoAllocationEnabled: boolean;
  hasBookingDetailsEnabled: boolean;
}

export interface ICustomerSettingsService {
  get(): Promise<CustomerSettingsModel<ApiPssCapabilities>>;
  getMultiFactorAuthentication(): Promise<MultiFactorAuthentication>;
}
