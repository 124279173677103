import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions-contracts';

const { t } = i18n.global;

export interface MoveRecommendedOverbookingRiskPercentagePayload extends BaseFlightActionPayload {
  cabinCode: string;
  offset: number;
  applyRecommendedOverbookingFactor: boolean;
  applyRecommendedAU: boolean;
}

export interface MoveRecommendedOverbookingRiskPercentageFieldPayload {
  levelMutation: number;
  applyRecommendedAU: boolean;
  applyRecommendedOverbookingFactor: boolean;
}

export class MoveRecommendedOverbookingRiskPercentageAction implements FlightAction<MoveRecommendedOverbookingRiskPercentagePayload> {
  public actionType: FlightActionType = FlightActionType.moveRecommendedOverbookingRiskPercentage;
  public value = 1;
  public cabinCode: string;
  public applyRecommendedOverbookingFactor = true;
  public applyRecommendedAU = true;
  public fieldType: FilterFieldField;

  constructor(levelMutation = 1, cabinCode?: string) {
    this.setMutation(levelMutation);
    this.cabinCode = cabinCode ?? 'Y';
  }

  public get label(): string {
    return this.fieldType === FilterFieldField.moveRecommendedOverbookingRiskPercentageUp
      ? t('control.bulk_actions.move_recommended_overbooking_risk_percentage_up')
      : t('control.bulk_actions.move_recommended_overbooking_risk_percentage_down');
  }

  public get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + this.fieldType,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.moveRecommendedOverbookingRiskPercentage,
        field: this.fieldType,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + this.fieldType,
        uniqueField: this.cabinCode,
        componentName: ComponentName.MoveRecommendedOverbookingRiskPercentageFilterField,
        value: {
          levelMutation: this.value,
          applyRecommendedOverbookingFactor: this.applyRecommendedOverbookingFactor,
          applyRecommendedAU: this.applyRecommendedAU,
        } as MoveRecommendedOverbookingRiskPercentageFieldPayload,
        warning: t('bulk_overbooking_change'),
        isValueValid: (value: MoveRecommendedOverbookingRiskPercentageFieldPayload) => isFinite(value.levelMutation),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    const payload = action.value as MoveRecommendedOverbookingRiskPercentageFieldPayload;
    this.fieldType = action.field as FilterFieldField;
    this.value = payload.levelMutation;
    this.cabinCode = action.uniqueField as string;
    this.applyRecommendedOverbookingFactor = payload.applyRecommendedOverbookingFactor;
    this.applyRecommendedAU = payload.applyRecommendedAU;
  }

  public setPayload(payload: MoveRecommendedOverbookingRiskPercentagePayload): void {
    this.setMutation(payload.offset);
    this.cabinCode = payload.cabinCode;
    this.applyRecommendedOverbookingFactor = payload.applyRecommendedOverbookingFactor;
    this.applyRecommendedAU = payload.applyRecommendedAU;
  }

  public getPayload(): MoveRecommendedOverbookingRiskPercentagePayload {
    const offset = this.fieldType === FilterFieldField.moveRecommendedOverbookingRiskPercentageDown ? this.value * -1 : this.value;

    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      applyRecommendedOverbookingFactor: this.applyRecommendedOverbookingFactor,
      applyRecommendedAU: this.applyRecommendedAU,
      offset,
    };
  }

  /**
   * setMutation sets the correct state for the action, as a negative value is displayed to the user as a 'down' option, whilst positive is the 'up' option.
   * This must be reflected in the UI. The offset will always be displayed as a positive number.
   * @param levelMutation the relative amount of levels the optimization profile will move, can be negative
   */
  private setMutation(levelMutation: number): void {
    this.fieldType =
      levelMutation < 0
        ? FilterFieldField.moveRecommendedOverbookingRiskPercentageDown
        : FilterFieldField.moveRecommendedOverbookingRiskPercentageUp;
    this.value = Math.abs(levelMutation);
  }
}
