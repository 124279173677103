import { ColDef } from 'ag-grid-enterprise';

import { FlightViewFlightLineRow } from '@/models/FlightModel';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import {
  ancillaryRevenueCabinColumn,
  ancillaryRevenueClassColumn,
  ancillaryRevenueFlightColumn,
  averageAncillaryRevenuePerPaxCabinColumn,
  averageAncillaryRevenuePerPaxClassColumn,
  averageAncillaryRevenuePerPaxFlightColumn,
  surchargeRevenueCabinColumn,
  surchargeRevenueClassColumn,
  surchargeRevenueFlightColumn,
  taxRevenueCabinColumn,
  taxRevenueClassColumn,
  taxRevenueFlightColumn,
  totalRevenueCabinColumn,
  totalRevenueClassColumn,
  totalRevenueFlightColumn,
} from '@/modules/revenues/columns';

export function generateAncillaryFlightColumns(): ColDef<FlightLineModel>[] {
  return [
    totalRevenueFlightColumn(),
    ancillaryRevenueFlightColumn(),
    surchargeRevenueFlightColumn(),
    taxRevenueFlightColumn(),
    averageAncillaryRevenuePerPaxFlightColumn(),
  ];
}

export function generateAncillaryCabinColumns(cabinCode: CabinCode): ColDef<FlightLineModel>[] {
  return [
    totalRevenueCabinColumn(cabinCode),
    ancillaryRevenueCabinColumn(cabinCode),
    surchargeRevenueCabinColumn(cabinCode),
    taxRevenueCabinColumn(cabinCode),
    averageAncillaryRevenuePerPaxCabinColumn(cabinCode),
  ];
}

export function generateAncillaryClassColumns(): ColDef<FlightViewFlightLineRow>[] {
  return [
    totalRevenueClassColumn,
    ancillaryRevenueClassColumn,
    surchargeRevenueClassColumn,
    taxRevenueClassColumn,
    averageAncillaryRevenuePerPaxClassColumn,
  ];
}
