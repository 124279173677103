import { isEmpty, isString } from 'lodash-es';
import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isArrayValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { ClearableClasses, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { ITag } from '@/modules/tags';
import { useTagsStore } from '@/modules/tags/store/tags.store';
import { matchWithExistingTags } from '@/modules/tags/utils/tags.util';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface AddTagsPayload extends BaseFlightActionPayload {
  tags: { name: string }[];
}

export const TAG_MAX_LENGTH = 40;

export class AddTagsAction implements FlightAction<AddTagsPayload> {
  public actionType: FlightActionType = FlightActionType.tagsAdd;
  public value: { name: string }[];
  public label = t('add_tags');

  constructor(value?: { name: string }[]) {
    this.value = value;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: FilterFieldField.tagsAdd,
      meta: {
        label: this.label,
        flightActionType: this.actionType,
        uniqueField: FilterFieldField.tagsAdd,
        field: FilterFieldField.tagsAdd,
        type: FilterFieldType.in,
        value: isEmpty(this.value) ? [] : this.value,
        valueKey: FilterFieldField.tagsAdd,
        componentName: ComponentName.TagsFilterField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/DisplayTagsActionValueRenderer.vue'),
        ),
        clearableClasses: ClearableClasses.none,
        isValueValid: (value: (ITag | string)[]) =>
          isArrayValueNotEmpty(value) && !value.some((value) => typeof value === 'string' && value.length > TAG_MAX_LENGTH),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
  }

  public setPayload(payload: AddTagsPayload): void {
    const tagsStore = useTagsStore();
    this.value = matchWithExistingTags(tagsStore.tags, payload.tags);
  }

  public getPayload(): AddTagsPayload {
    const addedTags = this.value.map((tag: any) => {
      // In case of a new tag, a string is passed instead of an object
      if (isString(tag)) {
        return {
          name: tag,
        };
      }
      // If it is an existing tag, only set the name (no id, this will make the API fail)
      return {
        name: tag.name,
      };
    });

    const flightAction: AddTagsPayload = {
      actionType: this.actionType,
      tags: addedTags,
    };

    return flightAction;
  }
}
