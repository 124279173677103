import { Library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBolt,
  faBookmark,
  faBroom,
  faCalendarAlt,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCog,
  faCogs,
  faCompressAlt,
  faEllipsisH,
  faEllipsisV,
  faExclamation,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFilter,
  faHashtag,
  faHistory,
  faIndustry,
  faInfoCircle,
  faLink,
  faMinus,
  faPaperPlane,
  faPaste,
  faPen,
  faPlane,
  faPlaneDeparture,
  faPlus,
  faPuzzlePiece,
  faQuestionCircle,
  faRedoAlt,
  faSave,
  faSearch,
  faSort,
  faStopwatch,
  faTags,
  faThumbsUp,
  faThumbtack,
  faTicketsAirline,
  faTimes,
  faToggleOn,
  faTrash,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';

export function addProSolidIcons(library: Library): void {
  library.add(
    faArchive,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBell,
    faBolt,
    faBookmark,
    faBroom,
    faCalendarAlt,
    faChartLine,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClock,
    faClock,
    faCog,
    faCogs,
    faCompressAlt,
    faEllipsisH,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExpandAlt,
    faEye,
    faEyeSlash,
    faFilter,
    faHashtag,
    faHistory,
    faIndustry,
    faInfoCircle,
    faLink,
    faMinus,
    faPaperPlane,
    faPaste,
    faPen,
    faPlane,
    faPlaneDeparture,
    faPlus,
    faPuzzlePiece,
    faQuestionCircle,
    faRedoAlt,
    faSave,
    faSearch,
    faSort,
    faStopwatch,
    faTags,
    faThumbsUp,
    faThumbtack,
    faTicketsAirline,
    faTimes,
    faToggleOn,
    faTrash,
    faUser,
  );
}
