import { isEmpty } from 'lodash-es';

/**
 * These are the environment variables that are available to the application and are
 * determined by the deployment process through the environment variables that are set in the release configuration.
 * The {@link server/main/kotlin/com/kambr/web/EnvController.kt} is responsible for exposing these values to eddy-web.
 */
export type EddyEnvironment = 'unknown_environment' | 'dev' | 'qa' | 'load' | 'uat' | 'demo' | 'prod';

declare global {
  interface Window {
    __env: {
      API_URL: string;
      BRANCH_NAME: string;
      BUILD_ID: string;
      ENVIRONMENT: EddyEnvironment;
      GOOGLE_IDENTITY_TENANT_ID: string;
      INTEGRATION_URL: string;
      OAUTH_URL: string;
      RELEASE_VERSION: string;
      VERSION: string;
    };
    playwright: boolean;
  }
}

export type IEnvironment = {
  apiUrl: string;
  branchName: string;
  buildId: string;
  clientId: string;
  environment: EddyEnvironment;
  functionUrls: {
    openInGoogleFlights: string;
  };
  googleTenantId: string;
  integrationUrl: string;
  isDevelopment: boolean;
  isE2E: boolean;
  isProduction: boolean;
  isRelease: boolean;
  logging: 'datadog' | 'console' | 'noop';
  oauthUrl: string;
  releaseVersion: string;
  version: string;
};

export const Environment: IEnvironment = {
  apiUrl: window.__env.API_URL,
  branchName: window.__env.BRANCH_NAME,
  buildId: window.__env.BUILD_ID,
  clientId: 'eddy',
  environment: window.__env.ENVIRONMENT,
  functionUrls: {
    openInGoogleFlights: 'https://us-central1-kambrinc.cloudfunctions.net/open-in-google-flights',
  },
  googleTenantId: window.__env.GOOGLE_IDENTITY_TENANT_ID,
  integrationUrl: window.__env.INTEGRATION_URL,
  isDevelopment: process.env.NODE_ENV === 'development',
  isE2E: JSON.parse(localStorage.getItem('isE2E') ?? 'false'),
  isProduction: process.env.NODE_ENV === 'production',
  isRelease: !isEmpty(window.__env.RELEASE_VERSION) && window.__env.RELEASE_VERSION !== 'unknown_release',
  logging: process.env.NODE_ENV === 'production' ? 'datadog' : import.meta.env.VITEST_MODE ? 'noop' : 'console',
  oauthUrl: window.__env.OAUTH_URL,
  releaseVersion: window.__env.RELEASE_VERSION,
  version: window.__env.VERSION,
};
