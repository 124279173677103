export enum ExportSeparator {
  Comma = ',',
  SemiColon = ';',
  Pipe = '|',
}

export interface ILafColorSchemeModel {
  color: string;
  class: string;
}
