import { ColDef, ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import { filterColumnsOnPermission } from '@/models/columns/utils/col-def-with-permission';
import { FlightViewFlightLineRow } from '@/models/FlightModel';
import { PssName } from '@/modules/api/application/application-contracts';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { FeaturesModel } from '@/modules/features';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';

import { InventoryGridColumnDefinitions1a, generateInventoryGridDefaultColumnState1a } from './1a';
import { InventoryGridColumnDefinitions1d, generateInventoryGridDefaultColumnState1d } from './1d';
import { InventoryGridColumnDefinitions1n, generateInventoryGridDefaultColumnState1n } from './1n';
import { InventoryGridColumnDefinitions1s, generateInventoryGridDefaultColumnState1s } from './1s';
import { InventoryGridColumnDefinitions1v, generateInventoryGridDefaultColumnState1v } from './1v';
import { InventoryGridColumnDefinitions1x, generateInventoryGridDefaultColumnState1x } from './1x';
import { InventoryGridColumnDefinitions1y, generateInventoryGridDefaultColumnState1y } from './1y';

const inventoryGridColumnDefinitionsMap = new Map<
  PssName,
  (
    bookingsPickupDays: number[],
    customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
  ) => Array<ColDef<FlightViewFlightLineRow> | ColGroupDef<FlightViewFlightLineRow>>
>([
  [PssName.Amadeus, InventoryGridColumnDefinitions1a],
  [PssName.Radixx, InventoryGridColumnDefinitions1d],
  [PssName.Sabre, InventoryGridColumnDefinitions1s],
  [PssName.Navitaire, InventoryGridColumnDefinitions1n],
  [PssName.Genius_TurSys, InventoryGridColumnDefinitions1x],
  [PssName.Pribas, InventoryGridColumnDefinitions1y],
  [PssName.Videcom, InventoryGridColumnDefinitions1v],
]);

const inventoryGridColumnDefaultStateMap = new Map<
  PssName,
  (bookingsPickupDays: number[], customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>) => ColumnState[]
>([
  [PssName.Amadeus, generateInventoryGridDefaultColumnState1a],
  [PssName.Radixx, generateInventoryGridDefaultColumnState1d],
  [PssName.Sabre, generateInventoryGridDefaultColumnState1s],
  [PssName.Navitaire, generateInventoryGridDefaultColumnState1n],
  [PssName.Genius_TurSys, generateInventoryGridDefaultColumnState1x],
  [PssName.Pribas, generateInventoryGridDefaultColumnState1y],
  [PssName.Videcom, generateInventoryGridDefaultColumnState1v],
]);

export const generateInventoryGridColumnDefinitions = (
  pss: PssName,
  bookingsPickupDays: number[],
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
  features: FeaturesModel,
): Array<ColDef<FlightViewFlightLineRow> | ColGroupDef<FlightViewFlightLineRow>> =>
  filterColumnsOnPermission(
    inventoryGridColumnDefinitionsMap.get(pss)?.(bookingsPickupDays, customerSettings) ?? [],
    customerSettings,
    features,
  );

export const generateInventoryColumnDefaultState = (
  pss: PssName,
  bookingsPickupDays: number[],
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
): ColumnState[] => inventoryGridColumnDefaultStateMap.get(pss)?.(bookingsPickupDays, customerSettings) ?? [];
