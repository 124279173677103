import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { CalendarEvent, CalendarEventType } from '@/modules/route-management/api/calendar.contracts';
import { calendarService } from '@/modules/route-management/api/calendar.service';

export const useCalendarStore = defineStore('calendar', () => {
  const seasons: Ref<CalendarEvent[]> = ref([]);

  async function get(captureDate: string): Promise<void> {
    seasons.value = await calendarService.getBy(CalendarEventType.Season, captureDate);
  }

  function $reset(): void {
    seasons.value = [];
  }

  return {
    seasons,
    get,
    $reset,
  };
});
