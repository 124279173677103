import { ValueGetterFunc } from 'ag-grid-community/dist/lib/entities/colDef';
import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';

import { NumberColumnFilterSettings, TextColumnFilterSettings } from '@/models/columns/definitions/base';
import { ColumnGroupId, generateCustomerDefinedDataColumndId } from '@/models/enums/grid';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import {
  CustomerDefinedDataSchemaResponse,
  CustomerDefinedDataType,
} from '@/modules/customer-defined-data/services/customer-defined-data.service.model';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

type FlightCustomerDefinedDataValueGetter = (key: string) => ValueGetterFunc<FlightLineModel>;
type CabinCustomerDefinedDataValueGetter = (key: string, cabinCode: CabinCode) => ValueGetterFunc<FlightLineModel>;

const flightValueGetter =
  (key: string): ValueGetterFunc<FlightLineModel> =>
  (params: ValueGetterParams<FlightLineModel>): CustomerDefinedDataType | undefined =>
    params.data?.customerDefinedData?.data?.find((data) => data.key === key)?.value;

const cabinValueGetter =
  (key: string, cabinCode: CabinCode): ValueGetterFunc<FlightLineModel> =>
  (params: ValueGetterParams<FlightLineModel>): CustomerDefinedDataType | undefined => {
    const customerDefinedCabinData = params.data?.customerDefinedData?.[`cabin_${cabinCode}`]?.data;
    return customerDefinedCabinData?.find((data) => data.key === key)?.value;
  };

function generateCustomerDefinedDataCols(
  fields: CustomerDefinedDataSchemaResponse['flightFields'] | CustomerDefinedDataSchemaResponse['cabinFields'] = {},
  valueGetter: FlightCustomerDefinedDataValueGetter | CabinCustomerDefinedDataValueGetter,
  cabin?: CabinCode,
): ColDef<FlightLineModel>[] {
  return Object.entries(fields).map(([key, schemeItem]) => {
    const filterType = schemeItem?.type === 'number' ? NumberColumnFilterSettings : TextColumnFilterSettings;
    return {
      ...filterType,
      headerName: `${schemeItem?.title}` ?? key,
      headerTooltip: `${schemeItem?.description}` ?? key,
      minWidth: 50,
      width: 50,
      sortable: true,
      hide: false,
      colId: generateCustomerDefinedDataColumndId(key),
      valueGetter: cabin ? valueGetter(key, cabin) : (valueGetter as FlightCustomerDefinedDataValueGetter)(key),
    };
  });
}

export function generateCustomerDefinedDataColumnGroup(schema: CustomerDefinedDataSchemaResponse, cabins?: CabinCode[]): ColGroupDef[] {
  let flightColumnGroup: ColGroupDef[] = [];
  let cabinColumnGroup: ColGroupDef[] = [];

  if (isEmpty(schema)) return [];

  if (!isEmpty(schema.flightFields)) {
    flightColumnGroup = [
      {
        headerName: t('flight_customer_defined_data_short'),
        headerTooltip: t('flight_customer_defined_data'),
        groupId: ColumnGroupId.CustomerDefinedData,
        children: generateCustomerDefinedDataCols(schema.flightFields, flightValueGetter),
      },
    ];
  }
  if (!isEmpty(schema.cabinFields)) {
    cabinColumnGroup =
      cabins?.map((cabin) => ({
        headerName: t('cabin_customer_defined_data_short', { cabin }),
        headerTooltip: t('cabin_customer_defined_data', { cabin }),
        groupId: `${ColumnGroupId.CustomerDefinedData}_${cabin}`,
        children: generateCustomerDefinedDataCols(schema.cabinFields, cabinValueGetter, cabin),
      })) ?? [];
  }
  return [...flightColumnGroup, ...cabinColumnGroup];
}
