import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { getCabinCodeForClass } from '@/store/modules/app-settings.module';

import { LinkedClassRuleDetailDto, LinkedClassRuleDto, LinkedClassRuleUnit } from '../api/linked-class-rules.contracts';

/**
 * The LinkedClassRuleModel represents a Linked Class Rule, which contains a group of rules that link classes to eachother.
 */
export class LinkedClassRuleModel {
  id: number;
  name: string;
  description: string;
  createdBy: UserModel;
  modifiedBy: UserModel;
  createdDateTime: string;
  modifiedDateTime?: string;
  /** Determines whether the rule is soft-deleted. */
  isArchived: boolean;
  /** The details are the actual Linked Class Rules, that link classes.  */
  details: Array<LinkedClassRuleDetailModel>;

  constructor({
    id,
    name,
    description,
    createdBy,
    modifiedBy,
    createdDateTime,
    modifiedDateTime,
    isArchived,
    details,
  }: {
    id: number;
    name: string;
    description: string;
    createdBy: UserModel;
    modifiedBy: UserModel;
    createdDateTime: string;
    modifiedDateTime: string;
    isArchived: boolean;
    details: Array<LinkedClassRuleDetailModel>;
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
    this.createdDateTime = createdDateTime;
    this.modifiedDateTime = modifiedDateTime;
    this.isArchived = isArchived;
    this.details = details;
  }

  public static fromDto(dto: LinkedClassRuleDto) {
    return new LinkedClassRuleModel({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      createdBy: dto.createdBy,
      modifiedBy: dto.modifiedBy,
      createdDateTime: dto.createdDateTime,
      modifiedDateTime: dto.modifiedDateTime,
      isArchived: dto.isArchived,
      details: LinkedClassRuleDetailModel.fromDtoList(dto.details),
    });
  }

  public static fromDtoList(dtos: LinkedClassRuleDto[]): LinkedClassRuleModel[] {
    return dtos.map((dto) => LinkedClassRuleModel.fromDto(dto));
  }

  /** Instantiates a new, empty model */
  public static new(): LinkedClassRuleModel {
    return new LinkedClassRuleModel({
      createdBy: undefined,
      createdDateTime: undefined,
      description: '',
      id: undefined,
      isArchived: false,
      modifiedBy: undefined,
      modifiedDateTime: undefined,
      name: 'Linked Class Rule',
      details: [],
    });
  }

  /**
   * Recalculates the Rank property of all the rules. Iterates the array and sets the first rule's `rank` to 1, second to 2, etc.
   */
  public recalculateRanks() {
    this.details.forEach((rule, index) => {
      rule.rank = index + 1;
    });
  }

  /**
   * Converts the Model to the DTO, for API communication.
   * @returns A DTO for the API containg the Linked Class Rule
   */
  public toDto(): LinkedClassRuleDto {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      createdBy: this.createdBy,
      modifiedBy: this.modifiedBy,
      createdDateTime: this.createdDateTime,
      modifiedDateTime: this.modifiedDateTime,
      isArchived: this.isArchived,
      details: this.details.map((model) => model.toDto()),
    };
  }
}

/**
 * The LinkedClassRuleDetailModel represents an individual rule/linked class.
 * It links two classes to eachother and makes sure the targeted class confirms the rule defined, when the user makes a manual change.
 */
export class LinkedClassRuleDetailModel {
  ruleId: number;
  /**
   * The rank determines the execution order. It starts at 1 (it's not zero based), and increments by 1 each rule.
   * The rule with the lowest rank, will get executed first.
   */
  rank: number;
  /**
   * The classCode is the targeted class to be changed with the value resuling of the execution of a rule.
   */
  classCode: string;
  /**
   * The value represents (based on the unit selected), the value that is used to calculate the new `classCode` value.
   */
  value: number;
  /**
   * The class that is used as the source of the rule to be exectued.
   */
  linkingClassCode: string;
  /**
   * The Unit represents the type of action that will use the Link Class Rules' `value` and `linkingClassCode`' value as input,
   * and applies it to the class selected.
   */
  unit: LinkedClassRuleUnit;

  constructor({
    ruleId,
    rank,
    classCode,
    value,
    linkingClassCode,
    unit,
  }: {
    ruleId: number;
    rank: number;
    classCode: string;
    value: number;
    linkingClassCode: string;
    unit: LinkedClassRuleUnit;
  }) {
    this.ruleId = ruleId;
    this.rank = rank;
    this.classCode = classCode;
    this.value = value;
    this.linkingClassCode = linkingClassCode;
    this.unit = unit;
  }

  public static fromDto(dto: LinkedClassRuleDetailDto) {
    return new LinkedClassRuleDetailModel({
      ruleId: dto.ruleId,
      rank: dto.rank,
      classCode: dto.dependentClassCode,
      value: dto.value,
      linkingClassCode: dto.sourceClassCode,
      unit: dto.unit,
    });
  }

  public static fromDtoList(dtos: LinkedClassRuleDetailDto[]): LinkedClassRuleDetailModel[] {
    return dtos.map((dto) => LinkedClassRuleDetailModel.fromDto(dto));
  }

  public toDto(): LinkedClassRuleDetailDto {
    return {
      ruleId: this.ruleId,
      rank: this.rank,
      dependentClassCabinCode: getCabinCodeForClass(this.classCode),
      dependentClassCode: this.classCode,
      value: this.value,
      sourceClassCabinCode: getCabinCodeForClass(this.linkingClassCode),
      sourceClassCode: this.linkingClassCode,
      unit: this.unit,
    };
  }
}
