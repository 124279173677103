<template>
  <vue-draggable
    :model-value="modelValue"
    class="k-draggable"
    filter=".disabled"
    :animation="250"
    data-test="draggable"
    :disabled="disabled"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #item="{ element, index }: { element: T; index: number }">
      <k-draggable-item :disabled="disabled" :removable="itemsRemovable" @remove="emit('itemRemove', { element, index })">
        <slot :element="element" :index="index"></slot>

        <template #remove>
          <slot name="remove" :element="element" :index="index"></slot>
        </template>
      </k-draggable-item>
    </template>
  </vue-draggable>
</template>

<script setup lang="ts" generic="T">
import VueDraggable from 'vuedraggable';

import { KDraggableRemoveItemEvent } from '@/modules/shared/components/draggable/KDraggable.types';
import KDraggableItem from '@/modules/shared/components/draggable/KDraggableItem.vue';

export interface KDraggableProps<T> {
  itemsRemovable?: boolean;
  disabled?: boolean;
  modelValue?: T[];
}

const props = withDefaults(defineProps<KDraggableProps<T>>(), {
  itemsRemovable: true,
  disabled: false,
  modelValue: (): T[] => [],
});

defineSlots<{
  default?: (props: { element: T; index: number }) => T;
  remove?: (props: { element: T; index: number }) => T;
}>();

const emit = defineEmits<{
  (e: 'itemRemove', v: KDraggableRemoveItemEvent<T>): void;
  (e: 'update:modelValue', v: typeof props.modelValue): void;
}>();
</script>

<style lang="scss" scoped>
.k-draggable {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.show-lines .k-draggable-item:not(:last-child) {
  border-radius: 0;
  border-bottom: 1px solid var(--grey-6, #e5e5e5);
}
</style>
