import { ColumnState } from 'ag-grid-enterprise';
import { isBoolean, merge } from 'lodash-es';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { generateFlightLineColumnDefaultState } from '@/models/columns/collections/flightlines';
import { generateInventoryColumnDefaultState } from '@/models/columns/collections/inventory';
import { generateInventoryTacticColumnState } from '@/models/columns/collections/inventory-tactics';
import { Authority } from '@/modules/api/auth/auth-contracts';
import { FilterFieldField, RouteFilterType } from '@/modules/api/shared-contracts';
import { generateBookingDetailsColumnDefaultState } from '@/modules/booking-details/utils/booking-grid.util';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { useFeaturesStore } from '@/modules/features/store/features.store';
import { logger } from '@/modules/monitoring';
import { InventoryManagementMethodology } from '@/modules/shared/shared-contracts';
import {
  ControlSettings,
  DatePattern,
  GridColumnStates,
  LafLoadFactorColoring,
  Language,
  UserConfigModel,
  UserConfigModelDTO,
} from '@/modules/user-settings/api/user/user.contracts';
import { userService } from '@/modules/user-settings/api/user/user.service';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { ExportSeparator } from '@/modules/user-settings/types';
import { ColumnService } from '@/services/column.service';
import { store } from '@/store';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { defaultUserControlFilterSettings } from '@/store/modules/default-user-control-filter-settings';

export type IUserConfigState = Required<UserConfigModel & { user: UserModel | null }>;

export interface GridState {
  columnsState: ColumnState[];
  isPivotMode: boolean;
}

@Module({ dynamic: true, store, name: 'userConfig', namespaced: true })
export class UserConfig extends VuexModule implements IUserConfigState {
  public fontSize = 13;
  public margin = 5;
  public datePattern: DatePattern = 'DD-MM-YYYY';
  public language: Language = 'en-US';
  public detailsViewTableOrder = true;
  public departedViewTableOrder = true;
  public inventoryGridColumnState: ColumnState[] = [];
  public departedGridColumnState: ColumnState[] = [];
  public controlGridColumnState: ColumnState[] = [];
  public bookingDetailsGridColumnState: GridState = { columnsState: [], isPivotMode: false };
  public cabinInventoryGridColumnState: ColumnState[] = [];

  public inventoryManagementMethodology: InventoryManagementMethodology = InventoryManagementMethodology.au;

  public readonly controlSettings: ControlSettings = defaultUserControlFilterSettings;

  public authorities: Authority[] = [];

  public user: UserModel | null = null;
  public controlBookingsPickUpPoints: number[] = [];
  public controlPerformanceBandPickUpPoints: number[] = [];
  public detailsBookingsPickUpPoints: number[] = [];
  public detailsPerformanceBandPickUpPoints: number[] = [];
  public lafColoring: LafLoadFactorColoring = LafLoadFactorColoring.OFF;
  public displaySeatAvailabilityAsZero = false;
  public exportSeparator = ExportSeparator.Comma;

  @Action
  public async getUser(email: string): Promise<UserModel> {
    const user = await userService.getByEmail(email);
    this.setUser(user);
    this.setAnalyticsUser(user);

    return user;
  }

  @Action
  public async setAnalyticsUser(user: UserModel): Promise<void> {
    if (!user?.id) return this.clearAnalyticsUser();
    await logger.setUser({
      id: user.id.toString(),
    });
  }

  @Action
  public async clearAnalyticsUser(): Promise<void> {
    await logger.clearUser();
  }

  @Action
  public async getUserConfiguration(email: string): Promise<UserModel> {
    const user = await this.getUser(email);

    if (user.settings) {
      const settings = (await this.getSession()) ?? user.settings;

      // Persist user config to local storage, so we create a user session (settings remain applied even on refresh, if user logs out settings are cleaned)
      this.setSession({
        inventoryGridColumnState: settings.inventoryGridColumnState,
        cabinInventoryGridColumnState: settings.cabinInventoryGridColumnState,
        controlGridColumnState: settings.controlGridColumnState,
      });

      this.setUserSettings(merge(user.settings, settings));
    }

    return user;
  }

  @Action
  public async saveInventoryGridColumnState(config: ColumnState[]): Promise<Partial<UserConfigModel> | null> {
    try {
      this.setInventoryGridColumnState(config);
      const payload = {
        inventoryGridColumnState: config,
      };
      const userConfig = await userService.patchConfig(this.user.id, payload);
      await this.patchSession(payload);
      return userConfig;
    } catch (error) {
      return null;
    }
  }

  @Action
  public async saveDepartedGridColumnState(config: ColumnState[]): Promise<Partial<UserConfigModel>> {
    try {
      this.setDepartedGridColumnState(config);
      return await userService.patchConfig(this.user.id, {
        departedGridColumnState: config,
      });
    } catch (error) {
      return null;
    }
  }

  @Action
  public async saveControlGridColumnStateToServer(config: ColumnState[]): Promise<Partial<UserConfigModel> | null> {
    try {
      await this.saveControlGridColumnStateSession(config);
      this.setControlGridColumnState(config);

      return await userService.patchConfig(this.user.id, {
        controlGridColumnState: config,
      });
    } catch (error) {
      return null;
    }
  }

  @Action
  public async saveControlGridColumnStateSession(columnsConfig: ColumnState[]): Promise<void> {
    await this.patchSession({ controlGridColumnState: columnsConfig });
  }

  @Action
  public async saveInventoryTacticsGridColumnState(config: ColumnState[]): Promise<Partial<UserConfigModel> | null> {
    try {
      this.setInventoryTacticsGridColumnState(config);
      const payload = {
        cabinInventoryGridColumnState: config,
      };
      const userConfig = await userService.patchConfig(this.user.id, payload);
      await this.patchSession(payload);
      return userConfig;
    } catch (error) {
      return null;
    }
  }

  @Action({ commit: 'setInventoryGridColumnState' })
  public async resetInventoryGridColumnState(): Promise<ColumnState[]> {
    return [];
  }

  @Action({ commit: 'setControlGridColumnState' })
  public async resetControlGridColumnState(): Promise<ColumnState[]> {
    return [];
  }

  @Action({ commit: 'setInventoryTacticsGridColumnState' })
  public async resetInventoryTacticsGridColumnState(): Promise<ColumnState[]> {
    return [];
  }

  @Action
  public async saveUserSettings(userSettings: UserConfigModelDTO): Promise<UserConfigModelDTO> {
    const response = await userService.patchConfig(this.user.id, userSettings);
    this.setUserSettings(response);

    return response;
  }

  /**
   * Saves the control settings part of the user config.
   * Since this is a nested object, we need to save it separately to make sure the other settings are retained,
   * because patching nested objects replaces them.
   *
   * @param modifiedControlSettings an object holding one or more modified settings on Control
   */
  @Action
  public async saveControlSettings(modifiedControlSettings: ControlSettings): Promise<Partial<UserConfigModel>> {
    try {
      this.setControlSettings(modifiedControlSettings);
      return await userService.patchConfig(this.user.id, {
        controlSettings: merge(this.controlSettings, modifiedControlSettings),
      });
    } catch (error) {
      return null;
    }
  }

  @Action
  public async changeDetailsViewTableOrder(): Promise<Partial<UserConfigModel> | null> {
    try {
      this.changeDetailsViewTableOrderState();
      return await userService.patchConfig(this.user.id, {
        detailsViewTableOrder: this.detailsViewTableOrder,
      });
    } catch (error) {
      return null;
    }
  }

  @Action
  public async saveControlFilterType(routeFilterType: RouteFilterType): Promise<void> {
    this.changeControlFilters(routeFilterType);
    await this.saveUserSettings({ controlSettings: this.controlSettings });
  }

  /**
   * Change the user selected Market Filter Type - (aka Route Filter Type) - (O&D or Hub).
   */
  @Action
  public changeControlFilterAction(routeFilterType: RouteFilterType): void {
    this.changeControlFilters(routeFilterType);
  }

  @Action
  public async getSession(): Promise<GridColumnStates | null> {
    const states = localStorage.getItem('gridColumnStates');
    return typeof states === 'string' ? JSON.parse(states) : states;
  }

  @Action
  public async saveSession(userConfig: GridColumnStates): Promise<void> {
    await this.patchSession(userConfig);
  }

  @Action
  public async getControlGridColumnState(): Promise<ColumnState[]> {
    const currentSession = await this.getSession();
    return currentSession?.controlGridColumnState ?? [];
  }

  @Action
  public async getCabinInventoryGridColumnState(): Promise<ColumnState[]> {
    const currentSession = await this.getSession();
    return currentSession?.cabinInventoryGridColumnState || [];
  }

  @Action
  public async getInventoryGridColumnState(): Promise<ColumnState[]> {
    const currentSession = await this.getSession();
    return currentSession?.inventoryGridColumnState ?? [];
  }

  @Action
  private async patchSession(gridColumnStates: GridColumnStates): Promise<void> {
    const currentSession = await this.getSession();
    this.setSession(merge(currentSession, gridColumnStates));
  }

  // Mutations
  @Mutation
  public setUser(user: UserModel): void {
    this.user = user;
  }

  @Mutation
  public setAuthorities(payload: Authority[]): void {
    if (this.user) {
      this.user = { ...this.user, authorities: payload };
    }
  }

  @Mutation
  private setSession(gridColumnStates: GridColumnStates): void {
    localStorage.setItem('gridColumnStates', JSON.stringify(gridColumnStates));
  }

  @Mutation
  public setFlightDetailsBookingsPickupSetting(payload: number[]): void {
    this.detailsBookingsPickUpPoints = payload;
  }

  @Mutation
  public setFlightDetailsPerformanceBandPickupSetting(payload: number[]): void {
    this.detailsPerformanceBandPickUpPoints = payload;
  }

  @Mutation
  public setControlBookingsPickupSetting(payload: number[]): void {
    this.controlBookingsPickUpPoints = payload;
  }

  @Mutation
  public setControlPerformanceBandPickupSetting(payload: number[]): void {
    this.controlPerformanceBandPickUpPoints = payload;
  }

  @Mutation
  public setCompetitorSetting(payload: string[]): void {
    this.controlSettings.competitors = payload;
  }

  @Mutation
  private changeControlFilters(routeFilterType: RouteFilterType): void {
    if (this.controlSettings.filters) {
      const hubIndex = this.controlSettings.filters.indexOf(FilterFieldField.hub);
      const originIndex = this.controlSettings.filters.indexOf(FilterFieldField.origin);
      this.controlSettings.routeFilterType = routeFilterType;

      /**
       * What we do here is replace the separate 'origin' and 'destination' filter with the 'hub' and 'flightpath' filter; and vice versa.
       * The user will see this in the UI when filtering on the control page.
       */
      if (routeFilterType === RouteFilterType.origin_destination) {
        if (hubIndex > -1) {
          this.controlSettings.filters.splice(hubIndex, 2);
        }
        if (originIndex === -1) {
          this.controlSettings.filters.unshift(FilterFieldField.origin, FilterFieldField.destination);
        }
      } else {
        if (originIndex > -1) {
          this.controlSettings.filters.splice(originIndex, 2);
        }
        if (hubIndex === -1) {
          this.controlSettings.filters.unshift(FilterFieldField.hub, FilterFieldField.flightPath);
        }
      }
    }
  }

  @Mutation
  private setInventoryGridColumnState(columnsConfig: ColumnState[]): void {
    this.inventoryGridColumnState = columnsConfig;
  }

  @Mutation
  private setDepartedGridColumnState(columnsConfig: ColumnState[]): void {
    this.departedGridColumnState = columnsConfig;
  }

  @Mutation
  private setControlGridColumnState(columnsConfig: ColumnState[]): void {
    this.controlGridColumnState = columnsConfig;
  }

  @Mutation
  private setInventoryTacticsGridColumnState(columnsConfig: ColumnState[]): void {
    this.cabinInventoryGridColumnState = columnsConfig;
  }

  @Mutation
  public setControlSettings(settings: ControlSettings): void {
    this.controlSettings.filters = settings.filters ?? this.controlSettings.filters;
    this.controlSettings.crossfilterState = settings.crossfilterState ?? this.controlSettings.crossfilterState;
    this.controlSettings.routeFilterType = settings.routeFilterType ?? this.controlSettings.routeFilterType;
    this.controlSettings.competitors = settings.competitors ?? this.controlSettings.competitors;
    this.controlSettings.groupKey = settings.groupKey ?? this.controlSettings.groupKey;
  }

  @Mutation
  private setUserSettings(userSettings: UserConfigModelDTO): void {
    const featuresStore = useFeaturesStore();
    const customerSettingsStore = useCustomerSettingsStore();

    const {
      controlSettings,
      detailsViewTableOrder,
      departedViewTableOrder,
      inventoryGridColumnState,
      fontSize,
      language,
      margin,
      datePattern,
      cabinInventoryGridColumnState,
      departedGridColumnState,
      bookingDetailsGridColumnState,
      controlGridColumnState,
      inventoryManagementMethodology,
      controlBookingsPickUpPoints,
      controlPerformanceBandPickUpPoints,
      detailsBookingsPickUpPoints,
      detailsPerformanceBandPickUpPoints,
      lafColoring,
      displaySeatAvailabilityAsZero,
      exportSeparator,
    } = userSettings;

    this.fontSize = fontSize;
    this.margin = margin;
    this.language = language;
    this.datePattern = datePattern.toUpperCase() as DatePattern;
    this.inventoryManagementMethodology = inventoryManagementMethodology;
    this.detailsViewTableOrder = detailsViewTableOrder;
    this.departedViewTableOrder = departedViewTableOrder;
    this.controlBookingsPickUpPoints = controlBookingsPickUpPoints;
    this.controlPerformanceBandPickUpPoints = controlPerformanceBandPickUpPoints;
    this.detailsBookingsPickUpPoints = detailsBookingsPickUpPoints;
    this.detailsPerformanceBandPickUpPoints = detailsPerformanceBandPickUpPoints;
    this.exportSeparator = exportSeparator;

    if (isBoolean(displaySeatAvailabilityAsZero)) {
      this.displaySeatAvailabilityAsZero = displaySeatAvailabilityAsZero;
    }

    if (lafColoring) {
      this.lafColoring = lafColoring;
    }

    if (departedGridColumnState?.length > 0) {
      this.departedGridColumnState = departedGridColumnState;
    }

    // Migrate it  with the user's state,
    // so new columns are added to the user's custom state at the right position

    // Set the grid state to the module state, if not available, generate default state
    this.inventoryGridColumnState =
      inventoryGridColumnState?.length > 0
        ? inventoryGridColumnState
        : ColumnService.updateUserColumnStateWithNewDefaultState(
            generateInventoryColumnDefaultState(
              AppSettingsModule.inventoryConfigurationProperties.pss,
              detailsBookingsPickUpPoints,
              customerSettingsStore.settings,
            ),
            inventoryGridColumnState,
          );

    // Set the grid state to the module state, if not available, generate default state
    this.controlGridColumnState =
      controlGridColumnState?.length > 0
        ? controlGridColumnState
        : generateFlightLineColumnDefaultState(AppSettingsModule.inventoryConfigurationProperties.pss, {
            cabins: AppSettingsModule.inventoryConfigurationProperties.cabins,
            bookingsPickupDays: controlBookingsPickUpPoints,
            performanceBandPickupDays: controlPerformanceBandPickUpPoints,
            competitorCarrierCodes: controlSettings.competitors,
            lafLoadFactorColoring: this.lafColoring,
            customerSettings: customerSettingsStore.settings,
            features: featuresStore.features,
          });

    this.bookingDetailsGridColumnState = bookingDetailsGridColumnState?.columnsState?.length
      ? bookingDetailsGridColumnState
      : generateBookingDetailsColumnDefaultState();

    // Set the grid state to the module state, if not available, generate default state
    this.cabinInventoryGridColumnState =
      cabinInventoryGridColumnState && cabinInventoryGridColumnState?.length > 0
        ? cabinInventoryGridColumnState
        : ColumnService.updateUserColumnStateWithNewDefaultState(
            generateInventoryTacticColumnState({
              pss: AppSettingsModule.inventoryConfigurationProperties.pss,
              performanceBandPickupDays: detailsPerformanceBandPickUpPoints ?? [],
              customerSettings: customerSettingsStore.settings,
            }),
            cabinInventoryGridColumnState ?? [],
          );

    if (controlSettings.filters) {
      this.controlSettings.filters = controlSettings.filters;
      this.controlSettings.routeFilterType = controlSettings.routeFilterType;
    }
    if (controlSettings.crossfilterState?.length) {
      this.controlSettings.crossfilterState = controlSettings.crossfilterState;
    }
    if (controlSettings.competitors) {
      this.controlSettings.competitors = controlSettings.competitors;
    }
    if (controlSettings.groupKey) {
      this.controlSettings.groupKey = controlSettings.groupKey;
    }

    if (lafColoring) {
      this.lafColoring = lafColoring;
    }
  }

  @Mutation
  private changeDetailsViewTableOrderState(): void {
    this.detailsViewTableOrder = !this.detailsViewTableOrder;
  }
}

export const UserConfigModule = getModule(UserConfig);
