import { ColDef, ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import { filterColumnsOnPermission } from '@/models/columns/utils/col-def-with-permission';
import { FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { PssName } from '@/modules/api/application/application-contracts';
import { CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { FeaturesModel } from '@/modules/features';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';

import { InventoryTacticColumns1a, generateDefaultColumnState1a } from './1a';
import { InventoryTacticColumns1d, generateDefaultColumnState1d } from './1d';
import { InventoryTacticColumns1n, generateDefaultColumnState1n } from './1n';
import { InventoryTacticColumns1s, generateDefaultColumnState1s } from './1s';
import { InventoryTacticColumns1v, generateDefaultColumnState1v } from './1v';
import { InventoryTacticColumns1x, generateDefaultColumnState1x } from './1x';
import { InventoryTacticColumns1y, generateDefaultColumnState1y } from './1y';

const inventoryTacticColumnsMap = new Map<
  PssName,
  (
    pss: PssName,
    performanceBandPickupDays: number[],
    customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
  ) => Array<ColDef<FlightViewLegCabinInventoryTactic> | ColGroupDef<FlightViewLegCabinInventoryTactic>>
>([
  [PssName.Amadeus, InventoryTacticColumns1a],
  [PssName.Radixx, InventoryTacticColumns1d],
  [PssName.Sabre, InventoryTacticColumns1s],
  [PssName.Navitaire, InventoryTacticColumns1n],
  [PssName.Genius_TurSys, InventoryTacticColumns1x],
  [PssName.Pribas, InventoryTacticColumns1y],
  [PssName.Videcom, InventoryTacticColumns1v],
]);

const inventoryTacticColumnStateMap = new Map<
  PssName,
  (performanceBandPickupDays: number[], customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>) => ColumnState[]
>([
  [PssName.Amadeus, generateDefaultColumnState1a],
  [PssName.Radixx, generateDefaultColumnState1d],
  [PssName.Sabre, generateDefaultColumnState1s],
  [PssName.Navitaire, generateDefaultColumnState1n],
  [PssName.Genius_TurSys, generateDefaultColumnState1x],
  [PssName.Pribas, generateDefaultColumnState1y],
  [PssName.Videcom, generateDefaultColumnState1v],
]);

type InventoryTacticFactoryParams = {
  pss: PssName;
  performanceBandPickupDays: number[];
  customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>;
};

export function generateInventoryTacticColumns(
  { pss, performanceBandPickupDays, customerSettings }: InventoryTacticFactoryParams,
  features: FeaturesModel,
): Array<ColDef<FlightViewLegCabinInventoryTactic> | ColGroupDef<FlightViewLegCabinInventoryTactic>> {
  return filterColumnsOnPermission(
    inventoryTacticColumnsMap.get(pss)?.(pss, performanceBandPickupDays, customerSettings) ?? [],
    customerSettings,
    features,
  );
}

export function generateInventoryTacticColumnState({
  pss,
  performanceBandPickupDays,
  customerSettings,
}: InventoryTacticFactoryParams): ColumnState[] {
  return inventoryTacticColumnStateMap.get(pss)?.(performanceBandPickupDays, customerSettings) ?? [];
}
