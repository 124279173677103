import { orderBy, uniqBy } from 'lodash-es';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { routesService } from '@/modules/route-management/api/routes/routes.service';
import { store } from '@/store';

export interface IMarketInfoState {
  marketInfo?: RouteModel[];
  isLoading: boolean;
}

export interface MarketHub {
  code: string;
  fullName: string;
  flightPaths?: string[];
}

@Module({ dynamic: true, store, name: 'marketInfo', namespaced: true })
class MarketInfo extends VuexModule implements IMarketInfoState {
  public marketInfo: RouteModel[] = [];

  public isLoading = false;

  // Actions
  @Action
  public async getMarketInfo(): Promise<RouteModel[] | undefined> {
    if (this.isLoading) {
      return;
    }

    if (this.marketInfo.length > 0) {
      return this.marketInfo;
    }

    try {
      this.setIsLoading(true);

      const response = await routesService.getAllRoutes();
      this.setMarketInfo(response);
      this.setIsLoading(false);

      return response;
    } catch (error) {
      this.setIsLoading(false);
      return undefined;
    }
  }

  @Mutation
  public setMarketInfo(markets: RouteModel[]): void {
    this.marketInfo = markets;
  }

  @Mutation
  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public get uniqueHubs(): MarketHub[] {
    const airports: Array<MarketHub> = [];

    this.marketInfo.forEach((market): void => {
      const origin = {
        code: market.origin ?? '',
        fullName: market.airportOriginName ?? '',
      };
      const destination = {
        code: market.destination ?? '',
        fullName: market.airportDestinationName ?? '',
      };
      airports.push(origin, destination);
    });
    const uniqueAirports = orderBy(uniqBy(airports, 'code'), 'code');
    uniqueAirports.forEach((airport) => {
      airport.flightPaths = this.marketInfo
        .filter((market) => market.origin === airport.code || market.destination === airport.code)
        .map((mkt) => mkt.flightPath);
    });
    return uniqueAirports;
  }
}

export const MarketInfoModule = getModule(MarketInfo);
