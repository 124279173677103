import { CellClassParams, ColDef, IProvidedColumn, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { SetColumnFilterSettings, TextColumnFilterSettings } from '@/models/columns/definitions/base';
import { ColumnId } from '@/models/enums/grid';
import { RouteCabinClustersAssignment } from '@/modules/route-management/api/route-cluster.contracts';
import { RouteGroup } from '@/modules/route-management/api/route-groups.contracts';
import { RouteCabin, RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

type RouteModelValueGetterParams = ValueGetterParams<RouteModel>;

const getRouteKey = (carrierCode: string, flightPath: string): string => `${carrierCode.padEnd(3, ' ')}-${flightPath}`;

export const RouteKeyColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteKey,
  type: 'leftAligned',
  headerName: t('route_key'),
  minWidth: 150,
  width: 180,
  headerCheckboxSelection: true,
  checkboxSelection: true,
  sortable: true,
  sort: 'asc',
  headerClass: 'data-test-route-key-header',
  valueGetter: ({ data }: RouteModelValueGetterParams) => (data ? getRouteKey(data?.carrierCode, data?.flightPath) : ''),
  cellClass: ({ data }: CellClassParams<RouteModel>) =>
    `data-test-route-key-cell ${data ? `data-test-route-key-cell-${getRouteKey(data?.carrierCode, data?.flightPath)}` : ''}`,
};

export const RouteCarrierCodeColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteCarrierCode,
  type: 'leftAligned',
  headerName: t('general.carrier_code'),
  field: 'carrierCode',
  minWidth: 60,
  width: 100,
  sortable: true,
  headerClass: 'ag-header-carrier',
};

export const RouteFlightPathColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteFlightPath,
  type: 'leftAligned',
  headerName: t('path'),
  field: 'flightPath',
  minWidth: 80,
  width: 130,
  sortable: true,
};

export const RouteOriginColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Origin,
  type: 'leftAligned',
  headerName: t('general.origin'),
  field: 'origin',
  minWidth: 60,
  width: 100,
  sortable: true,
  headerClass: 'ag-header-origin',
};

export const RouteDestinationColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Destination,
  type: 'leftAligned',
  headerName: t('general.destination'),
  field: 'destination',
  minWidth: 60,
  width: 100,
  sortable: true,
  headerClass: 'ag-header-destination',
};

export const RouteAssignedToColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteAssignedTo,
  type: 'leftAligned',
  headerName: t('assigned_to'),
  field: 'users',
  minWidth: 150,
  width: 250,
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => (params.data.users ? params.data.users.map((user: any) => user.name).join() : []),
  cellClass: ({ data: { carrierCode, flightPath } }: CellClassParams) =>
    `data-test-assigned-to-column-${carrierCode.padEnd(3, ' ')}-${flightPath}`,
};

export const RouteCfTimeWindow: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteCfTime,
  type: 'leftAligned',
  headerName: t('time_window'),
  field: 'timeWindow',
  minWidth: 150,
  width: 150,
  sortable: false,
  valueGetter: ({ data: { competitiveFareStartAt, competitiveFareEndAt } }: RouteModelValueGetterParams) => [
    competitiveFareStartAt / 60,
    competitiveFareEndAt / 60,
  ],
  cellRenderer: 'GridRouteCfWindowRenderer',
};

export const RouteFareCurrencyColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteFareCurrency,
  type: 'leftAligned',
  headerName: t('fare_currency'),
  field: 'fareCurrency',
  minWidth: 60,
  width: 100,
  sortable: true,
  valueGetter: (params: RouteModelValueGetterParams) => params.data?.fareCurrency,
};

export const RouteDirectionColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteDirection,
  type: 'leftAligned',
  headerName: t('direction'),
  field: 'direction',
  minWidth: 60,
  width: 100,
  sortable: true,
};

export const RouteGroupsColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RouteGroups,
  type: 'leftAligned',
  headerName: t('route_groups'),
  sortable: true,
  field: 'routeGroups',
  minWidth: 100,
  width: 150,
  cellRenderer: 'GridRouteGroupsRenderer',
  comparator: StringOrNumberComparator,
  valueGetter: ({ data: { routeGroups = [] } }: RouteModelValueGetterParams) =>
    routeGroups.map((routeGroup: RouteGroup) => routeGroup.name).join(),
  cellRendererParams: ({ data }: RouteModelValueGetterParams) => ({ value: data?.routeGroups }),
};

export const RouteForecastGeneration: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.ForecastGeneration,
  type: 'leftAligned',
  headerName: t('general.forecast_generation'),
  field: 'forecastGeneration',
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin',
  valueFormatter: (params: ValueFormatterParams): string => (params.value ? t('enabled') : t('disabled')),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasForecastingEnabled,
};

export const RouteForecastLearning: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.ForecastLearning,
  type: 'leftAligned',
  headerName: t('general.forecast_learning'),
  field: 'forecastLearning',
  minWidth: 60,
  width: 150,
  sortable: true,
  headerClass: 'ag-header-origin',
  valueFormatter: (params: ValueFormatterParams): string => (params.value ? t('enabled') : t('disabled')),
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasForecastingEnabled,
};

// Used in the route review queue
export const GenerateRouteFlightPathColumn = (checkboxSelection: boolean): ColDef => ({
  colId: ColumnId.RouteFlightPath,
  headerName: t('path'),
  field: 'flightPath',
  minWidth: 200,
  width: 200,
  headerCheckboxSelection: checkboxSelection,
  checkboxSelection,
  sortable: true,
  sort: 'asc',
  valueGetter: (params: RouteModelValueGetterParams) => params.data?.flightPath,
});

export const RouteCabinClustersColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: `${cabinCode}-clusters`,
  type: 'leftAligned',
  headerName: t('clusters'),
  sortable: true,
  minWidth: 100,
  width: 150,
  cellRenderer: 'GridRouteCabinClustersRenderer',
  cellClass: ({ data: { carrierCode, flightPath } }: CellClassParams) =>
    `data-test-cluster-column-${carrierCode.padEnd(3, ' ')}-${flightPath}`,
  comparator: StringOrNumberComparator,
  cellRendererParams: ({ data }: RouteModelValueGetterParams): RouteCabinClustersAssignment | undefined =>
    data?.cabinsWithClusters.find((cabin: RouteCabinClustersAssignment) => cabin.cabinCode === cabinCode),
  valueGetter: ({ data }: RouteModelValueGetterParams): string | undefined => {
    const routeClusterAssignment = data?.cabinsWithClusters.find((cabin: RouteCabinClustersAssignment) => cabin.cabinCode === cabinCode);

    return routeClusterAssignment?.clusters[0]?.name;
  },
});

export const RouteCabinPacingCurvesColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: `${cabinCode}-pacing-curves`,
  type: 'leftAligned',
  headerName: t('pacing_curves'),
  sortable: true,
  minWidth: 100,
  width: 150,
  cellRenderer: 'GridRouteCabinPacingCurvesRenderer',
  cellClass: ({ data: { carrierCode, flightPath } }: CellClassParams) =>
    `data-test-pacing-curve-column-${carrierCode.padEnd(3, ' ')}-${flightPath}`,
  comparator: StringOrNumberComparator,
  cellRendererParams: ({
    data,
    column,
  }: RouteModelValueGetterParams): { assignedClusters: RouteCabinClustersAssignment; relatedClusterColumnVisible: boolean } | undefined => {
    const relatedClusterColumnVisible = !!column
      .getOriginalParent()
      ?.getChildren()
      .find((child: IProvidedColumn) => child.getId().includes('-clusters'))
      ?.isVisible();

    const assignedClusters = data?.cabinsWithClusters.find((cabin: RouteCabinClustersAssignment) => cabin.cabinCode === cabinCode);

    return {
      assignedClusters,
      relatedClusterColumnVisible,
    };
  },
  valueGetter: ({ data }: RouteModelValueGetterParams): string | undefined => {
    const routeClusterAssignment = data?.cabinsWithClusters.find((cabin: RouteCabinClustersAssignment) => cabin.cabinCode === cabinCode);
    return routeClusterAssignment?.clusters[0]?.pacingCurve.name;
  },
});

export function RouteCabinBYOROpLevelAdjustmentIncrementColumn(cabinCode: string): ColDef {
  return {
    ...SetColumnFilterSettings,
    colId: `${cabinCode}-byor-op-level-adjustment-increment`,
    type: 'leftAligned',
    headerName: t('route_management.labels.byor_op_level_adjustment_increment'),
    headerTooltip: t('route_management.labels.byor_op_level_adjustment_increment_tooltip'),
    sortable: true,
    minWidth: 120,
    width: 120,
    comparator: StringOrNumberComparator,
    valueGetter: ({ data }: RouteModelValueGetterParams): number | undefined =>
      data?.cabins.find((cabin: RouteCabin) => cabin.cabinCode === cabinCode)?.byorOpLevelAdjustmentIncrement,
    requiredPermission: ({ customerSettings }) => !!customerSettings.hasConstructBringYourOwnRecommendationsOpLevelsEnabled,
  };
}

export function RouteCabinForecastDynamicProgramOpLevelAdjustmentIncrementColumn(cabinCode: string): ColDef {
  return {
    ...SetColumnFilterSettings,
    colId: `${cabinCode}-forecast-dynamic-program-op-level-adjustment-increment`,
    type: 'leftAligned',
    headerName: t('route_management.labels.forecast_dynamic_program_op_level_adjustment_increment'),
    headerTooltip: t('route_management.labels.forecast_dynamic_program_op_level_adjustment_increment_tooltip'),
    sortable: true,
    minWidth: 120,
    width: 120,
    comparator: StringOrNumberComparator,
    valueGetter: ({ data }: RouteModelValueGetterParams): number | undefined =>
      data?.cabins.find((cabin: RouteCabin) => cabin.cabinCode === cabinCode)?.dsOpLevelAdjustmentIncrement,
    requiredPermission: ({ customerSettings }) =>
      !!customerSettings.hasForecastingEnabled && !!customerSettings.hasForecastingAndDynamicProgramEnabled,
  };
}
