export const en = {
  user_settings: {
    titles: {
      change_password: 'Change Your Password',
      user_settings: 'User Settings',
    },
    headings: {
      password_criteria: 'Password Criteria',
    },
    placeholders: {
      current_password: 'Your Current Password',
      new_password: 'Your New Password',
      verify_password: 'Verify Your New Password',
    },
    info: {
      password_change_required:
        'Due to security reasons, you are required to change your password before continuing using Eddy. Change your password below to continue.',
      current_password_required: 'For verification purposes, please fill in your current password.',
      new_password_confirmation_required: 'To change your password, please fill in your new password below twice.',
      passwords_are_equal: 'Passwords are equal',
      passwords_are_not_equal: 'Passwords are not equal',
      minimum_n_characters: 'A minimum of {n} characters',
      contains_digit: 'Contains a digit',
      contains_lowercase: 'Contains a lowercase character',
      contains_uppercase: 'Contains an uppercase character',
      contains_symbol: 'Contains a symbol',
      contains_no_parts_of_username: 'Contains no parts of your username',
      date_format: 'Select the way Eddy will format dates.',
      currency_format:
        'Different countries have different ways in which they display numeric values. Choose how monetary values are displayed throughout the application.',
      inventory_management_methodology:
        'Select how manual inventory adjustments can be entered in the application. Choose AU (authorization units) or PR (protections).',
      laf_lf_coloring: 'Select a color palette for the LAF and LF columns in the Flight Control Results grid.',
      negative_seat_availability: 'Select how to display the seat availability when it is negative.',
      exports_separator: 'The separator used when extracting a report.',
      font_size: 'Select the font size to be used in tables.',
    },
    labels: {
      semicolon: `Semicolon (;)`,
      comma: `Comma (,)`,
      pipe: `Pipe ({'|'})`, // Result: 'Pipe (|)', https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation
      date_format: 'Date Format',
      currency_format: 'Currency Format',
      inventory_management_methodology: 'Inventory Management Methodology',
      laf_lf_coloring: 'LAF/LF Coloring',
      negative_seat_availability: 'Negative Seat Availability Display',
      exports_separator: 'Separator for Exports',
      font_size: 'Font Size',
      au: 'AU',
      pr: 'Pr',
      px: '{n}px',
      no_coloring: 'No Coloring',
      red_to_green: 'Red to Green',
      as_is: 'Display As Is',
      as_zero: 'Display As Zero',
    },
    actions: {
      change_password: 'Change Password',
      log_out: 'Log Out',
      save: 'Save',
      select: 'Select',
    },
  },
};
