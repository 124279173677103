import { CascaderItem } from '@/models/element-ui';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions-contracts';

const { t } = i18n.global;

export interface ApplyRecommendedAUPayload extends BaseFlightActionPayload {
  cabinCode: string;
}

export class ApplyRecommendedAUAction implements FlightAction<ApplyRecommendedAUPayload> {
  public actionType: FlightActionType = FlightActionType.applyRecommendedAU;
  public label = t('dynamic_filters.apply_recommended_au');
  public value: any;
  public cabinCode: string;

  constructor(cabinCode?: string) {
    this.cabinCode = cabinCode;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: this.cabinCode + FilterFieldField.applyRecommendedAU,
      meta: {
        cabinCode: this.cabinCode,
        label: this.label,
        flightActionType: FlightActionType.applyRecommendedAU,
        field: FilterFieldField.applyRecommendedAU,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.applyRecommendedAU,
        uniqueField: this.cabinCode,
        isValueValid: () => true,
        hasNoEditor: true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.cabinCode = action.uniqueField as string;
  }

  public getPayload(): ApplyRecommendedAUPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
    };
  }

  public setPayload(payload: ApplyRecommendedAUPayload) {
    this.cabinCode = payload.cabinCode;
  }
}
