export enum ColumnGroupId {
  FlightGroup = 'flight-group',
  AircraftGroup = 'aircraft-group',
  SourceFlightGroup = 'source-flight-group',
  CustomerDefinedData = 'customer-defined-data',
  CabinGroup = 'cabin-group',
  DepartedFlightClassGroup = 'departed-flight-class-group',
  InventoryFlightLineGroup = 'inventory-flight-line-group',
  PricingTacticsGroup = 'pricing-tactics-group',
  ActiveOptimisationTacticsGroup = 'active-optimisation-tactics-group',
  ShadowOptimisationTacticsGroup = 'shadow-optimisation-tactics-group',
  ClassSourceFlightGroup = 'class-source-flight-group',
}
