import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { FlightLegCabin, FlightLegModel } from '@/modules/api/flight/flight-contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';

import { NumberColumnFilterSettings, SetColumnFilterSettings } from './base';

const { t } = i18n.global;

const getMatchedLegCabin = (legs: FlightLegModel[], cabinCode: string): FlightLegCabin | undefined => {
  if (legs?.length !== 1) {
    return;
  }
  return FlightService.getMatchedLegCabin(legs[0], cabinCode);
};

export function LegCabinAuthorizedCapacityColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinAuthorizedCapacity),
    headerName: t('leg_authorized_capacity_short'),
    field: 'authorizedCapacity',
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-aCap-key-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.isAuthorizedCapacityUpdated ? matchingCabin.updatedAuthorizedCapacity : matchingCabin.authorizedCapacity;
    },
    headerTooltip: t('leg_authorized_capacity'),
    enableValue: true,
  };
}

export function LegCabinSaleableCapacityColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinSaleableCapacity),
    headerName: t('leg_saleable_capacity_short'),
    field: 'saleableCapacity',
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-sCap-key-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.saleableCapacity;
    },
    headerTooltip: t('leg_saleable_capacity'),
    enableValue: true,
  };
}

export function LegCabinAuthorizedCapacityLoadfactorColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinLoadFactor),
    headerName: t('authorized_capacity_load_factor_short'),
    headerTooltip: t('authorized_capacity_load_factor'),
    minWidth: 60,
    width: 60,
    sortable: true,
    cellRenderer: 'CapacityLoadFactorRenderer',
    cellClass: 'marginless-cell border border-theme-primary flex',
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin || matchingCabin.loadFactor === undefined) {
        return undefined;
      }
      return parseFloat(FormatService.roundNumber(matchingCabin.loadFactor, 1));
    },
    comparator: StringOrNumberComparator,
    cellRendererParams: (params: ICellRendererParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }

      return {
        value: matchingCabin.capturedLoadFactor,
        realtimeCapacityLoadFactor: matchingCabin.loadFactor,
      };
    },
  };
}

export function LegCabinSaleableCapacityLoadfactorColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinCapacityLoadfactor),
    headerName: t('saleable_capacity_load_factor_short'),
    headerTooltip: t('saleable_capacity_load_factor'),
    minWidth: 60,
    width: 60,
    sortable: true,
    cellRenderer: 'CapacityLoadFactorRenderer',
    cellClass: 'marginless-cell border border-theme-primary flex',
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin || matchingCabin.capacityLoadFactor === undefined) {
        return undefined;
      }
      return parseFloat(FormatService.roundNumber(matchingCabin.capacityLoadFactor, 1));
    },
    comparator: StringOrNumberComparator,
    cellRendererParams: (params: ICellRendererParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }

      return {
        value: matchingCabin.capturedCapacityLoadFactor,
        realtimeCapacityLoadFactor: matchingCabin.capacityLoadFactor,
      };
    },
  };
}

export function LegCabinOverbookingFactorColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinOverbookingFactor),
    headerName: t('overbooking_factor_short'),
    headerTooltip: t('overbooking_factor'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-overbooking-factor-key-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.overbookingFactor;
    },
  };
}

export function LegCabinLeftoverCapacityFactorColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinLeftOverCapacity),
    headerName: t('leftover_capacity_short'),
    minWidth: 35,
    width: 35,
    sortable: true,
    headerTooltip: t('leftover_capacity'),
    cellClass: 'marginless-cell ag-right-aligned-cell',
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.leftoverCapacity;
    },
  };
}

export function LegCabinExpectedNoShowsColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinExpectedNoShows),
    headerName: t('control.columns.expected_no_shows_short'),
    headerTooltip: t('control.columns.expected_no_shows'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-expected-no-shows-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.expectedNoShows;
    },
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}

export function LegCabinRecommendedOverbookingFactorColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinRecommendedOverbookingFactor),
    headerName: t('control.columns.recommended_overbooking_factor_short'),
    headerTooltip: t('control.columns.recommended_overbooking_factor'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-recommended-overbooking-factor-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.recommendedOverbookingFactor;
    },
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}

export function LegCabinOverbookingRiskColumn(cabinCode: string): ColDef {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinOverbookingRisk),
    headerName: t('control.columns.overbooking_risk_short'),
    headerTooltip: t('control.columns.overbooking_risk'),
    minWidth: 35,
    width: 35,
    sortable: true,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-leg-cabin-overbooking-risk-cell-${data.ondId}`,
    comparator: StringOrNumberComparator,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      if (!matchingCabin) {
        return undefined;
      }
      return matchingCabin.overbookingRisk;
    },
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}

export function LegCabinOverbookingAutopilotColumn(cabinCode: string): ColDef {
  return {
    ...SetColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.LegCabinOverbookingAutopilot),
    headerName: t('control.columns.overbooking_autopilot_short'),
    minWidth: 40,
    width: 40,
    sortable: true,
    hide: false,
    type: 'leftAligned',
    cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-overbooking-autopilot-key-cell-${data.ondId}`,
    headerTooltip: t('control.columns.overbooking_autopilot'),
    cellStyle: (params: CellClassParams) =>
      params.value ? { color: '#409EFF' } : params.value === false ? { color: '#909399' } : undefined,
    valueGetter: (params: ValueGetterParams): boolean => {
      const matchingCabin = getMatchedLegCabin(params.data.legs, cabinCode);
      return matchingCabin?.overbookingAutopilot ?? false;
    },
    valueFormatter: (params: ValueFormatterParams): string => (params.value ? t('on') : t('off')),
    requiredPermission(params) {
      return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
    },
  };
}
