import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const CLUSTER_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/cluster-management',
    name: RouteName.clusterManagement,
    meta: {
      authorities: [Authority.ClustersRead],
    },
    component: () => import('../views/ClusterManagementView.vue'),
  },
];
