import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { ClearableClasses, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { useGlobalRivalRule } from '@/modules/rules/rival-rules/composables/use-rival-rule-loader.composable';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface AddRivalRulePayload extends BaseFlightActionPayload {
  ruleId: number;
}

export class AddRivalRuleAction implements FlightAction<AddRivalRulePayload> {
  public actionType: FlightActionType = FlightActionType.addRivalRule;
  public label = t('rival_rules.set_rival_rules');
  public value: number | undefined;

  constructor(value?: number) {
    this.value = value;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: FlightActionType.addRivalRule,
      meta: {
        label: this.label,
        flightActionType: FlightActionType.addRivalRule,
        uniqueField: FlightActionType.addRivalRule,
        field: FilterFieldField.addRivalRule,
        type: FilterFieldType.equal,
        value: this.value,
        componentDataOptions: useGlobalRivalRule().rivalRules.value,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/RivalRuleActionValueRenderer.vue'),
        ),
        valueKey: FlightActionType.addRivalRule,
        componentName: ComponentName.RivalRuleSelectFilterField,
        clearableClasses: ClearableClasses.none,
        isValueValid: (value: any[]) => isValueNotEmpty(value),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
  }

  public setPayload(payload: AddRivalRulePayload): void {
    this.value = payload.ruleId;
  }

  public getPayload(): AddRivalRulePayload {
    const flightAction: AddRivalRulePayload = {
      actionType: this.actionType,
      ruleId: this.value,
    };

    return flightAction;
  }
}
