import { Library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faArrowUpRightFromSquare,
  faArrowsH,
  faBars,
  faBolt,
  faCalculator,
  faCalendarAlt,
  faCalendarCheck,
  faChairOffice,
  faChalkboard,
  faChevronLeft,
  faChevronRight,
  faClipboardList,
  faClock,
  faCodeBranch,
  faCog,
  faCopy,
  faExclamationTriangle,
  faEye,
  faFunction,
  faGem,
  faGripLines,
  faListUl,
  faLock,
  faMobile,
  faPencil,
  faPlaneArrival,
  faPlaneDeparture,
  faPlay,
  faPlus,
  faRedo,
  faRobot,
  faRocketLaunch,
  faSave,
  faSearch,
  faServer,
  faSlidersH,
  faSms,
  faTachometerFast,
  faTags,
  faTasks,
  faTimes,
  faTrashAlt,
  faUser,
  faUsers,
  faWindowMaximize,
} from '@fortawesome/pro-regular-svg-icons';

export function addProRegularIcons(library: Library): void {
  library.add(
    faArchive,
    faArrowsH,
    faArrowUpRightFromSquare,
    faBars,
    faBolt,
    faCalculator,
    faCalendarAlt,
    faCalendarCheck,
    faChairOffice,
    faChalkboard,
    faChevronLeft,
    faChevronRight,
    faClipboardList,
    faClock,
    faCodeBranch,
    faCog,
    faCopy,
    faExclamationTriangle,
    faEye,
    faFunction,
    faGem,
    faGripLines,
    faListUl,
    faLock,
    faMobile,
    faPencil,
    faPlaneArrival,
    faPlaneDeparture,
    faPlay,
    faPlus,
    faRedo,
    faRobot,
    faRocketLaunch,
    faSms,
    faSave,
    faSearch,
    faServer,
    faSlidersH,
    faTachometerFast,
    faTags,
    faTasks,
    faTimes,
    faTrashAlt,
    faUser,
    faUsers,
    faWindowMaximize,
  );
}
