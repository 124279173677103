<template>
  <button
    class="button font-semibold"
    :class="[variantClass, sizeClass, enabled && !loading ? '' : 'opacity-60 cursor-not-allowed']"
    :disabled="!enabled || loading"
  >
    <font-awesome-icon v-if="icon" :icon="['fal', icon]" class="mr-2" />
    <font-awesome-icon v-else-if="loading" :icon="['fal', 'spinner-third']" spin class="mr-2 w-4 h-4" />
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    /** This determines the color */
    variant: {
      default: 'primary',
      type: String,
      required: false,
    },
    size: {
      default: 'sm',
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
      required: false,
    },
    icon: {
      default: undefined,
      type: String,
      required: false,
    },
    enabled: {
      default: true,
      type: Boolean,
      required: false,
    },
    loading: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  computed: {
    variantClass(): string {
      switch (this.variant) {
        case 'primary':
          return `button-primary`;
        case 'secondary':
          return `button-secondary`;
        case 'green':
          return `button-green`;
        case 'red':
          return `button-red`;
        case 'orange':
          return `button-orange`;
        case 'dark':
          return `button-dark`;
        case 'outline-red':
          return `button-outline-red`;
        case 'outline-green':
          return `button-outline-green`;
        case 'outline-secondary':
          return `button-outline-secondary`;
        case 'transparent':
          return `button-transparent`;
        default:
          return `button-primary`;
      }
    },
    sizeClass(): string {
      switch (this.size) {
        case 'xs':
          return `button-xs`;
        case 'sm':
          return `button-sm`;
        case 'md':
          return `button-md`;
        case 'lg':
          return `button-lg`;
        default:
          return `button-sm`;
      }
    },
  },
});
</script>
