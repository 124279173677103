import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { ClearableClasses, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { useGlobalLinkedClassRule } from '@/modules/rules/linked-class-rules/composables/use-linked-class-rule-loader.composable';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export type AddLinkedClassRulePayload = BaseFlightActionPayload & { linkedClassRuleId: number };

export class AddLinkedClassRuleAction implements FlightAction<AddLinkedClassRulePayload> {
  public actionType: FlightActionType = FlightActionType.addLinkedClassRule;
  public label = t('linked_class_rules.set_linked_class_rules');
  public value: number | undefined;

  constructor(value?: number) {
    this.value = value;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      label: this.label,
      value: FlightActionType.addLinkedClassRule,
      meta: {
        label: this.label,
        flightActionType: FlightActionType.addLinkedClassRule,
        uniqueField: FlightActionType.addLinkedClassRule,
        field: FilterFieldField.addLinkedClassRule,
        type: FilterFieldType.equal,
        value: this.value,
        componentDataOptions: useGlobalLinkedClassRule().linkedClassRules.value,
        valueKey: FlightActionType.addLinkedClassRule,
        componentName: ComponentName.LinkedClassRuleSelectFilterField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/LinkedClassRuleActionValueRenderer.vue'),
        ),
        clearableClasses: ClearableClasses.none,
        isValueValid: (value: any[]) => isValueNotEmpty(value),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
  }

  public setPayload(payload: AddLinkedClassRulePayload): void {
    this.value = payload.linkedClassRuleId;
  }

  public getPayload(): AddLinkedClassRulePayload {
    const flightAction: AddLinkedClassRulePayload = {
      actionType: this.actionType,
      linkedClassRuleId: this.value,
    };

    return flightAction;
  }
}
