<template>
  <sticky-header>
    <template #title>
      <k-bread-crumb :crumbs="breadcrumbs" />
    </template>

    <template v-if="canCreateNewRivalRule" #menu>
      <el-button v-if="!isLoadingRivalRule && !isNew && hasChanges()" plain @click="revertChanges">
        {{ t('actions.revert_changes') }}
      </el-button>

      <el-dropdown v-if="!isLoadingRivalRule" split-button class="pl-2" type="success" @click="onSave(true)" @command="onSave(false)">
        <span data-test="dropdown-save-and-close-button">
          {{ $t('actions.save_and_close') }}
        </span>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="false">
              {{ $t('actions.save') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </template>

    <template #content>
      <el-row v-loading="isLoadingRivalRule" :element-loading-text="$t('rival_rules.loading')" :gutter="20" class="gap-y-4">
        <el-col :md="8">
          <k-card :title="$t('rival_rules.general')">
            <div>
              <div>
                {{ $t('rival_rules.display_name') }}
              </div>

              <div>
                <el-input
                  v-if="rivalRule"
                  v-model="rivalRule.name"
                  :placeholder="$t('rival_rules.display_name_placeholder')"
                  :minlength="5"
                  :maxlength="20"
                  data-test="rival-rules-name-input"
                  :disabled="!canCreateNewRivalRule"
                />
              </div>
            </div>
            <div class="pt-2">
              <div>
                {{ $t('description') }}
              </div>

              <div>
                <el-input
                  v-if="rivalRule"
                  v-model="rivalRule.description"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 6 }"
                  :placeholder="$t('description')"
                  :maxlength="450"
                  :disabled="!canCreateNewRivalRule"
                />
              </div>
            </div>
          </k-card>
        </el-col>

        <el-col :md="16">
          <k-card :title="$t('rival_rules.rivals')">
            <template #titleSuffix>
              <el-button
                plain
                type="success"
                :disabled="!hasRivalOptions"
                data-test="rival-rules-add-rule-button"
                @click="addNewRivalRuleDetails(availableCarrierCodes?.at(0))"
              >
                <font-awesome-icon :icon="['fal', 'plus']" class="mr-2" />
                {{ t('rival_rules.add_new_rival') }}
              </el-button>
            </template>

            <el-alert
              v-if="!carrierCodes.length && !rivalRule?.details.length"
              :show-icon="true"
              :title="$t('rival_rules.no_rivals_available_title')"
              type="info"
              :closable="false"
            >
              {{ t('rival_rules.no_rivals_available') }}
            </el-alert>

            <el-alert
              v-else-if="!hasRivalOptions"
              :title="$t('rival_rules.all_rivals_selected_title')"
              type="info"
              class="mb-3"
              :closable="false"
              :show-icon="true"
            >
              {{ t('rival_rules.all_rivals_selected') }}
            </el-alert>

            <rival-rule-detail-list
              v-if="rivalRule?.details.length > 0"
              v-model="rivalRule.details"
              :carrier-codes="availableCarrierCodes"
              :disabled="!canCreateNewRivalRule"
            />
          </k-card>
        </el-col>
      </el-row>
    </template>
  </sticky-header>
</template>

<script lang="ts" setup>
import { Ref, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { NavigationGuardNext, RouteLocationNormalized, onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import RivalRuleDetailList from '@/modules/rules/rival-rules/components/RivalRuleDetailList.vue';
import { useRivalRule } from '@/modules/rules/rival-rules/composables/use-rival-rule-loader.composable';
import { MessageService } from '@/modules/shared';
import { competitorFaresService } from '@/modules/shared/api/competitor-fares';
import KCard from '@/modules/shared/components/card/KCard.vue';
import KBreadCrumb from '@/modules/shared/components/KBreadCrumb.vue';
import StickyHeader from '@/modules/shared/components/layouts/StickyHeader.vue';
import { BreadCrumbInterface, RouteName } from '@/modules/shared/types/routes';
import { MessageBoxService } from '@/services/message-box.service';
import { UserConfigModule } from '@/store/modules/user-config.module';

const router = useRouter();
const route = useRoute();

const {
  isLoadingRivalRule,
  rivalRule,
  loadRivalRule,
  revertChanges,
  saveRivalRule,
  hasChanges,
  addNewRivalRuleDetails,
  loadNewRivalRule,
  isNew,
} = useRivalRule();

const { t } = useI18n();

const breadcrumbs = computed((): BreadCrumbInterface[] => [
  {
    displayName: t('rules.rules'),
    to: { name: RouteName.rulesOverview },
  },
  {
    displayName: t('rival_rules.rival_rules'),
    to: { name: RouteName.rivalRulesOverview },
  },
  {
    displayName: rivalRule.value ? rivalRule.value.name : t('rival_rules.loading'),
    to: { name: RouteName.rivalRulesDetails },
  },
]);

const carrierCodes: Ref<string[]> = ref([]);
const hasRivalOptions = computed((): boolean => availableCarrierCodes.value.length > 0);
const availableCarrierCodes = computed(() => {
  const rivalCarrierCodes = rivalRule.value?.details.map((rule) => rule.rivalCarrierCode) || [];
  return carrierCodes.value.filter((carrierCode) => !rivalCarrierCodes.includes(carrierCode));
});

(async () => {
  carrierCodes.value = await competitorFaresService.getAllCarriers();
})();

const canCreateNewRivalRule = computed(() => authService.hasAuthority([Authority.RivalRulesUpdate], UserConfigModule.user.authorities));

onMounted(() => {
  if (route.name === RouteName.rivalRulesCreate) {
    loadNewRivalRule();
  } else {
    const rivalRuleId = parseInt(`${route.params.id}`);

    if (isFinite(rivalRuleId)) {
      loadRivalRule(rivalRuleId, true);
    }
  }
});

onBeforeRouteLeave(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (hasChanges()) {
    try {
      await MessageBoxService.unsavedChanges();
      next();
    } catch (error) {
      next(false);
    }
  } else {
    next();
  }
});

async function onSave(saveAndClose: boolean): Promise<void> {
  if (!rivalRule.value.name) {
    MessageService.error(t('form.validate_name'));
    return;
  }

  const response = await saveRivalRule();

  if (response && saveAndClose) {
    router.push({
      name: RouteName.rivalRulesOverview,
    });
  }
}
</script>
