import { RouteRecordRaw } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const REPORTING_ROUTES: RouteRecordRaw[] = [
  {
    path: 'insight',
    redirect: { name: RouteName.reportingListing },
    children: [
      {
        path: 'reporting/create',
        name: RouteName.reportingCreate,
        meta: {
          authorities: [Authority.FlightRead],
        },
        component: () => import('@/modules/reporting/views/ReportingEditorView.vue'),
      },
      {
        path: 'reporting/:id/edit',
        name: RouteName.reportingEdit,
        meta: {
          authorities: [Authority.FlightRead],
        },
        component: () => import('@/modules/reporting/views/ReportingEditorView.vue'),
      },
      {
        path: 'reporting/listing',
        name: RouteName.reportingListing,
        meta: {
          authorities: [Authority.FlightRead],
        },
        component: () => import('@/modules/reporting/views/ReportingListingView.vue'),
      },
    ],
  },
];
