import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { i18n } from '@/plugins/i18n';

import { AvailabilityDistribution, BaseFlightActionPayload, FlightAction, FlightActionType } from '../../api/flight-actions-contracts';

const { t } = i18n.global;

export interface ApplyDatascienceRecommendedLafPayload extends BaseFlightActionPayload {
  cabinCode: string;
  availabilityDistribution: AvailabilityDistribution;
}

export class ApplyDatascienceRecommendedLafAction implements FlightAction<ApplyDatascienceRecommendedLafPayload> {
  public actionType: FlightActionType = FlightActionType.applyDatascienceRecommendedLaf;
  public cabinCode: string;
  public value: AvailabilityDistribution = AvailabilityDistribution.MAINTAIN_PROTECTIONS;
  public label = t('dynamic_filters.apply_data_science_rlaf');

  constructor(cabinCode?: string) {
    this.cabinCode = cabinCode;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition> {
    return {
      value: this.cabinCode + FilterFieldField.applyDataScienceRecommendedLaf,
      label: this.label,
      meta: {
        label: this.label,
        cabinCode: this.cabinCode,
        flightActionType: FlightActionType.applyDatascienceRecommendedLaf,
        field: FilterFieldField.applyDataScienceRecommendedLaf,
        type: FilterFieldType.equal,
        valueKey: this.cabinCode + FilterFieldField.applyDataScienceRecommendedLaf,
        uniqueField: this.cabinCode,
        value: this.value,
        componentName: ComponentName.DistributionOptionFilterField,
        isValueValid: () => true,
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition): void {
    this.value = action.value;
    this.cabinCode = action.uniqueField as string;
  }

  public setPayload(payload: ApplyDatascienceRecommendedLafPayload): void {
    this.value = payload.availabilityDistribution;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): ApplyDatascienceRecommendedLafPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      availabilityDistribution: this.value,
    };
  }
}
