import { Component } from 'vue';

import { ComponentName } from '@/models/enums/components';
import { InventoryGridModel } from '@/modules/api/flight/inventory-grid-model';
import { FilterField, FilterFieldField, FilterFieldType, FilterGroup } from '@/modules/api/shared-contracts';
import { AvailabilityDistribution, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { logger } from '@/modules/monitoring';
import { Dictionary } from '@/modules/shared/types/generic';

export interface FilterFieldDefinition<T = any> {
  field: FilterFieldField | InventoryGridModel;
  uniqueField?: string | string[];
  type: FilterFieldType;
  // The value on the FilterFieldDefinition is used to generate the data-test class in the BulkActionListItem
  value?: T;
  index?: number;
  componentName?: string;
  componentDataOptions?: any[];
  componentErrors?: any[];
  componentAllowCreate?: boolean;
  componentValueIsString?: boolean;
  componentCustomTemplate?: boolean;
  customTemplateFieldKeys?: string[];
  componentTitle?: boolean;
  valueKey?: string;
  name?: string;
  uid?: string;
  multiSelect?: boolean;
  loading?: boolean;
  disabled?: boolean;
  isFilterable?: boolean;
  isValid?: boolean;
  isHistorical?: boolean;
  disableShortcuts?: boolean;
  isDateDisabled?: (date: Date) => boolean;
  generateCustomFilter?: (value: any) => FilterField | FilterGroup;
  clearable?: boolean;
  disableSelectionGrouping?: boolean;
  warning?: string;
  clearableClasses?: ClearableClasses;
  hasPin?: boolean;
  isValueValid?: (value: any) => boolean;
  availabilityDistribution?: AvailabilityDistribution;
  transformToParams?: (value: any, type: FilterFieldType, urlParams: Dictionary<string | string[]>) => string;
  transformFromParams?: (value: string, filters: FilterFieldDefinition[], urlParams: Dictionary<string | string[]>) => any;
  /**
   * Determines whether the Flight Action List will show an editor on the right hand side.
   * For fields like 'Send Flight to PSS' or 'Apply rAU', you don't need an editor.
   */
  hasNoEditor?: boolean;
  dataTest?: string;
}

export interface FlightActionDefinition<T = any> extends FilterFieldDefinition<T> {
  cabinCode?: string;
  displayValueComponent?: Component;
  flightActionType: FlightActionType;
  label: string;
}

export enum ClearableClasses {
  none = 'none',
  all = 'all',
  onlyCabinClass = 'onlyCabinClass',
}

export interface FilterFieldComponent {
  fieldDefinition: FilterFieldDefinition;
  onChange: (newValue: any) => void;
}

export function convertFieldToComponentName(fieldName: string): ComponentName | undefined {
  switch (fieldName) {
    case 'aircraftType':
      return ComponentName.AircraftFilterField;
    case 'dayOfWeek':
      return ComponentName.DayOfWeekFilterField;
    case 'origin':
      return ComponentName.OriginSelectFilterField;
    case 'destination':
      return ComponentName.DestinationSelectFilterField;
    case 'tagsAdd':
    case 'tagsRemove':
      return ComponentName.TagsFilterField;
    case 'tagId':
      return ComponentName.TagSelectFilterField;
    case 'departureDateRange':
      return ComponentName.DateRangeFilterField;
    case 'departureDate':
      return ComponentName.DatePickerFilterField;
    case 'flightPath':
      return ComponentName.FlightPathFilterField;
    case 'hub':
      return ComponentName.HubFilterField;
    case 'class':
      return ComponentName.CustomNumberInputFilterField;
    case 'userId':
      return ComponentName.UserFilterField;
    case 'optimizationProfile':
      return ComponentName.OptimizationProfileFilterField;
    case 'optimizationTactic':
      return ComponentName.OptimizationTacticFilterField;
    case 'cluster':
      return ComponentName.ClusterFilterField;
    case 'flightNumberRange':
    case 'flightNumber':
      return ComponentName.FlightNumberRangeFilterField;
    case 'flight':
      return ComponentName.FlightPickerFilterField;
    case 'captureDate':
      return ComponentName.DatePickerFilterField;
    case 'routeGroupId':
      return ComponentName.RouteGroupFilterField;
    case 'carrierCode':
      return ComponentName.CarrierCodeFilterField;
    default:
      logger.error(new Error(`No component name found`), { fieldName });
  }
}
